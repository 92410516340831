import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class PlaneLesson extends Lesson {
  //Properties
  exp = 8;
  center = new paper.Point(this.canvasWidth / 2, this.canvasHeight / 2);
  squareHeight = 0;
  squareWidth = 0;
  perfectShape;

  // p1 is closest to viewer (front)
  // p2 is closest to vp1 (left)
  // p3 is closest to vp2 (right)
  // p4 is closest to horizon (back)

  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });

  circle_array = [this.circle1, this.circle2, this.circle3, this.circle4];

  adjacencyList = {
    0: [1, 2],
    1: [0, 3],
    2: [0, 3],
    3: [1, 2],
  };

  exportLessonData = () => {
    return {
      name: "Planes",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      perfectShape: this.perfectShape,
      vp1: this.vp1,
      vp2: this.vp2,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData

    // perfectShape is an object with 4 points and length
    
    // Set corners using perfectShape
  }


  generateLesson() {
    let checkVisible = false;
    while (!checkVisible) {
      //Random size
      let min_dim = Math.min(this.canvasWidth, this.canvasHeight);
      this.squareHeight = min_dim * 0.5 + Math.random() * min_dim * 0.2;
      this.squareWidth = this.squareHeight;

      // Frontmost point
      let width = Math.min(this.vp2.x - this.vp1.x, this.canvasWidth);
      let left_x = Math.max(this.vp1.x, this.canvasWidth / 8);
      const x1 = left_x + Math.random() * width * 0.8;
      const y1 =
        this.canvasHeight / 5 + Math.random() * this.canvasHeight * 0.6;

      // const x1 = (this.vp2.x - this.vp1.x)/2 + this.vp1.x;
      // const y1 = this.canvasHeight/2;
      this.circle1.position = new paper.Point(x1, y1);
      this.circle1.fillColor = this.masterCircleColor;

      // Calculate measure points
      const [mp1, mp2] = this.findMeasurePoints(x1);

      // Calculate and initialize circles2-circle4
      const [p2, p3, p4] = this.definePlane(x1, y1, mp1, mp2);
      this.circle2.position = p2;
      this.circle2.fillColor = this.masterCircleColor;
      this.circle3.position = p3;
      this.circle3.fillColor = this.masterCircleColor;
      this.circle4.position = p4;
      this.circle4.fillColor = this.masterCircleColor;

      checkVisible = true;
      for (let i = 0; i < this.circle_array.length; i++) {
        // Check that all points are visible on screen
        if (
          this.circle_array[i].position.x < this.canvasWidth * 0.1 ||
          this.circle_array[i].position.y < this.canvasHeight * 0.1 ||
          this.circle_array[i].position.x > this.canvasWidth * 0.9 ||
          this.circle_array[i].position.y > this.canvasHeight * 0.9
        ) {
          checkVisible = false;
          //console.log("Not visible");
          break;
        }

        // Check that no points are too close to horizon
        if (
          Math.abs(this.circle_array[i].position.y - this.horizonHeight) <
          this.masterCircleSize * 3
        ) {
          checkVisible = false;
          //console.log("On horizon");
          break;
        }
      }
    }

    // Keep track of the points that define the plane and the length of the combined path
    this.perfectShape = {
      c1: this.circle1.position,
      c2: this.circle2.position,
      c3: this.circle3.position,
      c4: this.circle4.position,
      length:
        paper.Path.Line(this.circle1.position, this.circle2.position).length +
        paper.Path.Line(this.circle1.position, this.circle3.position).length +
        paper.Path.Line(this.circle4.position, this.circle2.position).length +
        paper.Path.Line(this.circle4.position, this.circle3.position).length,
    };
    this.corners = this.circle_array.map((c) => c.position);

    if (this.variation === "scaffold") {
      // Draw guidelines
      const vp1c2 = new paper.Path.Line({
        from: this.vp1,
        to: this.circle2.position,
        strokeColor: this.masterCircleColor,
        opacity: 0.7,
      });
      const vp1c4 = new paper.Path.Line({
        from: this.vp1,
        to: this.circle4.position,
        strokeColor: this.masterCircleColor,
        opacity: 0.7,
      });
      const vp2c3 = new paper.Path.Line({
        from: this.vp2,
        to: this.circle3.position,
        strokeColor: this.masterCircleColor,
        opacity: 0.7,
      });
      const vp2c4 = new paper.Path.Line({
        from: this.vp2,
        to: this.circle4.position,
        strokeColor: this.masterCircleColor,
        opacity: 0.7,
      });
    }

    return true;
  }

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }

    //Do the checks
    if (
      this.isClosedShape(strokes) &&
      this.isCompleteShape(strokes)
    ) {
      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }
    return false;
  }

  getPrecision(strokes) {
    return this.linearPrecision(strokes, 4);
  }
}

export default PlaneLesson;
