import Lesson from "../Lesson/Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SFTCube extends Lesson {
  //Properties
  // p1 is closest to viewer (front)
  // p2 is closest to vp1 (left)
  // p3 is closest to vp2 (right)
  // p4 is closest to horizon (back)

  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });

  circle5 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle6 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle7 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle8 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });

  squareHeight = Math.min(this.canvasWidth, this.canvasHeight) * 0.6;
  squareWidth = this.squareHeight;



  generateLesson() {
    // Generate lines based on screen size
    const title = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20)
    );
    title.content = "CUBE";
    title.style = {
      fontWeight: "bold",
      fontSize: 20,
      fillColor: "black",
      justification: "center",
    };

    const instructions = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20 + 20)
    );
    instructions.content =
      "Sketch a cube in 2-point perspective using the provided construction lines.";
    instructions.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };

    this.vp2.x = this.canvasWidth * 1.3;
    this.vp1.x = -this.canvasWidth * 0.7;
    this.horizonHeight = this.canvasHeight * 0;
    this.vp2.y = this.horizonHeight;
    this.vp1.y = this.horizonHeight;

    let x1 = (11 * this.canvasWidth) / 20;
    let y1 = (9 * this.canvasHeight) / 10;
    this.circle1.position = new paper.Point(x1, y1);

    // Calculate measure points
    const [mp1, mp2] = this.findMeasurePoints(x1);

    // Define first plane: get center points for circles 2-4
    const [p2, p3, p4] = this.definePlane(x1, y1, mp1, mp2);
    this.circle2.position = p2;
    this.circle3.position = p3;
    this.circle4.position = p4;

    const x5 = x1; // second plane aligns with anchor from first
    const y5 = y1 - this.squareHeight;
    this.circle5.position = new paper.Point(x5, y5);

    // Define second plane: get center points for circles 2-4
    const [p6, p7, p8] = this.definePlane(x5, y5, mp1, mp2);
    this.circle6.position = p6;
    this.circle7.position = p7;
    this.circle8.position = p8;

    let vert_line = new paper.Path.Line({
      from: this.circle1.position,
      to: this.circle5.position,
      strokeColor: this.masterCircleColor,
    });

    let top_left = new paper.Path.Line({
      from: this.circle5.position,
      to: this.circle6.position,
    });
    let top_left_guide = new paper.Path.Line({
      from: this.circle5.position,
      to: top_left.getPointAt(top_left.length * 0.4),
      strokeColor: this.masterCircleColor,
    });

    let top_right = new paper.Path.Line({
      from: this.circle5.position,
      to: this.circle7.position,
    });
    let top_right_guide = new paper.Path.Line({
      from: this.circle5.position,
      to: top_right.getPointAt(top_right.length * 0.4),
      strokeColor: this.masterCircleColor,
    });

    let bottom_left = new paper.Path.Line({
      from: this.circle1.position,
      to: this.circle2.position,
    });
    let bottom_left_guide = new paper.Path.Line({
      from: this.circle1.position,
      to: bottom_left.getPointAt(bottom_left.length * 0.45),
      strokeColor: this.masterCircleColor,
    });

    let bottom_right = new paper.Path.Line({
      from: this.circle1.position,
      to: this.circle3.position,
    });
    let bottom_right_guide = new paper.Path.Line({
      from: this.circle1.position,
      to: bottom_right.getPointAt(bottom_right.length * 0.45),
      strokeColor: this.masterCircleColor,
    });

    // Keep track of the points that define the plane and the length of the combined path
    this.perfectShape = {
      c1: this.circle1.position,
      c2: this.circle2.position,
      c3: this.circle3.position,
      c4: this.circle4.position,
      c5: this.circle5.position,
      c6: this.circle6.position,
      c7: this.circle7.position,
      c8: this.circle8.position,
      length:
        paper.Path.Line(this.circle1.position, this.circle2.position).length +
        paper.Path.Line(this.circle1.position, this.circle3.position).length +
        paper.Path.Line(this.circle4.position, this.circle2.position).length +
        paper.Path.Line(this.circle4.position, this.circle3.position).length +
        paper.Path.Line(this.circle5.position, this.circle6.position).length +
        paper.Path.Line(this.circle5.position, this.circle7.position).length +
        paper.Path.Line(this.circle8.position, this.circle6.position).length +
        paper.Path.Line(this.circle8.position, this.circle7.position).length +
        paper.Path.Line(this.circle1.position, this.circle5.position).length +
        paper.Path.Line(this.circle2.position, this.circle6.position).length +
        paper.Path.Line(this.circle3.position, this.circle7.position).length +
        paper.Path.Line(this.circle4.position, this.circle8.position).length,
    };

    this.corners = [
      this.perfectShape.c1,
      this.perfectShape.c2,
      this.perfectShape.c3,
      this.perfectShape.c4,
      this.perfectShape.c5,
      this.perfectShape.c6,
      this.perfectShape.c7,
      this.perfectShape.c8,
    ];

    this.adjacencyList = {
      0: [1, 2, 4],
      1: [0, 3, 5],
      2: [0, 3, 6],
      3: [1, 2, 7],
      4: [5, 6, 0],
      5: [4, 7, 1],
      6: [4, 7, 2],
      7: [5, 6, 3],
    };
  }

  getPrecision = (strokes) => {
    let total_deviation = 0;
    let num_misses = 0;

    let segments = this.splitShape(strokes);

    let min_dev = 1000000;
    let dev = -1;
    for (let i = 0; i < this.corners.length; i++) {
      for (let j = 0; j < this.corners.length; j++) {
        // Check paths from i -> j
        for (let k = 0; k < segments[i][j].length; k++) {
          dev = this.checkLine(
            segments[i][j][k],
            this.corners[i],
            this.corners[j],
            false
          );
          if (dev < min_dev) {
            min_dev = dev;
          }
        }
        // Check paths from decoded j -> i
        for (let k = 0; k < segments[j][i].length; k++) {
          dev = this.checkLine(
            segments[j][i][k],
            this.corners[i],
            this.corners[j],
            false
          );
          if (dev < min_dev) {
            min_dev = dev;
          }
        }
      }
      if (dev === -1) {
        // No match
        num_misses += 1;
      } else {
        total_deviation += min_dev;
      }
    }

    let avgDeviation = total_deviation / this.perfectShape.length;
    //console.log("Average Deviation: " + avgDeviation);

    return 100 - avgDeviation - (100 * num_misses) / 12;
  };

  exportLessonData = () => {
    return {
      name: "SFTCube",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };
}

export default SFTCube;
