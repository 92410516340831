const LessonNav = (props) => {
  let showStart =
    props.name !== "1pt Perspective" &&
    props.name !== "2pt Perspective" &&
    props.name != "Additive Combos";

  // Remove left button for Results Overlay
  let showBack = props.disableBack ? false : true;

  return (
    <div className="lessonNav">
      <div className="row">
        <div className="col s4 m4 l4">
          {showBack && (
            <div className="lessonButton prev" onClick={props.onBack}>
              Back
            </div>
          )}
        </div>
        <div className="col s4 m4 l4">
          {showStart && (
            <div
              id="start"
              className="lessonButton start"
              onClick={props.onStart}
            >
              {props.beginText}
            </div>
          )}
        </div>
        <div className="col s4 m4 l4"> </div>
      </div>
    </div>
  );
};

export default LessonNav;
