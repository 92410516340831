import { Fragment } from "react";
import LessonManager from "../Lesson/LessonManager";
import { useSelector } from "react-redux";

const playAudio = (type) => {
  console.log("LessonManager playAudio", type);
};

const SketchingFoundationsTest = (props) => {
  const partialSFT = useSelector((state) => state.login.partialSFT);

  let lessonList = [
    "SFTWarmup",
    "SFTHorizontalLines",
    "SFTDiagonalLines",
    "SFTSquares",
    "SFTCircles",
    "SFTEllipses",
    // "SFTCube",
    // "SFTCylinder",
    // "SFTCamera",
  ];

  if (partialSFT) {
    lessonList = [
      "SFTWarmup",
      "SFTHorizontalLines",
      "SFTDiagonalLines",
      "SFTSquares",
      "SFTCircles",
      "SFTEllipses",
    ];
  }

  return (
    <Fragment>
      <LessonManager name="SFT" category="SFT" lessonList={lessonList} />
    </Fragment>
  );
};

export default SketchingFoundationsTest;
