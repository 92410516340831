import paper from "paper";
import { multiply, inv, round } from "mathjs";
import bonusPreciseImg from "../../images/bonuses/bonus-precise.png";
import bonusSmoothImg from "../../images/bonuses/bonus-smooth.png";
import bonusQuickImg from "../../images/bonuses/bonus-quick.png";
import bonusPerfectImg from "../../images/bonuses/bonus-perfect.png";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class Lesson {
  constructor(canvasCtx) {
    this.name = canvasCtx.name;
    this.variation = canvasCtx.variation;

    this.canvasHeight = canvasCtx.canvasHeight;
    this.canvasWidth = canvasCtx.canvasWidth;

    this.sketchColor = canvasCtx.sketchColor;
    this.sketchSize = canvasCtx.sketchSize;
    this.masterCircleColor = canvasCtx.masterCircleColor;
    this.masterCircleSize = canvasCtx.masterCircleSize;
    this.deviationThreshold = canvasCtx.deviationThreshold;

    this.vp1 = canvasCtx.vp1;
    this.vp2 = canvasCtx.vp2;
    this.horizonHeight = canvasCtx.horizonHeight;

    this.showFeedback = canvasCtx.showFeedback;
  }

  // Properties
  // -- To be given by SketchCanvas on evaluation
  timeValues = [];
  pressureValues = [];
  tiltValues = [];

  hasCanvasDims = false;

  // -- Used when loading data
  numStrokes = 0;
  startIndex = -1;
  endIndex = -1;
  sketchData = [];
  lessonData = [];

  //Combine strokes
  //Only can be used on basics
  combinePath = (strokes) => {
    let combinedPath = new paper.Path();
    if (strokes.length === 0) {
      return combinedPath;
    }

    combinedPath.addSegments(strokes[0].segments);
    const used_strokes = strokes.map((ele) => false);
    used_strokes[0] = true;

    let min = Number.MAX_SAFE_INTEGER;
    let forward = true;
    let min_index = 0;
    let front,
      back = 0;
    while (!used_strokes.every((val) => val)) {
      // Find the closest next stroke
      for (let i = 1; i < strokes.length; i++) {
        if (!used_strokes[i]) {
          front = combinedPath.lastSegment.point.getDistance(
            strokes[i].firstSegment.point
          );
          back = combinedPath.lastSegment.point.getDistance(
            strokes[i].lastSegment.point
          );

          if (front < min) {
            forward = true;
            min = front;
            min_index = i;
          }
          if (back < min) {
            forward = false;
            min = back;
            min_index = i;
          }
        }
      }

      if (forward) {
        combinedPath.addSegments(strokes[min_index].segments);
      } else {
        let reverse_stroke = new paper.Path(strokes[min_index].segments);
        reverse_stroke.reverse();
        combinedPath.addSegments(reverse_stroke.segments);
      }

      used_strokes[min_index] = true;
      min = Number.MAX_SAFE_INTEGER;
    }

    return combinedPath;
  };

  evaluateSketch(strokes) {
    if (strokes.length === 0) {
      // No strokes were drawn
      return {
        precision: 0,
        smoothness: 0,
        speed: 0,
        avgPressure: 0,
        exp: 0,
        bonusPrecision: 0,
        bonusSmoothness: 0,
        bonusSpeed: 0,
      };
    }

    let precision = this.getPrecision(strokes);
    let smoothness = this.getSmoothness(strokes);
    let speed = this.getSpeed(strokes);
    let avgPressure = this.getAveragePressure();

    const scores = {
      precision,
      smoothness,
      speed,
      avgPressure,
      exp: this.exp,
    };

    //let middlePoint = path.getPointAt(path.length / 2);
    let middlePoint = new paper.Point(
      this.canvasWidth / 2,
      this.canvasHeight / 2
    );
    const bonuses = this.generateBonuses(
      precision,
      smoothness,
      speed,
      middlePoint
    );

    return { ...scores, ...bonuses };
  }

  generateBonuses(precision, smoothness, speed, middlePoint) {
    //Score feedback
    let d = 76;
    let e = 38;
    let fadeTimer = 500;
    const bonuses = { bonusPrecision: 0, bonusSmoothness: 0, bonusSpeed: 0 };

    if (false) {
    }
    //Text feedback
    if (precision >= 90) {
      // let bonusPrecise = new paper.Raster(bonusPreciseImg);
      // bonusPrecise.position.x = middlePoint.x;
      // bonusPrecise.position.y = middlePoint.y - d;
      // d += e;
      // fadeTimer += 150;

      //Record
      bonuses.bonusPrecision += 1;

      // setTimeout(function () {
      //   removeObject(bonusPrecise);
      // }, fadeTimer);
    }

    if (speed >= 900) {
      // let bonusQuick = new paper.Raster(bonusQuickImg);
      // bonusQuick.position.x = middlePoint.x;
      // bonusQuick.position.y = middlePoint.y - d;
      // d += e;
      // fadeTimer += 150;

      //Record
      bonuses.bonusSpeed += 1;

      // setTimeout(function () {
      //   removeObject(bonusQuick);
      // }, fadeTimer);
    }

    if (smoothness >= 90) {
      // let bonusSmooth = new paper.Raster(bonusSmoothImg);
      // bonusSmooth.position.x = middlePoint.x;
      // bonusSmooth.position.y = middlePoint.y - d;
      // d += e;
      // fadeTimer += 150;

      //Record
      bonuses.bonusSmoothness += 1;

      // setTimeout(function () {
      //   removeObject(bonusSmooth);
      // }, fadeTimer);
    }

    if (speed >= 900 && smoothness >= 90 && precision >= 90) {
      // let bonusPerfect = new paper.Raster(bonusPerfectImg);
      // bonusPerfect.position.x = middlePoint.x;
      // bonusPerfect.position.y = middlePoint.y - d;
      // d += e;
      // fadeTimer += 150;
      playAudio("perfect");
      // setTimeout(function () {
      //   removeObject(bonusPerfect);
      // }, fadeTimer);
    }

    return bonuses;
  }

  normalizeSmoothness = (x) => {
    return 100 * Math.pow(0.002, x * 5);
  };

  smoothnessHelper = (path) => {
    let sumAbsAngle = 0;
    let pta, ptb, ptc, deltax, deltay, prevdeltax, prevdeltay, result;

    const step = 10;
    for (let i = step; i < path.length - step; i += step) {
      pta = path.getPointAt(i - step);
      ptb = path.getPointAt(i);
      ptc = path.getPointAt(i + step);

      deltax = ptc.x - ptb.x;
      deltay = ptc.y - ptb.y;
      prevdeltax = ptb.x - pta.x;
      prevdeltay = ptb.y - pta.y;

      result = Math.atan(
        (deltax * prevdeltay - prevdeltax * deltay) /
          (deltax * prevdeltax + prevdeltay * deltay)
      );
      sumAbsAngle += Math.abs(result);
    }
    return sumAbsAngle;
  };

  getSmoothness(strokes) {
    let avgAbsAngle = 0;
    let length = 0;
    let path;
    if (strokes.length === 0) {
      return 0;
    }

    for (let ind = 0; ind < strokes.length; ind++) {
      path = strokes[ind];
      length += path.length;
      avgAbsAngle += this.smoothnessHelper(path);
    }

    avgAbsAngle /= length;
    let normalizedSmoothness = this.normalizeSmoothness(avgAbsAngle);
    return normalizedSmoothness;
  }

  getCurvedSmoothness(strokes) {
    let path = this.combinePath(strokes);
    if (path.length === 0) {
      return 0;
    }
    let deviation_line = this.ellipseDeviation(path, this.perfectShape);
    let avgAbsAngle =
      this.smoothnessHelper(deviation_line) / this.perfectShape.length;
    let normalizedSmoothness = this.normalizeSmoothness(avgAbsAngle);
    return normalizedSmoothness;
  }

  getSpeed(strokes) {
    let totalTime = 0;
    let length = 0;
    if (strokes.length === 0) {
      return 0;
    }

    for (let i = 0; i < strokes.length; i++) {
      length += strokes[i].length;
      totalTime +=
        this.timeValues[i][this.timeValues[i].length - 1] -
        this.timeValues[i][0];
    }

    let speed = (length / totalTime) * 1000;
    return speed;
  }

  getAveragePressure() {
    let totalPressure = this.pressureValues.flat().reduce((a, b) => {
      return a + b;
    });
    let length = this.pressureValues
      .map((arr) => arr.length)
      .reduce((a, b) => {
        return a + b;
      });

    let avgPressure = totalPressure / length;
    return avgPressure;
  }

  // For diagonal lines
  lengthTest(path, type) {
    if (
      path.length < this.perfectShape.length * 1.4 &&
      path.length > this.perfectShape.length * 0.8
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Given a shape with a corner array and adjacency list,
  // confirm that a path exists between all adjacent corners
  isCompleteShape(strokes) {
    // Initialize booleans for checking corner intersection
    const cornerCheck = [];
    for (let i = 0; i < this.corners.length; i++) {
      cornerCheck.push(0);
    }

    // Iterate over strokes to find corner intersections
    let corner_threshold = this.masterCircleSize * 2;
    let stroke;
    for (let ind = 0; ind < strokes.length; ind++) {
      stroke = strokes[ind];
      for (let j = 0; j < this.corners.length; j++) {
        for (let i = 0; i < stroke.segments.length; i++) {
          let dist = stroke.segments[i].point.getDistance(this.corners[j]);
          if (dist < corner_threshold) {
            // Entered threshold of corner
            cornerCheck[j] = 1;
          }
        }
      }
    }

    const segments = this.splitShape(strokes);

    // Check that every corner has at least 1 match and adjacency constraints
    for (let i = 0; i < cornerCheck.length; i++) {
      if (cornerCheck[i] === 0) {
        // console.log("No match at corner ", i + 1);
        return false;
      }

      for (let j = 0; j < this.adjacencyList[i].length; j++) {
        let idx = this.adjacencyList[i][j];
        if (segments[i][idx].length === 0 && segments[idx][i].length === 0) {
          // console.log("No path between corners", i, "and", idx);
          return false;
        }
      }
    }

    return true;
  }

  isClosedShape(strokes) {
    //Check if path exists
    if (strokes.length === 0) {
      return false;
    }
    if (strokes[0].segments.length === 0) {
      return false;
    }

    // Check for single stroke
    if (
      strokes.length === 1 &&
      strokes[0].getCrossings(strokes[0]).length > 0
    ) {
      return true;
    }

    // Check if every stroke has at least two intersections
    let round1 = strokes.map((stroke) => 0);
    let path1, path2;
    for (let i = 0; i < strokes.length; i++) {
      path1 = strokes[i];
      for (let j = i + 1; j < strokes.length; j++) {
        path2 = strokes[j];
        if (path1.getIntersections(path2).length > 0) {
          round1[i] += 1;
          round1[j] += 1;
        }
      }
    }

    let round2 = [];
    for (let i = 0; i < round1.length; i++) {
      if (round1[i] < 2) {
        round2.push(i);
      }
    }
    if (round2.length === 0) {
      // Every stroke had at least two intersections
      return true;
    }

    // For strokes without enough intersections,
    // check if endpoitns are near other strokes (inclusive)
    let threshold = this.masterCircleSize * 3;
    let match_start = false;
    let match_end = false;
    let pt1A, pt1B, pt2A, pt2B;
    for (let i = 0; i < round2.length; i++) {
      path1 = strokes[round2[i]];
      pt1A = path1.firstSegment.point;
      pt1B = path1.lastSegment.point;
      match_start = false;
      match_end = false;

      for (let j = 0; j < strokes.length; j++) {
        if (j === round2[i]) {
          continue;
        }

        path2 = strokes[j];
        pt2A = path2.firstSegment.point;
        pt2B = path2.lastSegment.point;

        if (
          pt1A.getDistance(pt2A) < threshold ||
          pt1A.getDistance(pt2B) < threshold
        ) {
          match_start = true;
        }
        if (
          pt1B.getDistance(pt2A) < threshold ||
          pt1B.getDistance(pt2B) < threshold
        ) {
          match_end = true;
        }
        if (match_start && match_end) {
          break;
        }
      }
      if (!(match_start && match_end)) {
        // there exists a path that does not intersect or have endpoints near other strokes
        return false;
      }
    }

    // Conditions were satisfied
    return true;
  }

  checkDistance(pointA, pointB) {
    let deltax = pointB.x - pointA.x;
    let deltay = pointB.y - pointA.y;
    let result = Math.sqrt(deltax * deltax + deltay * deltay);
    return result;
  }

  plotTwoPointVP = () => {
    let circle1 = new paper.Path.Circle(this.vp1, 7);
    circle1.fillColor = this.masterCircleColor;
    circle1.opacity = 0.8;

    let circle2 = new paper.Path.Circle(this.vp2, 7);
    circle2.fillColor = this.masterCircleColor;
    circle2.opacity = 0.8;
  };

  findMeasurePoints(x) {
    // Determine measure point location based on direction of view determined by x-coordinate

    const vp_dist = this.vp1.getDistance(this.vp2);
    const dvp = new paper.Point(x, this.horizonHeight); // direction of view
    const leg1 = this.vp1.getDistance(dvp);
    const leg2 = this.vp2.getDistance(dvp);
    const viewVP1 = Math.sqrt(
      (vp_dist * vp_dist - leg2 * leg2 + leg1 * leg1) / 2
    );
    const viewVP2 = Math.sqrt(
      (vp_dist * vp_dist + leg2 * leg2 - leg1 * leg1) / 2
    );

    // Confirm that the view is physically possible
    if (!viewVP1 || !viewVP2) {
      return [false, false];
    }

    // Measure points
    const mp1 = new paper.Point(this.vp1.x + viewVP1, this.horizonHeight);
    const mp2 = new paper.Point(this.vp2.x - viewVP2, this.horizonHeight);

    return [mp1, mp2];
  }

  definePlane(x1, y1, mp1, mp2) {
    /*
      circle1: frontmost circle (closest to viewer)
      circle2: leftmost circle (closest to vp1)
      circle3: rightmost circle (closest to vp2)
      circle4: backmost circle (closest to horizon)
    */
    const p1 = new paper.Point(x1, y1);

    // Guideline to determine foreshortening toward vp1
    const mp1_bar = new paper.Point(x1 - this.squareHeight, y1);
    // const mp1measure = new paper.Path.Line({
    //   from: new paper.Point(x1 - this.squareHeight, y1),
    //   to: mp1,
    // });

    // Guideline to determine foreshortening toward vp2
    const mp2_bar = new paper.Point(x1 + this.squareWidth, y1);
    // const mp2measure = new paper.Path.Line({
    //   from: new paper.Point(x1 + this.squareWidth, y1),
    //   to: mp2,
    // });

    // Determine front-facing sides based on measure points
    const p2 = this.solve(mp1, mp1_bar, p1, this.vp1);
    // let p2 = mp1measure.getIntersections(
    //   paper.Path.Line({
    //     from: [x1, y1],
    //     to: this.vp1,
    //   })
    // )[0].point;

    const p3 = this.solve(mp2, mp2_bar, p1, this.vp2);
    // let p3 = mp2measure.getIntersections(
    //   paper.Path.Line({
    //     from: [x1, y1],
    //     to: this.vp2,
    //   })
    // )[0].point;

    // Determine backmost point based on points determined by vp's
    const p4 = this.solve(p3, this.vp1, p2, this.vp2);
    // let p4 = paper.Path.Line({
    //   from: this.vp1,
    //   to: p3,
    // }).getIntersections(
    //   paper.Path.Line({
    //     from: this.vp2,
    //     to: p2,
    //   })
    // )[0].point;

    return [p2, p3, p4];
  }

  defineVerticalPlane(x1, y1, mp1, mp2, isLeft) {
    /*
      p1: front-bottom circle (closest to viewer, closest to bottom of screen)
      p2: front-top circle (closest to viewer, closest to top of screen)
      p3: back-bottom circle (closest to horizon, closest to bottom of screen)
      p4: back-top circle (closest to horizon, closest to top of screen)
      isLeft: true if plane is defined by vp1 (on left side); false otherwise
    */

    let p2 = new paper.Point(x1, y1 - this.squareHeight);

    // Guidelines to determine foreshortening toward vp1
    const mp1measure_bot = new paper.Path.Line({
      from: new paper.Point(x1 - this.squareHeight, y1),
      to: mp1,
    });
    const mp1measure_top = new paper.Path.Line({
      from: new paper.Point(p2.x - this.squareHeight, p2.y),
      to: mp1,
    });

    // Guidelines to determine foreshortening toward vp2
    const mp2measure_bot = new paper.Path.Line({
      from: new paper.Point(x1 + this.squareHeight, y1),
      to: mp2,
    });
    const mp2measure_top = new paper.Path.Line({
      from: new paper.Point(p2.x + this.squareHeight, p2.y),
      to: mp2,
    });

    let p3;
    let p4;
    if (isLeft) {
      // Plane extends toward vp1
      p3 = mp1measure_bot.getIntersections(
        paper.Path.Line({
          from: [x1, y1],
          to: this.vp1,
        })
      )[0].point;
      p4 = mp1measure_top.getIntersections(
        paper.Path.Line({
          from: [p2.x, p2.y],
          to: this.vp1,
        })
      )[0].point;
    } else {
      // Plane extends toward vp2
      p3 = mp2measure_bot.getIntersections(
        paper.Path.Line({
          from: [x1, y1],
          to: this.vp2,
        })
      )[0].point;
      p4 = mp2measure_top.getIntersections(
        paper.Path.Line({
          from: [p2.x, p2.y],
          to: this.vp2,
        })
      )[0].point;
    }

    return [p2, p3, p4];
  }

  defineEllipse(circle1, circle2, circle3, circle4, scaffold) {
    /*
      circle1: frontmost circle (closest to viewer)
      circle2: leftmost circle (closest to vp1)
      circle3: rightmost circle (closest to vp2)
      circle4: backmost circle (closest to horizon)
    */

    // Guidelines: draw box that defines plane
    // Naming convention: line_circleID_circleID
    let line_1_2 = new paper.Path.Line(circle1.position, circle2.position);
    let line_1_3 = new paper.Path.Line(circle1.position, circle3.position);
    let line_2_4 = new paper.Path.Line(circle4.position, circle2.position);
    let line_3_4 = new paper.Path.Line(circle4.position, circle3.position);
    let diag_1_4 = new paper.Path.Line(circle1.position, circle4.position);
    let diag_2_3 = new paper.Path.Line(circle2.position, circle3.position);

    if (scaffold >= 0) {
      line_1_2.strokeColor = this.masterCircleColor;
      line_1_3.strokeColor = this.masterCircleColor;
      line_2_4.strokeColor = this.masterCircleColor;
      line_3_4.strokeColor = this.masterCircleColor;
    }
    if (scaffold >= 1) {
      diag_1_4.strokeColor = this.masterCircleColor;
      diag_2_3.strokeColor = this.masterCircleColor;
    }

    // Keep track of the center
    let center = diag_1_4.getIntersections(diag_2_3)[0].point;

    // Halfpoint lines (first 4 points to define ellipse)

    // p5
    let front_left_mid = this.solve(
      circle1.position,
      circle2.position,
      this.vp2,
      center
    );

    let front_left_mid_circle = new paper.Path.Circle({
      center: [front_left_mid.x, front_left_mid.y],
      radius: this.masterCircleSize,
      //fillColor: "green",
    });

    // p6
    let front_right_mid = this.solve(
      circle1.position,
      circle3.position,
      this.vp1,
      center
    );
    let front_right_mid_circle = new paper.Path.Circle({
      center: [front_right_mid.x, front_right_mid.y],
      radius: this.masterCircleSize,
      //fillColor: "purple",
    });

    // p7
    let back_left_mid = this.solve(
      circle4.position,
      circle2.position,
      this.vp1,
      center
    );

    let back_left_mid_circle = new paper.Path.Circle({
      center: [back_left_mid.x, back_left_mid.y],
      radius: this.masterCircleSize,
      //fillColor: "blue",
    });

    // p8
    let back_right_mid = this.solve(
      circle4.position,
      circle3.position,
      this.vp2,
      center
    );

    let back_right_mid_circle = new paper.Path.Circle({
      center: [back_right_mid.x, back_right_mid.y],
      radius: this.masterCircleSize,
      //fillColor: "orange",
    });

    if (scaffold >= 2) {
      let diag_5_8 = new paper.Path.Line(back_right_mid, front_left_mid);
      diag_5_8.strokeColor = this.masterCircleColor;
      let diag_6_7 = new paper.Path.Line(back_left_mid, front_right_mid);
      diag_6_7.strokeColor = this.masterCircleColor;
    }

    // Get midpoint of diagonal to solve for 5th point
    let diag_5_6_mid = this.solve(
      front_left_mid,
      front_right_mid,
      circle1.position,
      center
    );

    // p9
    let front_left_quarter = this.solve(
      circle1.position,
      this.solve(this.vp1, diag_5_6_mid, circle4.position, circle2.position),
      this.vp2,
      this.solve(this.vp2, diag_5_6_mid, circle1.position, circle2.position)
    );

    let front_left_quarter_circle = new paper.Path.Circle({
      center: [front_left_quarter.x, front_left_quarter.y],
      radius: this.masterCircleSize,
      //fillColor: "green",
    });

    const [a, b, c, d, e, f] = this.conicGeneralForm(
      front_left_mid,
      front_right_mid,
      back_left_mid,
      back_right_mid,
      front_left_quarter
    );

    const [ellipse_center, angle, major, minor] = this.ellipseParams(
      a,
      b,
      c,
      d,
      e,
      f
    );

    return [
      center,
      front_left_mid_circle,
      front_right_mid_circle,
      back_left_mid_circle,
      back_right_mid_circle,
      ellipse_center,
      angle,
      major,
      minor,
    ];
  }

  defineVerticalEllipse(circle1, circle2, circle3, circle4, isLeft, scaffold) {
    /*
      circle1: front-bottom circle (closest to viewer, closest to bottom of screen)
      circle2: front-top circle (closest to viewer, closest to top of screen)
      circle3: back-bottom circle (closest to horizon, closest to bottom of screen)
      circle4: back-top circle (closest to horizon, closest to top of screen)
    */

    // Guidelines: draw box that defines plane
    // Naming convention: line_circleID_circleID
    let line_1_2 = new paper.Path.Line(circle1.position, circle2.position);
    let line_1_3 = new paper.Path.Line(circle1.position, circle3.position);
    let line_2_4 = new paper.Path.Line(circle4.position, circle2.position);
    let line_3_4 = new paper.Path.Line(circle4.position, circle3.position);
    let diag_1_4 = new paper.Path.Line(circle1.position, circle4.position);
    let diag_2_3 = new paper.Path.Line(circle2.position, circle3.position);

    if (scaffold >= 0) {
      line_1_2.strokeColor = this.masterCircleColor;
      line_1_3.strokeColor = this.masterCircleColor;
      line_2_4.strokeColor = this.masterCircleColor;
      line_3_4.strokeColor = this.masterCircleColor;
    }
    if (scaffold >= 1) {
      diag_1_4.strokeColor = this.masterCircleColor;
      diag_2_3.strokeColor = this.masterCircleColor;
    }

    // Keep track of the center
    let center = diag_1_4.getIntersections(diag_2_3)[0].point;

    // Find horizontal mid points
    let vertical_line = new paper.Path.Line({
      from: [center.x, 0],
      to: [center.x, this.canvasHeight],
    });
    let horiz_bot_mid = line_1_3.getIntersections(vertical_line)[0].point;
    let horiz_top_mid = line_2_4.getIntersections(vertical_line)[0].point;

    // Halfpoint lines (first 4 points to define ellipse)

    // p5
    let horiz_bot_mid_circle = new paper.Path.Circle({
      center: [horiz_bot_mid.x, horiz_bot_mid.y],
      radius: this.masterCircleSize,
      //fillColor: "green",
    });

    // p6
    let horiz_top_mid_circle = new paper.Path.Circle({
      center: [horiz_top_mid.x, horiz_top_mid.y],
      radius: this.masterCircleSize,
      //fillColor: "purple",
    });

    let _vp;
    if (isLeft) {
      // Plane extends toward vp1
      _vp = this.vp1;
    } else {
      // Plane extends toward vp2
      _vp = this.vp2;
    }

    // p7
    let vert_front_mid = this.solve(
      circle1.position,
      circle2.position,
      _vp,
      center
    );
    let vert_front_mid_circle = new paper.Path.Circle({
      center: [vert_front_mid.x, vert_front_mid.y],
      radius: this.masterCircleSize,
      //fillColor: "orange",
    });

    // p8
    let vert_back_mid = this.solve(
      circle3.position,
      circle4.position,
      _vp,
      center
    );
    let vert_back_mid_circle = new paper.Path.Circle({
      center: [vert_back_mid.x, vert_back_mid.y],
      radius: this.masterCircleSize,
      //fillColor: "blue",
    });

    // // Draw guidelines to the center
    // let _vpc1 = new paper.Path.Line({
    //   from: [_vp.x, _vp.y],
    //   to: [vert_front_mid.x, vert_front_mid.y],
    //   dashArray: [2, 4],
    //   strokeColor: this.sketchColor,
    // });

    if (scaffold >= 2) {
      let horiz_line = new paper.Path.Line(vert_front_mid, vert_back_mid);
      horiz_line.strokeColor = this.masterCircleColor;
      let vert_line = new paper.Path.Line(horiz_bot_mid, horiz_top_mid);
      vert_line.strokeColor = this.masterCircleColor;
    }

    // Draw diagonals connecting the midpoints
    let diag_bot_front_mid = this.solve(
      horiz_bot_mid,
      vert_front_mid,
      circle1.position,
      center
    );

    let diag_bot_front_mid_circle = new paper.Path.Circle({
      center: [diag_bot_front_mid.x, diag_bot_front_mid.y],
      radius: this.masterCircleSize,
      //fillColor: "green",
    });

    // Draw diagonals connecting the midpoints
    let diag_top_front_mid = this.solve(
      horiz_top_mid,
      vert_front_mid,
      circle2.position,
      center
    );

    // p9
    let bot_front_quarter = this.solve(
      circle1.position,
      this.solve(
        diag_top_front_mid,
        diag_bot_front_mid,
        circle4.position,
        circle2.position
      ),
      _vp,
      this.solve(_vp, diag_bot_front_mid, circle1.position, circle2.position)
    );

    // let bot_front_quarter_circle = new paper.Path.Circle({
    //   center: bot_front_quarter,
    //   radius: 5,
    //   fillColor: "cyan"
    // })

    const [a, b, c, d, e, f] = this.conicGeneralForm(
      horiz_bot_mid,
      vert_front_mid,
      vert_back_mid,
      horiz_top_mid,
      bot_front_quarter
    );

    const [ellipse_center, angle, major, minor] = this.ellipseParams(
      a,
      b,
      c,
      d,
      e,
      f
    );

    return [
      center,
      horiz_bot_mid_circle,
      vert_front_mid_circle,
      vert_back_mid_circle,
      horiz_top_mid_circle,
      ellipse_center,
      angle,
      major,
      minor,
    ];
  }

  checkPerspectiveVisible(circle_array) {
    // Check if all points of shape defined by two planes are visible
    let checkVisible = true;
    for (let i = 0; i < circle_array.length; i++) {
      //Check that all points are visible on screen
      if (
        circle_array[i].position.x < this.canvasWidth * 0.1 ||
        circle_array[i].position.y < this.canvasHeight * 0.1 ||
        circle_array[i].position.x > this.canvasWidth * 0.9 ||
        circle_array[i].position.y > this.canvasHeight * 0.9
      ) {
        // console.log("Not visible");
        // console.log(
        //   i,
        //   this.vp1,
        //   this.vp2,
        //   circle_array[i].position.x,
        //   circle_array[i].position.y,
        //   circle_array[i].position.x < this.canvasWidth * 0.1,
        //   circle_array[i].position.y < this.canvasHeight * 0.1,
        //   circle_array[i].position.x > this.canvasWidth * 0.9,
        //   circle_array[i].position.y > this.canvasHeight * 0.9
        // );
        checkVisible = false;
      }
    }
    return checkVisible;
  }

  splitShape = (strokes) => {
    // Prepare data structure for segments between corners
    let segments = [];
    // Determine maximum thresholds for nearest corner
    let thresholds = [];

    // Create 2D array for paths from i->j (adjacency checked in next step)
    let min_dist = 10000000;
    let dist = 0;
    let adj;
    for (let i = 0; i < this.corners.length; i++) {
      min_dist = 10000000;
      adj = [];
      for (let j = 0; j < this.corners.length; j++) {
        adj.push([]);
        dist = this.checkDistance(this.corners[i], this.corners[j]);
        if (dist < min_dist && i !== j) {
          min_dist = dist;
        }
      }
      segments.push(adj);
      thresholds.push(this.masterCircleSize * 3);
    }

    // Find segments between corners
    let stroke;
    let pt;
    let segment = [];

    let within_threshold = false;
    let foundFirst = false;
    let prev = -1;
    let next = -1;
    min_dist = 10000000;
    let min_idx_prev = 0;
    let min_idx_next = 0;
    for (let ind = 0; ind < strokes.length; ind++) {
      stroke = strokes[ind];

      // Ensure initial state for current ind
      within_threshold = false;
      foundFirst = false;
      min_dist = 10000000;
      min_idx_prev = -1;
      min_idx_next = -1;
      prev = -1;
      next = -1;
      segment = [];

      // Iterate over all points
      for (let i = 0; i < stroke.segments.length; i++) {
        pt = stroke.segments[i].point;

        // If corner hasn't been encountered
        if (!foundFirst) {
          if (prev !== -1) {
            dist = pt.getDistance(this.corners[prev]);
          }
          if (!within_threshold) {
            // Look for first corner
            for (let j = 0; j < this.corners.length; j++) {
              dist = pt.getDistance(this.corners[j]);
              if (dist < thresholds[j]) {
                // Encountered a corner
                within_threshold = true;
                min_dist = dist;
                min_idx_prev = i;
                prev = j;
              }
            }
          } else if (dist < thresholds[prev]) {
            // Within the threshold of first corner
            if (dist < min_dist) {
              min_dist = dist;
              min_idx_prev = i;
            }
          } else {
            // Exited threshold
            foundFirst = true;
            within_threshold = false;
            min_dist = 10000000;
          }
        } else {
          if (next !== -1) {
            dist = pt.getDistance(this.corners[next]);
          }
          if (!within_threshold) {
            // Look for next corner
            for (let j = 0; j < this.corners.length; j++) {
              dist = pt.getDistance(this.corners[j]);

              if (
                dist < thresholds[j] &&
                this.adjacencyList[prev].includes(j)
              ) {
                // Encountered an adjacent corner
                within_threshold = true;
                min_dist = dist;
                min_idx_next = i;
                next = j;
              }
            }
          } else if (dist < thresholds[next]) {
            // Within the threshold of next corner
            if (dist < min_dist) {
              min_dist = dist;
              min_idx_next = i;
            }
          } else {
            // Exited threshold

            // Add segment
            for (let k = min_idx_prev; k < min_idx_next; k++) {
              segment.push(
                new paper.Point(
                  stroke.segments[k].point.x,
                  stroke.segments[k].point.y
                )
              );
            }
            segment = new paper.Path(segment);
            segments[prev][next].push(segment);

            // Make prev = next
            prev = next;
            min_idx_prev = min_idx_next;

            // Reset
            within_threshold = false;
            min_dist = 10000000;
            min_idx_next = -1;
            next = -1;
            segment = [];
          }
        }
      }
      // Check if stroke ended within point
      if (prev !== -1 && next !== -1) {
        // Add segment
        for (let k = min_idx_prev; k < min_idx_next; k++) {
          segment.push(
            new paper.Point(
              stroke.segments[k].point.x,
              stroke.segments[k].point.y
            )
          );
        }
        segment = new paper.Path(segment);
        segments[prev][next].push(segment);
      }
    }

    return segments;
  };

  alignSegments = (segments) => {
    let total_deviation = 0;
    let num_misses = 0;

    let min_dev = 1000000;
    let dev = -1;

    for (const arr in this.adjacencyList) {
      num_misses += this.adjacencyList[arr].length;
    }
    num_misses /= 2; // adjacency list is symmetrical; divide to remove double counts

    for (let i = 0; i < this.corners.length; i++) {
      for (let j = 0; j < this.corners.length; j++) {
        // Check paths from i -> j
        dev = -1;
        min_dev = 1000000;
        for (let k = 0; k < segments[i][j].length; k++) {
          dev = this.checkLine(
            segments[i][j][k],
            this.corners[i],
            this.corners[j],
            this.showFeedback
          );

          if (dev < min_dev) {
            min_dev = dev;
          }
        }
        if (dev !== -1) {
          total_deviation += min_dev;
          num_misses -= 1;
        }
      }
    }

    return { total_deviation, num_misses };
  };

  linearPrecision = (strokes, num_sides) => {
    // Precision calculation for squares, planes, cubes
    let segments = this.splitShape(strokes);
    let { total_deviation, num_misses } = this.alignSegments(segments);

    let avgDeviation = total_deviation / this.perfectShape.length;
    return Math.max(100 - avgDeviation - (100 * num_misses) / num_sides, 0);
  };

  straightLineCheck = (path) => {
    const threshold = 0.1;
    return (
      Math.abs(
        1 -
          path.firstSegment.point.getDistance(path.lastSegment.point) /
            path.length
      ) < threshold
    );
  };

  // Calculate Error - Lines
  checkLine(path, pt1, pt2, drawError = true) {
    // Calculate error between bath and prompt line definted by pt1 and pt2
    let errorline = new paper.Path.Line(0, 0);

    let perfectLine;
    let dist1 = path.segments[0].point.getDistance(pt1);
    let dist2 = path.segments[0].point.getDistance(pt2);
    if (dist2 > dist1) {
      perfectLine = new paper.Path.Line(pt1, pt2);
    } else {
      perfectLine = new paper.Path.Line(pt2, pt1);
    }

    let inverse_m = 0;
    if (pt1.y !== pt2.y) {
      inverse_m = -(pt2.x - pt1.x) / (pt2.y - pt1.y);
    }
    let b;
    let errorLine;

    let deviation = 0;
    for (let i = 0; i < perfectLine.length; i++) {
      let pt = perfectLine.getPointAt(i);
      if (pt1.y === pt2.y) {
        // Draw vertical line (perpendicular to horizontal)
        errorLine = new paper.Path.Line({
          from: [pt.x, 0],
          to: [pt.x, this.canvasHeight],
        });
      } else {
        // Solve for perpendicular line defined by ptA and ptB
        b = pt.y - inverse_m * pt.x;
        let ptA = new paper.Point(0, b);
        let ptB = new paper.Point(
          this.canvasWidth,
          inverse_m * this.canvasWidth + b
        );
        errorLine = new paper.Path.Line({
          from: ptA,
          to: ptB,
        });
      }

      let intersections = errorLine.getIntersections(path);
      if (intersections.length > 0) {
        let intersection = intersections[0].point;
        deviation += pt.getDistance(intersection);

        if (this.showFeedback && drawError) {
          errorline = new paper.Path.Line({
            from: pt,
            to: intersection,
            strokeColor: "rgba(255,0,0,0.2)",
          });
        }
      } else {
        // Stroke is too short
        deviation += 1; // add penalty for this segment of the line
        if (this.showFeedback && drawError) {
          errorline = new paper.Path.Line({
            from: [pt.x, pt.y + 2],
            to: [pt.x, pt.y - 2],
            strokeColor: "rgba(255,0,0,0.2)",
          });
        }
      }
    }

    return deviation;
  }

  // Calculate Error - Curves
  ellipseError = (path, ellipse, drawError = true) => {
    let totalDeviation = 0;
    let errorline = new paper.Path.Line(0, 0);

    let radius = ellipse.bounds.width / 2;
    var no_of_points = (2 * Math.PI * radius) / 2;
    for (let i = 0; i < 2 * Math.PI; i += Math.PI / no_of_points) {
      // Find the closest point at each angle
      let errorPoint = new paper.Point(
        ellipse.bounds.center.x + 10 * radius * Math.cos(i),
        ellipse.bounds.center.y + 10 * radius * Math.sin(i)
      );

      let angle_line = new paper.Path.Line(errorPoint, ellipse.bounds.center);
      let inter1 = angle_line.getIntersections(path);
      let inter2 = angle_line.getIntersections(ellipse);

      if (inter1.length >= 1) {
        let p1 = new paper.Point(inter1[0].point.x, inter1[0].point.y);
        let p2 = new paper.Point(inter2[0].point.x, inter2[0].point.y);

        //Compute deviation
        let deviation = this.checkDistance(p1, p2);
        totalDeviation += deviation;

        //Show deviation
        if (drawError) {
          errorline = new paper.Path.Line(p1, p2);
          if (errorline.length > this.deviationThreshold) {
            errorline.strokeColor = "rgba(255,0,0,0.2)";
          }
        }
      }
    }

    return totalDeviation;
  };

  // For Smoothness calculation of curves
  ellipseDeviation = (path, ellipse) => {
    const deviation_array = [];
    if (path.length === 0) {
      return deviation_array;
    }

    const no_of_points = (Math.PI * ellipse.bounds.width) / 2;
    const step = Math.PI / no_of_points;
    const ray = Math.max(this.canvasHeight, this.canvasWidth);

    for (let i = 0; i < 2 * Math.PI; i += step) {
      // Find the closest point at each angle
      let errorPoint = new paper.Point(
        ellipse.bounds.center.x + ray * Math.cos(i),
        ellipse.bounds.center.y + ray * Math.sin(i)
      );
      let angle_line = new paper.Path.Line(errorPoint, ellipse.bounds.center);

      let inter1 = angle_line.getIntersections(path);
      let inter2 = angle_line.getIntersections(ellipse);

      if (inter1.length >= 1) {
        let deviation = inter1[0].point.getDistance(inter2[0].point);
        let pt = new paper.Point(
          (i / (2 * Math.PI)) * ellipse.length,
          deviation
        );
        deviation_array.push(pt);
      }
    }
    return new paper.Path(deviation_array);
  };

  getClosestPoint(m, b, p1) {
    // Solve for nearest point to p1 to line defined by y = mx + b
    let x2 = (-b + p1.y + p1.x / m) / (m + 1 / m);
    let y2 = (m * p1.y + p1.x + b / m) / (m + 1 / m);
    return new paper.Point(x2, y2);
  }

  getClosestIndex(stroke, pt) {
    // For a given point (pt), find the closest point in the given stroke

    let min_dist = Number.MAX_SAFE_INTEGER;
    let min_index = 0;
    let dist = 0;
    for (let i = 0; i < stroke.segments.length; i++) {
      dist = pt.getDistance(stroke.segments[i].point);
      if (dist < min_dist) {
        min_dist = dist;
        min_index = i;
      }
    }
    return [min_index, min_dist];
  }

  getSlopeInterceptForm(pt1, pt2) {
    // Solve for slope and intercept
    let m = (pt2.y - pt1.y) / (pt2.x - pt1.x);
    let b = pt2.y - m * pt2.x;
    return [m, b];
  }

  getPointFromSlopeInterceptForm(x, m, b) {
    return new paper.Point(x, m * x + b);
  }

  solve(p1, p2, p3, p4) {
    // Find the intersection of the lines defined by P1->P2 and P3->P4
    let x =
      ((p1.x * p2.y - p1.y * p2.x) * (p3.x - p4.x) -
        (p1.x - p2.x) * (p3.x * p4.y - p3.y * p4.x)) /
      ((p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x));

    let y =
      ((p1.x * p2.y - p1.y * p2.x) * (p3.y - p4.y) -
        (p1.y - p2.y) * (p3.x * p4.y - p3.y * p4.x)) /
      ((p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x));

    return new paper.Point(x, y);
  }

  ellipseParams = (a, b, c, d, e, f) => {
    const ellipse_center = new paper.Point({
      x: (2 * c * d - b * e) / (b * b - 4 * a * c),
      y: (2 * a * e - b * d) / (b * b - 4 * a * c),
    });

    let angle;
    if (b != 0.0) {
      angle =
        (180 * Math.atan((c - a - Math.sqrt((a - c) * (a - c) + b * b)) / b)) /
        Math.PI;
    } else if (a < c) {
      angle = 0;
    } else {
      angle = 90;
    }

    const axis_term =
      2 * (a * e * e + c * d * d - b * d * e + (b * b - 4 * a * c) * f);
    const major =
      -Math.sqrt(axis_term * (a + c + Math.sqrt((a - c) * (a - c) + b * b))) /
      (b * b - 4 * a * c);
    const minor =
      -Math.sqrt(axis_term * (a + c - Math.sqrt((a - c) * (a - c) + b * b))) /
      (b * b - 4 * a * c);

    return [ellipse_center, angle, major, minor];
  };

  conicRow(p) {
    // Helper function for determinant solving: format the rows of the matrix
    return [p.x * p.x, p.x * p.y, p.y * p.y, p.x, p.y, 1];
  }

  detTermInner(rows, i1, i2, i3, i4) {
    // Innermost determinant term (4x4 with expanded 3x3 determinants)
    // B, C, D, E are rows of the determinant
    // i1-4 are the column indices

    const [B, C, D, E] = rows;

    let term =
      B[i1] *
        (C[i2] * D[i3] * E[i4] +
          C[i3] * D[i4] * E[i2] +
          C[i4] * D[i2] * E[i3] -
          C[i3] * D[i2] * E[i4] -
          C[i4] * D[i3] * E[i2] -
          C[i2] * D[i4] * E[i3]) -
      B[i2] *
        (C[i1] * D[i3] * E[i4] +
          C[i3] * D[i4] * E[i1] +
          C[i4] * D[i1] * E[i3] -
          C[i1] * D[i4] * E[i3] -
          C[i3] * D[i1] * E[i4] -
          C[i4] * D[i3] * E[i1]) +
      B[i3] *
        (C[i1] * D[i2] * E[i4] +
          C[i2] * D[i4] * E[i1] +
          C[i4] * D[i1] * E[i2] -
          C[i1] * D[i4] * E[i2] -
          C[i2] * D[i1] * E[i4] -
          C[i4] * D[i2] * E[i1]) -
      B[i4] *
        (C[i1] * D[i2] * E[i3] +
          C[i2] * D[i3] * E[i1] +
          C[i3] * D[i1] * E[i2] -
          C[i1] * D[i3] * E[i2] -
          C[i2] * D[i1] * E[i3] -
          C[i3] * D[i2] * E[i1]);

    return term;
  }

  detTermOuter(rows, i0, i1, i2, i3, i4) {
    // Outer determinant term (5x5 matrix); calls detTermInner

    // A, B, C, D, E are the rows of the determinant
    // i0-4 are the column indices

    const [A, B, C, D, E] = rows;
    const inner_rows = [B, C, D, E];

    let term0 = A[i0] * this.detTermInner(inner_rows, i1, i2, i3, i4);
    let term1 = A[i1] * this.detTermInner(inner_rows, i0, i2, i3, i4);
    let term2 = A[i2] * this.detTermInner(inner_rows, i0, i1, i3, i4);
    let term3 = A[i3] * this.detTermInner(inner_rows, i0, i1, i2, i4);
    let term4 = A[i4] * this.detTermInner(inner_rows, i0, i1, i2, i3);

    return term0 - term1 + term2 - term3 + term4;
  }

  conicGeneralForm(p0, p1, p2, p3, p4) {
    // Find the equation of the ellipse in the general form using 5 points:
    // a*x^2 + b*xy + c*y^2 + d*x + e*y + f = 0

    // Solving the 6x6 determinant of:
    // _ : /   x^2     xy     y^2    x    y   f  \
    // A : |  p0x^2  p0xp0y  p0y^2  p0x  p0y  1  |
    // B : |  p1x^2  p1xp1y  p1y^2  p1x  p1y  1  |
    // C : |  p2x^2  p2xp2y  p2y^2  p2x  p2y  1  |
    // D : |  p3x^2  p3xp3y  p3y^2  p3x  p3y  1  |
    // E : \  p4x^2  p4xp4y  p4y^2  p4x  p4y  1  /

    // To improve readability, referring to rows via variable name and storing values in array
    const A = this.conicRow(p0);
    const B = this.conicRow(p1);
    const C = this.conicRow(p2);
    const D = this.conicRow(p3);
    const E = this.conicRow(p4);
    const rows = [A, B, C, D, E];

    let a = this.detTermOuter(rows, 1, 2, 3, 4, 5);
    let b = this.detTermOuter(rows, 0, 2, 3, 4, 5);
    let c = this.detTermOuter(rows, 0, 1, 3, 4, 5);
    let d = this.detTermOuter(rows, 0, 1, 2, 4, 5);
    let e = this.detTermOuter(rows, 0, 1, 2, 3, 5);
    let f = this.detTermOuter(rows, 0, 1, 2, 3, 4);

    return [a, -b, c, -d, e, -f];
  }

  plot_projection(x, y, transform) {
    let a1 = multiply(transform, [[x], [y], [1]]);
    let [x1, y1, z1] = a1.flat().flat();
    x1 /= z1;
    y1 /= z1;
    a1 = new paper.Path.Circle({
      center: [x1, y1],
      radius: 10,
      fillColor: "black",
    });
    return [x1, y1];
  }

  getPerspectiveTransform(src, dst) {
    // Solve for the perspective transformation matrix to map src points to dst
    /* 
      / x_i \ / c00 c01 c02 \   / x_i' \
      | y_i | | c10 c11 c12 | = | y_i' |
      \  1  / \ c20 c21 -1- /   \ -w-  /

      c22 is assumed to be 1 in order to make the system solvable [see openCV for reference]
      https://stackoverflow.com/questions/35819142/calculate-a-2d-homogeneous-perspective-transformation-matrix-from-4-points-in-ma

      Resulting point is divided by weight w to get it back into 2 dimensions
    */

    let [p0, p1, p2, p3] = src; // grab the four points from src
    let [q0, q1, q2, q3] = dst; // grab the four poings from dst

    // Solve Ax = b => x = b*A^(-1)
    let A = [
      [p0.x, p0.y, 1, 0, 0, 0, -p0.x * q0.x, -p0.y * q0.x],
      [p1.x, p1.y, 1, 0, 0, 0, -p1.x * q1.x, -p1.y * q1.x],
      [p2.x, p2.y, 1, 0, 0, 0, -p2.x * q2.x, -p2.y * q2.x],
      [p3.x, p3.y, 1, 0, 0, 0, -p3.x * q3.x, -p3.y * q3.x],
      [0, 0, 0, p0.x, p0.y, 1, -p0.x * q0.y, -p0.y * q0.y],
      [0, 0, 0, p1.x, p1.y, 1, -p1.x * q1.y, -p1.y * q1.y],
      [0, 0, 0, p2.x, p2.y, 1, -p2.x * q2.y, -p2.y * q2.y],
      [0, 0, 0, p3.x, p3.y, 1, -p3.x * q3.y, -p3.y * q3.y],
    ];
    let b = [[q0.x], [q1.x], [q2.x], [q3.x], [q0.y], [q1.y], [q2.y], [q3.y]];

    let transform = multiply(inv(A), b);
    transform = [...transform, [1]].flat(); // add c22 back in and flatten for paper.Matrix
    return new paper.Matrix(transform.slice(0, 6));
  }

  // #####################################
  // -- Load Lesson Functions
  importData = (sketchData, lessonData, timeValues, pressureValues) => {
    this.sketchData = sketchData;
    this.lessonData = lessonData;
    this.timeValues = timeValues;
    this.pressureValues = pressureValues;
  };

  setIndices = (startIndex, endIndex) => {
    this.startIndex = startIndex === "---" ? -1 : startIndex;
    this.endIndex = endIndex === "---" ? -1 : endIndex;
  };

  getBBPoints = (bounds) => {
    // Convert the results from this.findBoundingBox into the 4 points of the bb
    const { width, height, topLeft } = bounds;
    return [
      new paper.Point(topLeft),
      new paper.Point(topLeft.x + width, topLeft.y),
      new paper.Point(topLeft.x, topLeft.y + height),
      new paper.Point(topLeft.x + width, topLeft.y + height),
    ];
  };

  findBoundingBox = (pts) => {
    let x_vals = pts.map((pt) => pt.x);
    let y_vals = pts.map((pt) => pt.y);
    let width = Math.max(...x_vals) - Math.min(...x_vals);
    let height = Math.max(...y_vals) - Math.min(...y_vals);
    let topLeft = new paper.Point({
      x: Math.min(...x_vals),
      y: Math.min(...y_vals),
    });
    return { width, height, topLeft };
  };

  translatePoint = (pt, bb_width, bb_height, bb_topLeft) => {
    // Scale and translate a pt to the canvas based on the given bounding box (bb)

    const scale_hor = this.canvasWidth / bb_width;
    const scale_ver = this.canvasHeight / bb_height;
    let _x = (pt.x - bb_topLeft.x) * scale_hor;
    let _y = (pt.y - bb_topLeft.y) * scale_ver;
    return [_x, _y];
  };

  loadRawSketch = () => {
    // Load the raw sketch data set by importData

    if (typeof this.sketchData.length === "undefined") {
      return;
    }

    let path;
    let combinedPath = new paper.Path();
    //let sketchColor = "red";
    const raw_sketch = [];
    for (let i = 0; i < this.sketchData.length; i++) {
      path = new paper.Path(this.sketchData[i][1]);
      this.sketchColor = path.strokeColor;
      path.strokeColor = null;
      raw_sketch.push(path);
      combinedPath.addSegments(path.segments);
    }
    this.raw_sketch = raw_sketch;
    this.raw_bb = combinedPath.bounds;
    this.numStrokes = raw_sketch.length;
    return this.raw_sketch;
  };

  getBoundingBox = () => {
    // Determine whether to scale based on original canvas size or sketch's bb
    // bb = bounding box
    this.hasCanvasDims =
      "canvasWidth" in this.lessonData && "canvasHeight" in this.lessonData;
    let bb_width = this.hasCanvasDims
      ? this.lessonData.canvasWidth
      : this.raw_bb.width;
    let bb_height = this.hasCanvasDims
      ? this.lessonData.canvasHeight
      : this.raw_bb.height;
    let bb_topLeft = this.hasCanvasDims
      ? new paper.Point(0, 0)
      : this.raw_bb.topLeft;

    return { bb_width, bb_height, bb_topLeft };
  };

  plotTransformedSketch = () => {
    const new_sketch = [];
    let start = this.startIndex === -1 ? 0 : this.startIndex;
    let end = this.endIndex === -1 ? this.raw_sketch.length : this.endIndex;

    const { bb_width, bb_height, bb_topLeft } = this.getBoundingBox();

    // Plot the strokes after fitting to new canvas
    let path;
    for (let i = start; i < end; i++) {
      path = new paper.Path();
      for (let j = 0; j < this.raw_sketch[i].segments.length; j++) {
        let [_x, _y] = this.translatePoint(
          this.raw_sketch[i].segments[j].point,
          bb_width,
          bb_height,
          bb_topLeft
        );
        path.add(new paper.Point(_x, _y));
      }
      path.strokeColor = this.sketchColor;
      new_sketch.push(path);
    }
    return new_sketch;
  };

  loadSketch = () => {
    // Loads and plots sketchData set by importData
    this.loadRawSketch();
    return this.plotTransformedSketch();
  };

  loadPrompt = () => {
    // Stub definition for children classes to re-define
    return;
  };

  async recalculateMetrics() {
    this.loadRawSketch(); // use original data
    this.loadPrompt(); // ensure prompt is defined
    const strokes = this.raw_sketch; // use original data

    let precision = this.getPrecision(strokes).toPrecision(4);
    let smoothness = this.getSmoothness(strokes).toPrecision(4);
    let speed = this.getSpeed(strokes).toPrecision(4);
    return { precision, smoothness, speed };
  }

  async processSketch(pair) {
    // Process sketch and return metrics

    // Load and process sketch
    this.loadRawSketch();
    this.loadPrompt(); // Define perfectShape
    const { precision, smoothness, speed } = await this.recalculateMetrics();
    return new Promise((resolve, reject) => {
      const sketchObject = {
        sketchId: pair[0],
        lessonName: pair[1],
        precision: precision,
        smoothness: smoothness,
        speed: speed,
        timestamp: new Date().toISOString().slice(0, 19).replace("T", " "),
      };
      resolve(sketchObject);
    });
  }

  // #####################################

  recoverCircle = (obj) => {
    // obj is array ["Path", {data}]
    let path = new paper.Path(obj[1]);
    return new paper.Path.Circle({
      center: path.bounds.center,
      radius: path.bounds.width / 2,
    });
  };

  recoverPoint = (obj) => {
    // obj is an array ["Point", x, y]
    return new paper.Point(obj[1], obj[2]);
  };

  solvePlane = (ellipse, center) => {
    // Given an ellipse and its center in perspective, solve for plane
    // Assumes vp1 and vp2 have been set

    // Create lines from vanishing points to center of the ellipse
    const center_side3_guide = new paper.Path.Line({
      from: this.vp1,
      to: center,
    });
    const center_side4_guide = new paper.Path.Line({
      from: this.vp2,
      to: center,
    });

    // Convert guidelines to slope-intercept form
    const [center_vp1_m, center_vp1_b] = this.getSlopeInterceptForm(
      center_side3_guide.firstSegment.point,
      center_side3_guide.lastSegment.point
    );
    const [center_vp2_m, center_vp2_b] = this.getSlopeInterceptForm(
      center_side4_guide.firstSegment.point,
      center_side4_guide.lastSegment.point
    );

    // Extend guidelines to opposite vanishing points
    let side1_pt = this.getPointFromSlopeInterceptForm(
      this.vp2.x,
      center_vp1_m,
      center_vp1_b
    );
    let side2_pt = this.getPointFromSlopeInterceptForm(
      this.vp1.x,
      center_vp2_m,
      center_vp2_b
    );
    const center_side1_guide = new paper.Path.Line({
      from: center,
      to: side1_pt,
    });
    const center_side2_guide = new paper.Path.Line({
      from: center,
      to: side2_pt,
    });

    // Find intersection of resulting lines with ellipse
    const side1 = ellipse.getIntersections(center_side1_guide)[0].point; // on vp2 side; side goes to vp2
    const side2 = ellipse.getIntersections(center_side2_guide)[0].point; // on vp1 side; side goes to vp1

    const side3 = ellipse.getIntersections(center_side3_guide)[0].point; // on vp1 side; side goes to vp2
    const side4 = ellipse.getIntersections(center_side4_guide)[0].point; // on vp2 side; side goes to vp1

    // Create lines for the sides
    const [side1_vp2_m, side1_vp2_b] = this.getSlopeInterceptForm(
      this.vp2,
      side1
    );
    const [side2_vp1_m, side2_vp1_b] = this.getSlopeInterceptForm(
      this.vp1,
      side2
    );
    const [side3_vp2_m, side3_vp2_b] = this.getSlopeInterceptForm(
      this.vp2,
      side3
    );
    const [side4_vp1_m, side4_vp1_b] = this.getSlopeInterceptForm(
      this.vp1,
      side4
    );

    // Extend the lines to the opposite vanishing points
    side1_pt = this.getPointFromSlopeInterceptForm(
      this.vp1.x,
      side1_vp2_m,
      side1_vp2_b
    );
    side2_pt = this.getPointFromSlopeInterceptForm(
      this.vp2.x,
      side2_vp1_m,
      side2_vp1_b
    );
    let side3_pt = this.getPointFromSlopeInterceptForm(
      this.vp1.x,
      side3_vp2_m,
      side3_vp2_b
    );
    let side4_pt = this.getPointFromSlopeInterceptForm(
      this.vp2.x,
      side4_vp1_m,
      side4_vp1_b
    );

    // Solve for intersection of resulting lines
    const p1 = this.solve(side1, side1_pt, side2, side2_pt);
    const p2 = this.solve(side3, side3_pt, p1, this.vp1);
    const p3 = this.solve(side4, side4_pt, p1, this.vp2);
    const p4 = this.solve(p2, this.vp2, p3, this.vp1);

    return [p1, p2, p3, p4];
  };

  recoverEllipse = (obj, pt) => {
    // obj is ["Path", obj]
    const orig_path = new paper.Path(obj[1]);
    const orig_pt = this.recoverPoint(pt);
    return [orig_path, orig_pt];

    // const [p1, p2, p3, p4] = this.solvePlane(orig_path, orig_pt);

    // const [
    //   center,
    //   front_left_mid,
    //   front_right_mid,
    //   back_left_mid,
    //   back_right_mid,
    //   ellipse_center,
    //   angle,
    //   major,
    //   minor,
    // ] = this.defineEllipse(
    //   new paper.Path.Circle({ center: p1, radius: this.masterCircleSize }),
    //   new paper.Path.Circle({ center: p2, radius: this.masterCircleSize }),
    //   new paper.Path.Circle({ center: p3, radius: this.masterCircleSize }),
    //   new paper.Path.Circle({ center: p4, radius: this.masterCircleSize }),
    //   1
    // );

    // const ellipse = new paper.Path.Ellipse({
    //   center: [ellipse_center.x, ellipse_center.y],
    //   radius: [major, minor],
    // });
    // ellipse.rotate(angle);

    // return [ellipse, center];
  };
}

function removeObject(object) {
  playAudio("click");
  object.remove();
}

export default Lesson;
