import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginActions } from "./store/login-context";
import "./components/style.css";

import SideNav from "./components/UI/SideNav";
import LandingPage from "./components/Login/LandingPage";
import LessonsPage from "./components/Lesson/LessonsPage";
import ChallengesPage from "./components/Challenge/ChallengesPage";
import SketchingFoundationsTest from "./components/SketchingFoundationsTest/SketchingFoundationsTest";
// import LoadPage from "./components/LoadSketch/LoadPage";
import LoadSketch from "./components/LoadSketch/LoadSketch";
import LessonsCompleted from "./components/Lesson/LessonsCompleted";
// import GameSelection from "./components/Arcade/GameSelection";
import AssignmentsCompleted from "./components/Assignments/AssignmentsCompleted";
import AllLessonsCompleted from "./components/LoadSketch/AllLessonsCompleted";
import ProcessChopped from "./components/LoadSketch/ProcessChopped";
import ProcessChoppedFull from "./components/LoadSketch/ProcessChoppedFull";

function App() {
  const [activePage, setActivePage] = useState(0);
  const showAllFlag = useSelector((state) => state.login.showAllFlag);
  const SFTMode = useSelector((state) => state.login.SFTMode);
  const loggedIn = useSelector((state) => state.login.loggedIn);
  const dispatch = useDispatch();

  const showLessonsPage = () => {
    // Handler: Navigate to LessonsPage
    dispatch(loginActions.setActiveLesson(showAllFlag));
    setActivePage(1);
  };

  const showChallengesPage = () => {
    // Handler: Navigate to ChallengesPage
    dispatch(loginActions.setActiveLesson(showAllFlag));
    setActivePage(2);
  };

  const showArcade = () => {
    // Handler: Navigate to Arcade
    dispatch(loginActions.setActiveLesson(showAllFlag));
    setActivePage(6);
  };

  const showFrontPage = () => {
    // Handler: navigate to the first page after logging in
    dispatch(loginActions.setActiveLesson(showAllFlag));
    setActivePage(1);
  };

  const showLoadPage = () => {
    // Handler: navigate to the data viewing page
    setActivePage(3);
  };

  const showLessonsCompleted = () => {
    // Handler: navigate to the
    setActivePage(4);
  };

  const showSFTPage = () => {
    setActivePage(5);
  };

  const showAllCompleted = () => {
    setActivePage(7);
  };

  const logoutHandler = () => {
    //loginCtx.logout();
    dispatch(loginActions.logout());
    setActivePage(0);
  };

  const showKanjiCanvas = () => {
    setActivePage(8);
  };

  let content = (
    <LandingPage
      showFrontPage={showFrontPage}
      showKanjiCanvas={showKanjiCanvas}
    />
  );
  //if (loginCtx.loggedIn) {
  //  if (loginCtx.showSFT) {
  if (loggedIn) {
    if (activePage === 1) {
      if (SFTMode) {
        content = <SketchingFoundationsTest />;
      } else {
        content = <LessonsPage />;
      }
    } else if (activePage === 2) {
      // content = <ChallengesPage />;
      content = <ProcessChopped />;
    } else if (activePage === 3) {
      content = <LoadSketch />; //<LoadPage />;
    } else if (activePage === 4) {
      //content = <LessonsCompleted />;
      content = <AssignmentsCompleted />;
    } else if (activePage === 5) {
      content = <SketchingFoundationsTest endSFT={showLessonsPage} />;
      // } else if (activePage === 6) {
      //   content = <GameSelection />;
    } else if (activePage === 7) {
      content = <AllLessonsCompleted />;
    }
  }

  return (
    <React.Fragment>
      {activePage !== 0 && activePage !== 8 && (
        <SideNav
          toLessonsPage={showLessonsPage}
          toChallengesPage={showChallengesPage}
          // toArcade={showArcade}
          toSketchbook={showLessonsPage}
          toSFT={showSFTPage}
          toLoad={showLoadPage}
          toLessonsCompleted={showLessonsCompleted}
          toAllCompleted={showAllCompleted}
          logout={logoutHandler}
        />
      )}
      {content}
    </React.Fragment>
  );
}

export default App;
