import { Fragment, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import LessonRow from "./LessonRow";
import lessonData from "../utils/lessonData";
import { loginActions } from "../../store/login-context";
import LessonMenu from "./LessonMenu";
import ExerciseReview from "./ExerciseReview";
import CategoryStatus from "./CategoryStatus";

//import MainProgressBar from "../ProgressBars/MainProgressBar";

const [
  lineLesson,
  arcLesson,
  circleLesson,
  squareLesson,
  symmetryLesson,
  contourLesson,
  planeLesson,
  ellipseLesson,
  cubeLesson,
  cylinderLesson,
  coneLesson,
  sphereLesson,
  onePointPerspective,
  twoPointPerspective,
  combinationsAdd,
  // warmup2D_5min,
  // warmup_test
] = lessonData;

const initialNav = {
  navBasics: "active",
  navPerspective: "inactive",
  navPrimitives: "inactive",
  navCombos: "inactive",
  navPerception: "inactive",
};

const navReducer = (state, action) => {
  if (action.type === "basics") {
    return initialNav;
  } else if (action.type === "perspective") {
    return {
      navBasics: "full",
      navPerspective: "active",
      navPrimitives: "inactive",
      navCombos: "inactive",
      navPerception: "inactive",
    };
  } else if (action.type === "primitives") {
    return {
      navBasics: "full",
      navPerspective: "full",
      navPrimitives: "active",
      navCombos: "inactive",
      navPerception: "inactive",
    };
  } else if (action.type === "combos") {
    return {
      navBasics: "full",
      navPerspective: "full",
      navPrimitives: "full",
      navCombos: "active",
      navPerception: "inactive",
    };
  } else if (action.type === "perception") {
    return {
      navBasics: "full",
      navPerspective: "full",
      navPrimitives: "full",
      navCombos: "full",
      navPerception: "active",
    };
  }

  // Default
  return state;
};

const LessonsPage = (props) => {
  //console.log("Inside LessonsPage");
  const displayAll = useSelector((state) => state.login.displayAll);
  const [menuOption, setMenuOption] = useState("basics");
  const dispatch = useDispatch();

  const [navState, navDispatch] = useReducer(navReducer, initialNav);

  const setActiveLessonHandler = (type) => {
    dispatch(loginActions.setActiveLesson(type));
  };

  let content = (
    <LessonMenu
      onSelect={setActiveLessonHandler}
      col1={lineLesson}
      col2={arcLesson}
      col3={squareLesson}
      col4={circleLesson}
    />
  );

  const showBasics = () => {
    // Display Basics Exercises
    setMenuOption("basics");
    navDispatch({ type: "basics" });
  };
  const showPerspective = () => {
    // Display Perspective Exercises
    setMenuOption("perspective");
    navDispatch({ type: "perspective" });
  };
  const showPrimitives = () => {
    // Display Primitives Exercises
    setMenuOption("primitives");
    navDispatch({ type: "primitives" });
  };
  const showCombos = () => {
    // Display Combos Exercises
    setMenuOption("combos");

    navDispatch({ type: "combos" });
  };
  const showPerception = () => {
    // Display Perception Exercises
    setMenuOption("perception");
    navDispatch({ type: "perception" });
  };

  if (menuOption === "perspective") {
    // 5. Perspective, 6. Perspective
    content = (
      <LessonMenu
        onSelect={setActiveLessonHandler}
        col1={onePointPerspective}
        col2={twoPointPerspective}
        col3={planeLesson}
        col4={ellipseLesson}
      />
    );
  } else if (menuOption === "primitives") {
    // Cone, Sphere
    content = (
      <LessonMenu
        onSelect={setActiveLessonHandler}
        col1={cubeLesson}
        col2={cylinderLesson}
        col3={coneLesson}
        col4={sphereLesson}
      />
    );
  } else if (menuOption === "combos") {
    // Additive
    content = (
      <LessonMenu
        onSelect={setActiveLessonHandler}
        col1={combinationsAdd}
        col2={{ name: "Blank" }}
        col3={{ name: "Blank" }}
        col4={{ name: "Blank" }}
      />
    );
  } else if (menuOption === "perception") {
    content = (
      <LessonMenu
        onSelect={setActiveLessonHandler}
        col1={contourLesson}
        col2={symmetryLesson}
        col3={{ name: "Blank" }}
        col4={{ name: "Blank" }}
      />
    );
  }

  const lessonClassName = displayAll ? "lessons-left" : "lessons-left-hidden";

  return (
    <Fragment>
      {displayAll && (
        <div style={{ width: "960px", margin: "0 auto", paddingTop: "100px" }}>
          <h1 style={{ textAlign: "left", fontWeight: "bold" }}></h1>
        </div>
      )}

      {displayAll && (
        <div className="lessons-nav">
          <ul>
            <CategoryStatus
              category="Basics"
              name="BASICS"
              id="nav-basics"
              status={navState.navBasics}
              onClick={showBasics}
            />
            <CategoryStatus
              category="Perspective"
              name="PERSPECTIVE"
              id="nav-perspective"
              status={navState.navPerspective}
              onClick={showPerspective}
            />
            <CategoryStatus
              category="Primitives"
              name="PRIMITIVES"
              id="nav-primitives"
              status={navState.navPrimitives}
              onClick={showPrimitives}
            />
            <CategoryStatus
              category="Combinations"
              name="COMBINATIONS"
              id="nav-combos"
              status={navState.navCombos}
              onClick={showCombos}
            />
            <CategoryStatus
              category="Perception"
              name="PERCEPTION"
              id="nav-perception"
              status={navState.navPerception}
              onClick={showPerception}
            />
          </ul>
        </div>
      )}
      <div className={lessonClassName}>{content}</div>
    </Fragment>
  );

};

export default LessonsPage;
