import Lesson from "../Lesson/Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SFTCylinder extends Lesson {
  //Properties

  perfectShape = {
    ellipse1: "",
    ellipse2: "",
    length: 0,
  };

  // p1 is closest to viewer (front)
  // p2 is closest to vp1 (left)
  // p3 is closest to vp2 (right)
  // p4 is closest to horizon (back)

  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  circle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //illColor: this.masterCircleColor,
  });

  circle5 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  circle6 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  circle7 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  circle8 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });

  // Points that define the planes containing the ellipses
  planeCircle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });

  planeCircle5 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle6 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle7 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle8 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });

  squareHeight = Math.min(this.canvasWidth, this.canvasHeight) * 0.5;
  squareWidth = this.squareHeight * 1.6;

  exportLessonData() {
    return {
      name: "SFTCylinder",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };

  generateLesson() {
    // Generate lines based on screen size
    const title = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20)
    );
    title.content = "CYLINDER";
    title.style = {
      fontWeight: "bold",
      fontSize: 20,
      fillColor: "black",
      justification: "center",
    };

    const instructions = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20 + 20)
    );
    instructions.content =
      "Sketch a cylinder in 2-point perspective using the provided cylinder construction lines.";
    instructions.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };

    this.vp2.x = this.canvasWidth * 2;
    this.vp1.x = -this.canvasWidth * 1;
    this.horizonHeight = this.canvasHeight * 0.1;
    this.vp2.y = this.horizonHeight;
    this.vp1.y = this.horizonHeight;

    let orientation = true;

    let x1 = (10 * this.canvasWidth) / 20;
    let y1 = (9 * this.canvasHeight) / 10;
    this.circle1.position = new paper.Point(x1, y1);

    // Calculate measure points
    const [mp1, mp2] = this.findMeasurePoints(x1);

    const [p2, p3, p4] = this.definePlane(x1, y1, mp1, mp2);

    this.circle2.position = p2;
    this.circle3.position = p3;
    this.circle4.position = p4;

    // // Draw lines
    let line1 = new paper.Path.Line({
      from: this.circle1.position,
      to: this.circle2.position,
      strokeColor: this.masterCircleColor,
    });
    let line2 = new paper.Path.Line({
      from: this.circle1.position,
      to: this.circle3.position,
      strokeColor: this.masterCircleColor,
    });
    let line3 = new paper.Path.Line({
      from: this.circle2.position,
      to: this.circle4.position,
      strokeColor: this.masterCircleColor,
    });
    let line4 = new paper.Path.Line({
      from: this.circle3.position,
      to: this.circle4.position,
      strokeColor: this.masterCircleColor,
    });

    const x5 = x1; // second plane aligns with anchor from first
    const y5 = y1 - this.squareHeight;

    const [p6, p7, p8] = this.definePlane(x5, y5, mp1, mp2);

    this.circle5.position = new paper.Point(x5, y5);
    this.circle6.position = p6;
    this.circle7.position = p7;
    this.circle8.position = p8;

    // // Draw lines
    let line5 = new paper.Path.Line({
      from: this.circle5.position,
      to: this.circle6.position,
      strokeColor: this.masterCircleColor,
    });
    let line6 = new paper.Path.Line({
      from: this.circle5.position,
      to: this.circle7.position,
      strokeColor: this.masterCircleColor,
    });
    let line7 = new paper.Path.Line({
      from: this.circle6.position,
      to: this.circle8.position,
      strokeColor: this.masterCircleColor,
    });
    let line8 = new paper.Path.Line({
      from: this.circle7.position,
      to: this.circle8.position,
      strokeColor: this.masterCircleColor,
    });

    // // Define first plane: get center points for circles 2-4
    // const [p2, p3, p4] = this.defineVerticalPlane(
    //   x1,
    //   y1,
    //   mp1,
    //   mp2,
    //   orientation
    // );

    // this.circle2.position = p2;
    // this.circle3.position = p3;
    // this.circle4.position = p4;

    // // Keep track of the first plane
    // this.planeCircle1.center = new paper.Point(x1, y1);
    // this.planeCircle2.center = p2;
    // this.planeCircle3.center = p3;
    // this.planeCircle4.center = p4;

    const [
      center_1,
      horiz_bot_mid_circle_1,
      vert_front_mid_circle_1,
      vert_back_mid_circle_1,
      horiz_top_mid_circle_1,
      ellipse_center_1,
      angle_1,
      major_1,
      minor_1,
    ] = this.defineVerticalEllipse(
      this.circle1,
      this.circle5,
      this.circle2,
      this.circle6,
      orientation,
      1
    );

    this.center1 = center_1;

    // Move the points from the plane corners to the ellipse points
    // this.circle1 = horiz_bot_mid_circle_1;
    // this.circle2 = vert_front_mid_circle_1;
    // this.circle3 = vert_back_mid_circle_1;
    // this.circle4 = horiz_top_mid_circle_1;

    this.perfectShape.ellipse1 = new paper.Path.Ellipse({
      center: [ellipse_center_1.x, ellipse_center_1.y],
      radius: [major_1, minor_1],
      //strokeColor: "red",
    });
    this.perfectShape.ellipse1.rotate(angle_1, ellipse_center_1);

    

    // //   // ###################################################################

    const [
      center_2,
      horiz_bot_mid_circle_2,
      vert_front_mid_circle_2,
      vert_back_mid_circle_2,
      horiz_top_mid_circle_2,
      ellipse_center_2,
      angle_2,
      major_2,
      minor_2,
    ] = this.defineVerticalEllipse(
      this.circle3,
      this.circle7,
      this.circle4,
      this.circle8,
      orientation,
      1
    );

    this.center2 = center_2;

    // Move the points from the plane corners to the ellipse points
    // this.circle5 = horiz_bot_mid_circle_2;
    // this.circle6 = vert_front_mid_circle_2;
    // this.circle7 = vert_back_mid_circle_2;
    // this.circle8 = horiz_top_mid_circle_2;

    this.perfectShape.ellipse2 = new paper.Path.Ellipse({
      center: [ellipse_center_2.x, ellipse_center_2.y],
      radius: [major_2, minor_2],
      //strokeColor: "blue",
    });
    this.perfectShape.ellipse2.rotate(angle_2, ellipse_center_2);

    this.perfectShape.length =
      this.perfectShape.ellipse1.length +
      this.perfectShape.ellipse2.length +
      4 * this.squareHeight;


    // Add points along diagonals
    let temp = this.perfectShape.ellipse1.getIntersections(
      new paper.Path.Line(this.center1, this.planeCircle1.center)
    )[0].point;
    this.diag1_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });
    temp = this.perfectShape.ellipse1.getIntersections(
      new paper.Path.Line(this.center1, this.planeCircle2.center)
    )[0].point;
    this.diag2_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse1.getIntersections(
      new paper.Path.Line(this.center1, this.planeCircle3.center)
    )[0].point;
    this.diag3_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse1.getIntersections(
      new paper.Path.Line(this.center1, this.planeCircle4.center)
    )[0].point;
    this.diag4_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse2.getIntersections(
      new paper.Path.Line(this.center2, this.planeCircle5.center)
    )[0].point;
    this.diag5_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });
    temp = this.perfectShape.ellipse2.getIntersections(
      new paper.Path.Line(this.center2, this.planeCircle6.center)
    )[0].point;
    this.diag6_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse2.getIntersections(
      new paper.Path.Line(this.center2, this.planeCircle7.center)
    )[0].point;
    this.diag7_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse2.getIntersections(
      new paper.Path.Line(this.center2, this.planeCircle8.center)
    )[0].point;
    this.diag8_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });
  }

  getPrecision = (strokes) => {
    let vertical_guideline =
      Math.abs(this.center1.y - this.center2.y) / 2 +
      Math.min(this.center1.y, this.center2.y);

    let horizontal_guideline =
      Math.abs(this.center1.x - this.center2.x) / 2 +
      Math.min(this.center1.x, this.center2.x);

    // Sort the strokes based on guideliens
    let ellipse1_strokes = [];
    let ellipse2_strokes = [];
    let left_strokes = [];
    let right_strokes = [];

    for (let i = 0; i < strokes.length; i++) {
      let stroke = strokes[i];

      let first = stroke.firstSegment.point;
      let last = stroke.lastSegment.point;

      if (first.y < vertical_guideline && last.y < vertical_guideline) {
        ellipse2_strokes.push(stroke);
      } else if (first.y > vertical_guideline && last.y > vertical_guideline) {
        ellipse1_strokes.push(stroke);
      } else if (
        first.x < horizontal_guideline &&
        last.x < horizontal_guideline
      ) {
        left_strokes.push(stroke);
      } else if (
        first.x > horizontal_guideline &&
        last.x > horizontal_guideline
      ) {
        right_strokes.push(stroke);
      }
    }

    let totalDeviation = 0;

    // Calculate deviation
    for (let i = 0; i < ellipse1_strokes.length; i++) {
      let stroke = ellipse1_strokes[i];
      totalDeviation += this.ellipseError(
        stroke,
        this.perfectShape.ellipse1,
        false
      );
    }
    for (let i = 0; i < ellipse2_strokes.length; i++) {
      let stroke = ellipse2_strokes[i];
      totalDeviation += this.ellipseError(
        stroke,
        this.perfectShape.ellipse2,
        false
      );
    }
    for (let i = 0; i < left_strokes.length; i++) {
      let stroke = left_strokes[i];
      totalDeviation += this.checkLine(
        stroke,
        this.diag2_circle.position,
        this.diag6_circle.position,
        false
      );
    }
    for (let i = 0; i < right_strokes.length; i++) {
      let stroke = right_strokes[i];
      totalDeviation += this.checkLine(
        stroke,
        this.diag3_circle.position,
        this.diag7_circle.position,
        false
      );
    }

    let avgDeviation = totalDeviation / this.perfectShape.length;

    return 100 - avgDeviation;
  };

  
}

export default SFTCylinder;
