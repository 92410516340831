import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const SFTOrder = [
  "Warmup",
  "Horizontal",
  "Diagonal",
  "Squares",
  "Circles",
  "Ellipses",
  "Cube",
  "Cylinder",
  "Camera",
];
const SFTFormatter = (_num) => {
  if (_num < SFTOrder.length) {
    return SFTOrder[_num];
  }
  return "ERROR";
};
const SFTNameConverter = (val) => {
  if (val === "Horizontal" || val === "Diagonal") {
    return "SFT".concat(val, "Lines");
  } else if (val === "WarmUp") {
    return "SFTWarmup";
  } else {
    return "SFT".concat(val);
  }
};

const FilteredList = (props) => {
  const loginId = useSelector((state) => state.login.userId);
  const loginToken = useSelector((state) => state.login.token);

  const [fullList, setFullList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("---");

  // Filter options based on previous filter's selection
  const func = props.isIndependent
    ? (val) => true
    : (val) => val[props.filterId] == props.filterValue;
  let options = fullList.filter(func).map((val) => (
    <option key={val[props.id]} value={val[props.id]}>
      {val[props.id]}
    </option>
  ));
  // Ensure that options clear
  if (props.filterValue == null && props.name === "Sketches") {
    options = [];
  }

  // Formatting logic ---------------------------------------
  if (props.name === "Names") {
    options = fullList.filter(func).map((val) => (
      <option key={val[props.id]} value={val[props.id]}>
        {/* {val.FirstName === null || val.LastName === null
          ? "UserId:" + val.UserId
          : val.FirstName + " " + val.LastName} */}
        {"UserId:" + val.UserId}
      </option>
    ));
  }
  if (props.name === "Lessons") {
    options = fullList.filter(func).map((val) => (
      <option key={val[props.id]} value={val[props.id]}>
        {"LessonId:" + val.LessonId + " (" + val.LessonName + ")"}
      </option>
    ));
  }
  if (props.name === "Sketches" && props.sketchCategory === "SFT") {
    // Filter down
    let filtered_ids = fullList.filter(func);
    let id_array = filtered_ids.map((val) => val.SketchId);
    // Cleanup formatting
    options = filtered_ids.map((val) => (
      <option key={val[props.id]} value={val[props.id]}>
        {"SketchId:" +
          val.SketchId +
          " (" +
          SFTFormatter(id_array.indexOf(val.SketchId)) +
          ")"}
      </option>
    ));
  }
  // --------------------------------------------------------

  useEffect(() => {
    // Unique logic for index selection filters
    if (props.name === "StartIndex" || props.name === "EndIndex") {
      let options = [];
      for (let i = 0; i < props.number; i++) {
        if (props.name === "StartIndex") {
          options.push({ number: i });
        }
        if (props.name === "EndIndex") {
          options.push({ number: i + 1 });
        }
      }
      setFullList(options);

      if (props.name === "StartIndex") {
        setSelectedValue(0);
      }
      if (props.name === "EndIndex") {
        setSelectedValue(props.number);
      }
    } else {
      // Load all options from the database
      fetch("/api/" + props.route, {
        method: "POST",
        body: JSON.stringify({
          userId: loginId,
          token: loginToken,
        }),
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            if (data) {
              setFullList(data);
            }
          });
        } else {
          alert("Load " + props.name + " failed");
        }
      });
      return () => {};
    }
  }, [props.number]);

  // Update Selected Value for this filter
  const filterHandler = (event) => {
    let selected = event.target.value;
    let _val = false;
    for (let i = 0; i < fullList.length; i++) {
      if (fullList[i][props.id] == selected) {
        _val = fullList[i];
        break;
      }
    }
    if (!_val) {
      return;
    }

    // Found a match; update filters
    setSelectedValue(selected);
    if (props.name === "StartIndex" || props.name === "EndIndex") {
      // Update stroke indices
      props.updateChoice(selected);
    } else {
      // Send id back to LoadSketch
      props.updateChoice(_val);
    }

    if (props.name === "Lessons" || props.name === "Sketches") {
      let selected_option =
        options[options.map((opt) => opt.key).indexOf(event.target.value)];
      let match =
        typeof selected_option.props.children !== "number"
          ? selected_option.props.children.match(/\((\w+)\)/)[1]
          : selected_option.props.children;

      if (props.name === "Lessons") {
        // Update category
        props.updateSketchCategory(match);
      }
      if (props.name === "Sketches") {
        // Update specific type
        if (props.sketchCategory !== "SFT") {
          props.updateSketchType(props.sketchCategory);
        } else {
          props.updateSketchType(SFTNameConverter(match));
        }
      }
    }
  };

  return (
    <div className="filterCard">
      <div className="filter">
        <div className="filter__control">
          <label>Select {props.title}</label>
          <select value={selectedValue} onChange={filterHandler}>
            <option value="---">---</option>
            {options.length > 0 && options}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FilteredList;
