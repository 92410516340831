import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class CubeLesson extends Lesson {
  //Properties
  exp = 8;
  center = new paper.Point(this.canvasWidth / 2, this.canvasHeight / 2);
  squareHeight = 0;
  perfectShape;

  // p1 is closest to viewer (front)
  // p2 is closest to vp1 (left)
  // p3 is closest to vp2 (right)
  // p4 is closest to horizon (back)

  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });

  circle5 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle6 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle7 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle8 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });

  circle_array = [
    this.circle1,
    this.circle2,
    this.circle3,
    this.circle4,
    this.circle5,
    this.circle6,
    this.circle7,
    this.circle8,
  ];

  adjacencyList = {
    0: [1, 2, 4],
    1: [0, 3, 5],
    2: [0, 3, 6],
    3: [1, 2, 7],
    4: [5, 6, 0],
    5: [4, 7, 1],
    6: [4, 7, 2],
    7: [5, 6, 3],
  };

  exportLessonData = () => {
    return {
      name: "Cubes",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      squareHeight: this.squareHeight,
      perfectShape: this.perfectShape,
      corners: this.corners,
      vp1: this.vp1,
      vp2: this.vp2,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData
    // perfectShape is an object with points and length
    this.perfectShape = {};
    const lbls = ["c1", "c2", "c3", "c4", "c5", "c6", "c7", "c8"];
    if (this.lessonData.hasOwnProperty("perfectShape")) {
      for (let i = 0; i < lbls.length; i++) {
        this.perfectShape[lbls[i]] = this.recoverPoint(
          this.lessonData.perfectShape[lbls[i]]
        );
      }
      this.perfectShape.length = this.lessonData.perfectShape.length;
      // console.log("cube", this.perfectShape);
    } else {
      console.log("Missing perfectShape - defaulting to [0,0] for each point");
      for (let i = 0; i < lbls.length; i++) {
        this.perfectShape[lbls[i]] = new paper.Point(0, 0);
      }
      this.perfectShape.length = 0;
    }

    // Set corners using perfectShape
    this.corners = lbls.map((lbl) => this.perfectShape[lbl]);
  };

  plotGuidelines = (mp1, mp2) => {
    // From vanishing points to front and back points on lower plane
    let solid_line = new paper.Path.Line({
      to: this.circle1.position,
      from: this.vp2,
      strokeColor: this.masterCircleColor,
    });
    solid_line = new paper.Path.Line({
      to: this.circle1.position,
      from: this.vp1,
      strokeColor: this.masterCircleColor,
    });
    solid_line = new paper.Path.Line({
      to: this.circle4.position,
      from: this.vp2,
      strokeColor: this.masterCircleColor,
    });
    solid_line = new paper.Path.Line({
      to: this.circle4.position,
      from: this.vp1,
      strokeColor: this.masterCircleColor,
    });

    // Close the lower plane with dotted lines
    let dotted_line = new paper.Path.Line({
      to: this.circle2.position,
      from: this.circle4.position,
      strokeColor: this.masterCircleColor,
      dashArray: [2, 4],
    });
    dotted_line = new paper.Path.Line({
      to: this.circle3.position,
      from: this.circle4.position,
      strokeColor: this.masterCircleColor,
      dashArray: [2, 4],
    });

    // Add vertical ticks
    let tick;
    tick = new paper.Path.Line({
      from: [
        this.circle1.position.x,
        this.circle1.position.y - this.masterCircleSize,
      ],
      to: [
        this.circle1.position.x,
        this.circle1.position.y - 2 * this.masterCircleSize,
      ],
      strokeColor: this.masterCircleColor,
    });
    tick = new paper.Path.Line({
      from: [
        this.circle2.position.x,
        this.circle2.position.y - this.masterCircleSize,
      ],
      to: [
        this.circle2.position.x,
        this.circle2.position.y - 2 * this.masterCircleSize,
      ],
      strokeColor: this.masterCircleColor,
    });
    tick = new paper.Path.Line({
      from: [
        this.circle3.position.x,
        this.circle3.position.y - this.masterCircleSize,
      ],
      to: [
        this.circle3.position.x,
        this.circle3.position.y - 2 * this.masterCircleSize,
      ],
      strokeColor: this.masterCircleColor,
    });
    tick = new paper.Path.Line({
      from: [
        this.circle4.position.x,
        this.circle4.position.y - this.masterCircleSize,
      ],
      to: [
        this.circle4.position.x,
        this.circle4.position.y - 2 * this.masterCircleSize,
      ],
      strokeColor: this.masterCircleColor,
      dashArray: [2, 4],
    });
    tick = new paper.Path.Line({
      from: [
        this.circle5.position.x,
        this.circle5.position.y + this.masterCircleSize,
      ],
      to: [
        this.circle5.position.x,
        this.circle5.position.y + 2 * this.masterCircleSize,
      ],
      strokeColor: this.masterCircleColor,
    });
    tick = new paper.Path.Line({
      from: [
        this.circle6.position.x,
        this.circle6.position.y + this.masterCircleSize,
      ],
      to: [
        this.circle6.position.x,
        this.circle6.position.y + 2 * this.masterCircleSize,
      ],
      strokeColor: this.masterCircleColor,
    });
    tick = new paper.Path.Line({
      from: [
        this.circle7.position.x,
        this.circle7.position.y + this.masterCircleSize,
      ],
      to: [
        this.circle7.position.x,
        this.circle7.position.y + 2 * this.masterCircleSize,
      ],
      strokeColor: this.masterCircleColor,
    });
    tick = new paper.Path.Line({
      from: [
        this.circle8.position.x,
        this.circle8.position.y + this.masterCircleSize,
      ],
      to: [
        this.circle8.position.x,
        this.circle8.position.y + 2 * this.masterCircleSize,
      ],
      strokeColor: this.masterCircleColor,
      dashArray: [2, 4],
    });

    // Solve for upper plane ticks
    let mp1measure;
    let mp2measure;
    let _pt;

    // Plane ticks based on pt5
    mp1measure = new paper.Path.Line({
      from: new paper.Point(
        this.circle5.position.x - 2 * this.masterCircleSize,
        this.circle5.position.y
      ),
      to: mp1,
    });
    mp2measure = new paper.Path.Line({
      from: new paper.Point(
        this.circle5.position.x + 2 * this.masterCircleSize,
        this.circle5.position.y
      ),
      to: mp2,
    });
    _pt = mp1measure.getIntersections(
      paper.Path.Line({
        from: this.circle5.position,
        to: this.vp1,
      })
    )[0].point;
    tick = new paper.Path.Line({
      from: this.circle5.position,
      to: _pt,
      strokeColor: this.masterCircleColor,
    });
    _pt = mp2measure.getIntersections(
      paper.Path.Line({
        from: this.circle5.position,
        to: this.vp2,
      })
    )[0].point;
    tick = new paper.Path.Line({
      from: this.circle5.position,
      to: _pt,
      strokeColor: this.masterCircleColor,
    });

    mp1measure = new paper.Path.Line({
      from: new paper.Point(
        this.circle5.position.x - this.squareHeight + 2 * this.masterCircleSize,
        this.circle5.position.y
      ),
      to: mp1,
    });
    mp2measure = new paper.Path.Line({
      from: new paper.Point(
        this.circle5.position.x + this.squareWidth - 2 * this.masterCircleSize,
        this.circle5.position.y
      ),
      to: mp2,
    });
    _pt = mp1measure.getIntersections(
      paper.Path.Line({
        from: this.circle5.position,
        to: this.vp1,
      })
    )[0].point;
    tick = new paper.Path.Line({
      from: this.circle6.position,
      to: _pt,
      strokeColor: this.masterCircleColor,
    });
    _pt = this.solve(
      this.vp2,
      _pt,
      this.circle7.position,
      this.circle8.position
    );
    tick = new paper.Path.Line({
      from: this.circle8.position,
      to: _pt,
      strokeColor: this.masterCircleColor,
    });

    _pt = mp2measure.getIntersections(
      paper.Path.Line({
        from: this.circle5.position,
        to: this.vp2,
      })
    )[0].point;
    tick = new paper.Path.Line({
      from: this.circle7.position,
      to: _pt,
      strokeColor: this.masterCircleColor,
    });
    _pt = this.solve(
      this.vp1,
      _pt,
      this.circle6.position,
      this.circle8.position
    );
    tick = new paper.Path.Line({
      from: this.circle8.position,
      to: _pt,
      strokeColor: this.masterCircleColor,
    });

    // Based on pt6
    mp2measure = new paper.Path.Line({
      from: new paper.Point(
        this.circle6.position.x + 2 * this.masterCircleSize,
        this.circle6.position.y
      ),
      to: mp2,
    });
    _pt = mp2measure.getIntersections(
      paper.Path.Line({
        from: this.circle6.position,
        to: this.vp2,
      })
    )[0].point;
    tick = new paper.Path.Line({
      from: this.circle6.position,
      to: _pt,
      strokeColor: this.masterCircleColor,
    });

    // Based on pt7
    mp1measure = new paper.Path.Line({
      from: new paper.Point(
        this.circle7.position.x - 2 * this.masterCircleSize,
        this.circle7.position.y
      ),
      to: mp1,
    });
    _pt = mp1measure.getIntersections(
      paper.Path.Line({
        from: this.circle7.position,
        to: this.vp1,
      })
    )[0].point;
    tick = new paper.Path.Line({
      from: this.circle7.position,
      to: _pt,
      strokeColor: this.masterCircleColor,
    });
  };

  generateLesson() {
    let checkVisible = false;
    let count = 0;
    let mp1, mp2;
    let min_dim = Math.min(this.canvasWidth, this.canvasHeight);

    this.squareHeight = min_dim * 0.3 + Math.random() * min_dim * 0.05;
    this.squareWidth = this.squareHeight;
    while (!checkVisible) {
      if (count > 20) {
        // Shrink to better fit on canvas
        this.squareHeight *= 0.9;
      } else {
        // Choose another random size
        this.squareHeight = min_dim * 0.3 + Math.random() * min_dim * 0.2;
      }
      this.squareWidth = this.squareHeight;

      // Frontmost point
      const x1 = this.canvasWidth / 2;
      let y1 = this.canvasHeight * 0.7;
      // let y1 = this.horizonHeight <= 0 ? this.canvasHeight * 0.7 : this.canvasHeight * 3;
      // if (count > 100) {
      //   y1 = this.canvasHeight * 0.5;
      //   if (this.horizonHeight > 0) {
      //     y1 = this.canvasHeight * 0.3;
      //   }
      // }

      this.circle1.position = new paper.Point(x1, y1);
      this.circle1.fillColor = this.masterCircleColor;

      // Calculate measure points
      [mp1, mp2] = this.findMeasurePoints(x1);

      if (!mp1) {
        // Generated an impossible point in the given perspective
        continue;
      }

      // Define first plane: get center points for circles 2-4
      const [p2, p3, p4] = this.definePlane(x1, y1, mp1, mp2);
      this.circle2.position = p2;
      this.circle2.fillColor = this.masterCircleColor;
      this.circle3.position = p3;
      this.circle3.fillColor = this.masterCircleColor;
      this.circle4.position = p4;
      this.circle4.fillColor = this.masterCircleColor;

      const x5 = x1; // second plane aligns with anchor from first
      const y5 = y1 - this.squareHeight;
      this.circle5.position = new paper.Point(x5, y5);
      this.circle5.fillColor = this.masterCircleColor;

      // Define second plane: get center points for circles 2-4
      const [p6, p7, p8] = this.definePlane(x5, y5, mp1, mp2);
      this.circle6.position = p6;
      this.circle6.fillColor = this.masterCircleColor;
      this.circle7.position = p7;
      this.circle7.fillColor = this.masterCircleColor;
      this.circle8.position = p8;
      this.circle8.fillColor = this.masterCircleColor;

      this.circle_array = [
        this.circle1,
        this.circle2,
        this.circle3,
        this.circle4,
        this.circle5,
        this.circle6,
        this.circle7,
        this.circle8,
      ];

      checkVisible = this.checkPerspectiveVisible(this.circle_array);
      count += 1;

      if (count > 23) {
        console.log("Perspective on edge of screen");
        break;
      }
    }

    // ----  Add extra guidelines -----
    this.plotGuidelines(mp1, mp2);

    // Keep track of the points that define the plane and the length of the combined path
    this.perfectShape = {
      c1: this.circle1.position,
      c2: this.circle2.position,
      c3: this.circle3.position,
      c4: this.circle4.position,
      c5: this.circle5.position,
      c6: this.circle6.position,
      c7: this.circle7.position,
      c8: this.circle8.position,
      length:
        paper.Path.Line(this.circle1.position, this.circle2.position).length +
        paper.Path.Line(this.circle1.position, this.circle3.position).length +
        paper.Path.Line(this.circle4.position, this.circle2.position).length +
        paper.Path.Line(this.circle4.position, this.circle3.position).length +
        paper.Path.Line(this.circle5.position, this.circle6.position).length +
        paper.Path.Line(this.circle5.position, this.circle7.position).length +
        paper.Path.Line(this.circle8.position, this.circle6.position).length +
        paper.Path.Line(this.circle8.position, this.circle7.position).length +
        paper.Path.Line(this.circle1.position, this.circle5.position).length +
        paper.Path.Line(this.circle2.position, this.circle6.position).length +
        paper.Path.Line(this.circle3.position, this.circle7.position).length +
        paper.Path.Line(this.circle4.position, this.circle8.position).length,
    };

    this.corners = [
      this.perfectShape.c1,
      this.perfectShape.c2,
      this.perfectShape.c3,
      this.perfectShape.c4,
      this.perfectShape.c5,
      this.perfectShape.c6,
      this.perfectShape.c7,
      this.perfectShape.c8,
    ];

    return true;
  }

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }

    let cornerCheck = this.isCompleteShape(strokes);
    if (cornerCheck === false) {
      return false;
    }

    playAudio("sketch");
    return this.evaluateSketch(strokes);
  }

  getPrecision(strokes) {
    return this.linearPrecision(strokes, 12);
  }
}

export default CubeLesson;
