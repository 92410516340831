import React, { useState } from "react";

const RegisterInput = (props) => {
  const [value, setValue] = useState(props.value);

  const changeHandler = (event) => {
    setValue(event.target.value);
    props.onChange(event.target.value);
  };

  return (
    <div className="input-field">
      <input
        type={props.type}
        value={value}
        id={props.name}
        name={props.name}
        className="validate"
        onChange={changeHandler}
      />
      <label className={value && "filled"} htmlFor={props.name}>
        {props.label}
      </label>
    </div>
  );
};

export default RegisterInput;
