import React, { useState, useEffect, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import { decodeData } from "./decodeData";
import paper from "paper";
import getLessonFromType from "../utils/getLessonFromType";

//import res from "express/lib/response";

let height_scale = 0.5;
let width_scale = 0.5;

const SFTOrder = [
  "Warmup",
  "Horizontal",
  "Diagonal",
  "Squares",
  "Circles",
  "Ellipses",
];
const SFTFormatter = (_num) => {
  if (_num < SFTOrder.length) {
    return SFTOrder[_num];
  }
  return "ERROR";
};
const SFTNameConverter = (val) => {
  if (val === "Horizontal" || val === "Diagonal") {
    return "SFT".concat(val, "Lines");
  } else if (val === "WarmUp") {
    return "SFTWarmup";
  } else {
    return "SFT".concat(val);
  }
};

const ProcessChopped = (props) => {
  const canvasRef = useRef();
  const canvasWidth = useRef(0);
  const canvasHeight = useRef(0);
  let canvasId = "sketchCanvas";
  const masterCircleColor = useSelector(
    (state) => state.canvas.masterCircleColor
  );
  const masterCircleSize = useSelector(
    (state) => state.canvas.masterCircleSize
  );
  const SFTCircleColor = useSelector((state) => state.canvas.SFTCircleColor);
  const SFTCircleSize = useSelector((state) => state.canvas.SFTCircleSize);
  const showFeedback = useSelector((state) => state.login.showFeedback);

  let sketchCategory = "";
  const canvasParams = {
    name: "",
    variation: props.variation,
    canvasHeight: 0,
    canvasWidth: 0,
    masterCircleColor:
      sketchCategory === "SFT" ? SFTCircleColor : masterCircleColor,
    masterCircleSize:
      sketchCategory === "SFT" ? SFTCircleSize : masterCircleSize,
    vp1: new paper.Point(0, 0),
    vp2: new paper.Point(0, 0),
    showFeedback: showFeedback,
  };

  // ######################################################

  // Determine Lesson object to create
  const [sketchIdsList, setSketchIdsList] = useState([]);

  const loadIds = () => {
    // Load SketchId and LessonName

    // console.log("Loading Lines, Squares, Circles, Ellipses, Cubes, Cylinders")
    console.log("Loading Cylinders");

    // NOTE: pulling from debug table
    fetch("/api/loadLessonSketchIds", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          // Ensure data is sorted
          data.sort((a, b) => {
            return a["SketchId"] < b["SketchId"] ? -1 : 1;
          });

          // Get SketchId, LessonName pairs
          // const subset = ["Cylinders"];
          // const missing = [
          //   35669, 54861, 64747, 64748, 78879, 78880, 78881, 81772,
          // ];
          // 35669 has a NaN in Speed
          // 54861 has a NaN in Smoothness

          // 18893, 18894, 18895, 18896 saved

          // const subset = ["Lines", "Squares", "Circles", "Ellipses", "Cubes", "Cylinders"];
          const subset = ["Cylinders"];
          const pairs = data
            .filter((row) => subset.includes(row["LessonName"]))
            .map((row) => [row["SketchId"], row["LessonName"]]);

          setSketchIdsList(pairs);
          console.log("Loaded", pairs.length, "sketches");

          // Looking for bugged Cylinders
          // setSketchIdsList(pairs.filter((row) => missing.includes(row[0])));
          // console.log(
          //   "Loaded",
          //   pairs.length,
          //   "sketches",
          //   pairs.filter((row) => missing.includes(row[0])).length
          // );
        });
      } else {
        alert("Load SFT Sketch Ids failed");
      }
    });
  };

  const loadSFTIds = () => {
    // Load SketchId and LessonName

    fetch("/api/loadSFTSketchIds", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          console.log(data);

          // Ensure data is sorted
          data.sort((a, b) => {
            if (a["LessonId"] === b["LessonId"]) {
              return a["SketchId"] < b["SketchId"] ? -1 : 1;
            } else {
              return a["LessonId"] < b["LessonId"] ? -1 : 1;
            }
          });

          console.log([... new Set(data.map((row) => row["FK_RegistrationCode"]))]);

          // Get SketchId, LessonId
          const sketchIds = data.map((row) => row["SketchId"]);
          const lessonIds = data.map((row) => row["LessonId"]);

          const pairs = [];
          let prev_lesson = "";
          let count = 0;
          let sketchType;

          // Recover SFT type from order
          for (let i = 0; i < sketchIds.length; i++) {
            if (lessonIds[i] !== prev_lesson) {
              count = 0;
              prev_lesson = lessonIds[i];
            } else {
              count += 1;
            }

            sketchType = SFTNameConverter(SFTFormatter(count));
            if (sketchType !== "SFTERROR" && sketchType !== "SFTWarmup") {
              pairs.push([sketchIds[i], sketchType]);
            }
          }
          setSketchIdsList(pairs);
          console.log("Loaded", pairs.length, "sketches");
        });
      } else {
        alert("Load SFT Sketch Ids failed");
      }
    });
  };

  const loadSFTSketchData = async (pair) => {
    return new Promise((resolve, reject) => {
      fetch("/api/loadSFTSketchDataById", {
        method: "POST",
        body: JSON.stringify({
          sketchId: pair[0],
        }),
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            if (data.length !== 0) {
              console.log("read data");
              resolve(data[0]);
            } else {
              console.log("ERROR");
              reject(pair);
            }
          });
        }
      });
    });
  };

  const loadSketchData = async (pair) => {
    return new Promise((resolve, reject) => {
      fetch("/api/loadLessonSketchDataById", {
        method: "POST",
        body: JSON.stringify({
          sketchId: pair[0],
        }),
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            if (data.length !== 0) {
              console.log("read data");
              resolve(data[0]);
            } else {
              console.log("ERROR");
              reject(pair);
            }
          });
        }
      });
    });
  };

  const saveChoppedMetrics = async (sketchObject) => {
    // Save sketch data
    return new Promise((resolve, reject) => {
      fetch("/api/saveChoppedSFTSketchData/", {
        method: "POST",
        body: JSON.stringify(sketchObject),
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.ok) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  };

  const saveLessonMetrics = async (sketchObject) => {
    // Save sketch data
    return new Promise((resolve, reject) => {
      fetch("/api/saveRecalLessonMetrics/", {
        method: "POST",
        body: JSON.stringify(sketchObject),
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.ok) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    });
  };

  const processLesson = async (pair, res) => {
    // Initialize and decode data
    return new Promise((resolve, reject) => {
      canvasParams.name = pair[1];
      canvasParams.canvasHeight = canvasHeight.current;
      canvasParams.canvasWidth = canvasWidth.current;
      let lesson = getLessonFromType(pair[1], canvasParams);
      lesson.importData(
        decodeData(res.SketchData.data),
        decodeData(res.LessonData.data),
        decodeData(res.TimeValues.data),
        decodeData(res.PressureValues.data)
      );

      if (lesson.lessonData.name.substring(0, 3) === "SFT") {
        lesson.chopSketchMetrics(pair).then((values) => {
          const children = paper.project.activeLayer.removeChildren();
          const results = Promise.all(
            values.map(async (sketchObject) => {
              return saveChoppedMetrics(sketchObject);
            })
          );
          resolve(results);
        });
      } else {
        // console.log("Running", lesson.lessonData.name)
        lesson.processSketch(pair).then((sketchObject) => {
          const children = paper.project.activeLayer.removeChildren();
          resolve(saveLessonMetrics(sketchObject));
        });
      }
    });
  };
  const processData = async () => {
    const batch_size = 50;
    const num_batches = Math.ceil(sketchIdsList.length / batch_size);
    console.log("Processing", num_batches, "batches");

    let pairs, result;
    for (let i = 0; i < num_batches; i++) {
      pairs = sketchIdsList.slice(
        i * batch_size,
        Math.min(sketchIdsList.length, (i + 1) * batch_size)
      );
      result = await Promise.all(
        pairs.map(async (pair) => {
          // return loadSketchData(pair).then((res) => {
          return loadSFTSketchData(pair).then((res) => {
            return processLesson(pair, res);
          });
        })
      ).then(() => {
        console.log("Finished batch", i);
      });
    }
  };

  // #######################################################

  useEffect(() => {
    // Associate Paper JS with Canvas
    paper.setup(canvasRef.current);

    // Make note of canvas size
    canvasHeight.current = paper.view.size.height;
    canvasWidth.current = paper.view.size.width;

    // Add perspective params
    canvasParams.canvasHeight = canvasHeight.current;
    canvasParams.canvasWidth = canvasWidth.current;
  }, []);

  return (
    <Fragment>
      <a
        className="waves-effect waves-light btn"
        // onClick={loadIds}
        onClick={loadSFTIds}
        style={{
          position: "absolute",
          top: "90%",
          left: "70%",
          marginLeft: "5%",
        }}
      >
        Load Data
      </a>
      <a
        className="waves-effect waves-light btn"
        onClick={processData}
        style={{
          position: "absolute",
          top: "90%",
          left: "85%",
          marginLeft: "5%",
        }}
      >
        Process
      </a>

      <div className="clear100" />

      <canvas
        id={canvasId}
        style={{
          height: 100 * height_scale + "%",
          width: 100 * width_scale + "%",
          top: "25%",
          left: "10%",
        }}
        ref={canvasRef}
      />
    </Fragment>
  );
};

export default ProcessChopped;
