import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class ArcLesson extends Lesson {
  exp = 2;
  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  perfectShape = new paper.Path.Arc(
    this.circle1.position,
    this.circle3.position,
    this.circle2.position
  );

  clipEnds = (path) => {
    if (this.variation === "horizontal" || this.variation === "diagonal") {
      for (let i = 0; i < path.segments.length; i++) {
        if (path.segments[i].point.x < this.circle1.position.x - 3) {
          path.removeSegment(i);
          i = i - 1;
        } else if (path.segments[i].point.x > this.circle2.position.x + 3) {
          path.removeSegment(i);
          i = i - 1;
        }
      }
    } else {
      for (let i = 0; i < path.segments.length; i++) {
        if (path.segments[i].point.y < this.circle1.position.y - 3) {
          path.removeSegment(i);
          i = i - 1;
        } else if (path.segments[i].point.y > this.circle2.position.y + 3) {
          path.removeSegment(i);
          i = i - 1;
        }
      }
    }
  };

  exportLessonData = () => {
    return {
      name: "Arcs",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      circle1: this.circle1,
      circle2: this.circle2,
      circle3: this.circle3,
      perfectShape: this.perfectShape,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData

    // perfectShape is a paper.js Arc
    this.perfectShape = this.lessonData.perfectShape;
    this.circle1 = this.lessonData.circle1;
    this.circle2 = this.lessonData.circle2;
    this.circle3 = this.lessonData.circle3;
  };

  generateLesson = () => {
    let x1, y1, x2, y2;
    if (this.variation === "horizontal") {
      x1 = 0.1 * this.canvasWidth + Math.random() * 0.2 * this.canvasWidth;
      y1 = 0.4 * this.canvasHeight + Math.random() * 0.3 * this.canvasHeight;
      x2 = this.canvasWidth - x1;
      y2 = y1;
    } else if (this.variation === "vertical") {
      x1 = this.canvasWidth / 2;
      y1 = 0.15 * this.canvasHeight + Math.random() * 0.2 * this.canvasHeight;
      x2 = x1;
      y2 = this.canvasHeight - y1;
    }

    this.circle1.position.x = x1;
    this.circle1.position.y = y1;

    this.circle2.position.x = x2;
    this.circle2.position.y = y2;

    //Add in construction line
    let constructionLine = new paper.Path.Line({
      from: this.circle1.position,
      to: this.circle2.position,
      strokeColor: this.masterCircleColor,
      strokeCap: "round",
      dashArray: [20, 10],
    });
    let middlePoint = constructionLine.getPointAt(constructionLine.length / 2);

    //Add in
    //Is it horizontal?
    if (this.variation === "horizontal") {
      this.circle3.position.x = middlePoint.x;
      this.circle3.position.y =
        middlePoint.y -
        0.1 * this.canvasHeight -
        Math.random() * 0.2 * this.canvasHeight;
    }
    //Is it vertical?
    else if (this.variation === "vertical") {
      this.circle3.position.x =
        middlePoint.x +
        0.05 * this.canvasWidth +
        Math.random() * 0.1 * this.canvasWidth;
      this.circle3.position.y = middlePoint.y;
    }

    //Add in construction line
    let constructionLine2 = new paper.Path.Line({
      from: middlePoint,
      to: this.circle3.position,
      strokeColor: this.masterCircleColor,
      strokeCap: "round",
      dashArray: [20, 10],
    });

    //Finally, let's ensure we have a perfect Arc
    this.perfectShape = new paper.Path.Arc(
      this.circle1.position,
      this.circle2.position,
      this.circle3.position
    );

    return true;
  };

  recognize = (strokes) => {
    let path = this.combinePath(strokes);
    let center_point = new paper.Path.Circle({
      center: this.circle3.position,
      radius: this.masterCircleSize * 3,
    });

    if (
      path.intersects(this.circle1) &&
      path.intersects(center_point) &&
      path.intersects(this.circle2)
      // &&
      //this.lengthTest(path)
    ) {
      this.clipEnds(path);
      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }
    return false;
  };

  getPrecision = (strokes) => {
    let path = this.combinePath(strokes);

    let deviationAvg = 0;
    let localPerfectArc;
    let errorline = new paper.Path.Line(0, 0);

    //Let's factor in stroke direction
    // Calculating the initial point of the perfectLine
    let pointA = new paper.Point(
      this.perfectShape.segments[0].point.x,
      this.perfectShape.segments[0].point.y
    );
    let pointB = new paper.Point(
      this.perfectShape.segments[this.perfectShape.segments.length - 1].point.x,
      this.perfectShape.segments[this.perfectShape.segments.length - 1].point.y
    );
    // Check for the Reverse of the path
    let distOne = path.segments[0].point.getDistance(pointA);
    let distTwo = path.segments[0].point.getDistance(pointB);
    if (distTwo > distOne) {
      // Forward Direction Line
      localPerfectArc = new paper.Path.Arc(
        pointA,
        this.circle3.position,
        pointB
      );
    } else {
      // Reverse Direction
      localPerfectArc = new paper.Path.Arc(
        pointB,
        this.circle3.position,
        pointA
      );
    }

    const deviation_array = [];
    for (let i = 1; i < localPerfectArc.length; i++) {
      let offset = (i / localPerfectArc.length) * path.length;

      let deviation = Math.abs(
        localPerfectArc.getPointAt(i).getDistance(path.getPointAt(offset))
      );
      deviationAvg += deviation;

      deviation_array.push(new paper.Point(i, deviation));

      if (this.showFeedback) {
        if (deviation > this.deviationThreshold) {
          errorline = new paper.Path.Line(
            localPerfectArc.getPointAt(i),
            path.getPointAt(offset)
          );
          errorline.strokeColor = "rgba(255,0,0,0.2)";
        }
      }
    }
    //Average deviation
    deviationAvg /= this.perfectShape.length;

    this.deviation_line = new paper.Path(deviation_array);

    return 100 - deviationAvg;
  };

  getSmoothness(strokes) {
    let avgAbsAngle =
      this.smoothnessHelper(this.deviation_line) / this.perfectShape.length;
    let normalizedSmoothness = this.normalizeSmoothness(avgAbsAngle);
    return normalizedSmoothness;
  }
}

export default ArcLesson;
