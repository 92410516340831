import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import challenges from "../utils/challengeData";
import { loginActions } from "../../store/login-context";
import ChallengeRow from "./ChallengeRow";

const [
  cameraIsometric,
  app,
  chair,
  car,
  motorcycle,
  citystreet1,
  citystreet2,
  citystreet3,
] = challenges;

const ChallengesPage = (props) => {
  const displayAll = useSelector((state) => state.login.displayAll);
  const dispatch = useDispatch();

  const setActiveLessonHandler = (type) => {
    dispatch(loginActions.setActiveLesson(type));
  };
  
  return (
    <Fragment>
      {displayAll && (
        <div id="pageTitle">
          <h2>Lessons 2D Basics</h2>
        </div>
      )}
      <div className="container">
        <div className="clear100" />
      </div>
      <ChallengeRow
        onSelect={setActiveLessonHandler}
        col1={app}
        col2={chair}
        col3={{name:"Blank"}}
      />
      {/* <ChallengeRow
        onSelect={setActiveLessonHandler}
        col1={car}
        col2={motorcycle}
        col3={{ name: "Blank" }}
      />
      <ChallengeRow
        onSelect={setActiveLessonHandler}
        col1={citystreet1}
        col2={citystreet2}
        col3={citystreet3}
      /> */}
    </Fragment>
  );
};

export default ChallengesPage;
