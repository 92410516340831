import React, { Fragment } from "react";
import { useSelector } from "react-redux";

const LessonStatus = (props) => {
  const lessonNames = useSelector((state) => state.login.lessonNames);
  //const lessonsCompleted = useSelector((state) => state.login.lessonsCompleted);
  const assignmentList = useSelector((state) => state.login.assignmentList);
  const lessonTimestamps = useSelector((state) => state.login.lessonTimestamps);

  let today = new Date(3600000 * Math.floor(Date.now() / 3600000));
  let offset = new Date().getTimezoneOffset();

  let numberAssigned = assignmentList
    .filter((assignment) => props.lessonName in assignment)
    .filter((assignment) => new Date(assignment["StartDate"]) < today)
    .map((assignment) => assignment[props.lessonName])
    .reduce((prev, curr) => prev + curr, 0);
  let numberExtra = 0;
  let lessonsCompleted = 0;

  // Check if assignments have been copmleted

  const lessonObj = lessonTimestamps.map((lesson) => {
    const obj = {};
    obj["lessonName"] = lesson.LessonName;
    obj["timestamp"] = new Date(lesson.Timestamp);
    obj["timestamp"].setMinutes(obj["timestamp"].getMinutes() - offset);
    return obj;
  });
  const remainingCompleted = lessonObj.filter(
    (obj) => obj.lessonName === props.lessonName
  );

  for (let i = 0; i < assignmentList.length; i++) {
    let startDate = new Date(assignmentList[i]["StartDate"]);
    let endDate = new Date(assignmentList[i]["DueDate"]);
    if (startDate.getYear() == 0 || startDate > today) {
      continue;
    }

    // Determine assigned amount and completed (for that assignment)

    let assignmentStatus = 0;
    if (
      props.lessonName in assignmentList[i] &&
      assignmentList[i][props.lessonName] !== 0
    ) {
      for (let j = 0; j < remainingCompleted.length; j++) {

        if (
          assignmentStatus < assignmentList[i][props.lessonName] &&
          remainingCompleted[j].timestamp > startDate
        ) {
          assignmentStatus += 1;
          remainingCompleted.splice(j, 1);
          j -= 1;
        } else if (
          assignmentStatus >= assignmentList[i][props.lessonName] &&
          remainingCompleted[j].timestamp <= endDate
        ) {
          numberExtra += 1;
          remainingCompleted.splice(j, 1);
          j -= 1;
        }
      }
    }
    lessonsCompleted += assignmentStatus;
  }

  let completeColor = "#002A92";
  let incompleteColor = "#D97900"; //"#D97900;
  let statusColor = completeColor;

  let content = 0;
  if (numberAssigned === 0) {
    content = "None Assigned";
  } else if (lessonNames.includes(props.lessonName)) {
    content =
      "Completed: " +
      lessonsCompleted.toString() +
      "/" +
      numberAssigned.toString();

    if (numberExtra > 0) {
      content += " + " + numberExtra.toString();
    }

    if (lessonsCompleted / numberAssigned < 1) {
      statusColor = incompleteColor;
    }
  } else {
    content = "None Assigned";
  }

  return (
    <Fragment>
      {content !== 0 && (
        <div
          className="lessonStatus"
          style={{
            backgroundColor: statusColor,
          }}
        >
          {content}
        </div>
      )}
    </Fragment>
  );
};

export default LessonStatus;
