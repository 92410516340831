import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class ConeLesson extends Lesson {
  //Properties
  exp = 8;
  center = new paper.Point(this.canvasWidth / 2, this.canvasHeight / 2);
  squareHeight = 0;
  squareWidth = 0;
  perfectShape;

  // p1 is closest to viewer (front)
  // p2 is closest to vp1 (left)
  // p3 is closest to vp2 (right)
  // p4 is closest to horizon (back)

  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });

  circle5 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle6 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle7 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle8 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });

  circle_array = [
    this.circle1,
    this.circle2,
    this.circle3,
    this.circle4,
    this.circle5,
    this.circle6,
    this.circle7,
    this.circle8,
  ];

  // Sort strokes into basics
  left_strokes = [];
  right_strokes = [];
  base_strokes = [];

  exportLessonData = () => {
    return {
      name: "Cones",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      center: this.center,
      perfectShape: this.perfectShape,
      vp1: this.vp1,
      vp2: this.vp2,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData
  };

  generateLesson() {
    let mp1, mp2;
    let checkVisible = false;
    let count = 0;

    let min_dim = Math.min(this.canvasWidth, this.canvasHeight);
    this.squareHeight = min_dim * 0.3; // + Math.random() * min_dim * 0.2;
    this.squareWidth = this.squareHeight;
    this.coneHeight = min_dim * 0.35;

    while (!checkVisible) {
      // Frontmost point
      const x1 = this.canvasWidth / 2;
      let y1 = this.canvasHeight * 0.7;
      if (count > 20) {
        this.squareHeight *= 0.9;
        this.squareWidth = this.squareHeight;
        this.coneHeight *= 0.9;
      }
      this.circle1.position = new paper.Point(x1, y1);
      this.circle1.fillColor = this.masterCircleColor;

      // Calculate measure points
      [mp1, mp2] = this.findMeasurePoints(x1);

      if (!mp1) {
        // Generated an impossible point in the given perspective
        continue;
      }

      // Define first plane: get center points for circles 2-4
      const [p2, p3, p4] = this.definePlane(x1, y1, mp1, mp2);
      this.circle2.position = p2;
      this.circle2.fillColor = this.masterCircleColor;
      this.circle3.position = p3;
      this.circle3.fillColor = this.masterCircleColor;
      this.circle4.position = p4;
      this.circle4.fillColor = this.masterCircleColor;

      const x5 = x1; // second plane aligns with anchor from first
      const y5 = y1 - this.coneHeight;
      this.circle5.position = new paper.Point(x5, y5);
      this.circle5.fillColor = this.masterCircleColor;

      // Define second plane: get center points for circles 2-4
      const [p6, p7, p8] = this.definePlane(x5, y5, mp1, mp2);
      this.circle6.position = p6;
      this.circle6.fillColor = this.masterCircleColor;
      this.circle7.position = p7;
      this.circle7.fillColor = this.masterCircleColor;
      this.circle8.position = p8;
      this.circle8.fillColor = this.masterCircleColor;

      this.circle_array = [
        this.circle1,
        this.circle2,
        this.circle3,
        this.circle4,
        this.circle5,
        this.circle6,
        this.circle7,
        this.circle8,
      ];

      checkVisible = this.checkPerspectiveVisible(this.circle_array);
      count += 1;

      if (count > 23) {
        console.log("Perspective on edge of screen");
        break;
      }
    }

    // Create cone based on cube

    let plane_center1 = this.solve(
      this.circle1.position,
      this.circle4.position,
      this.circle3.position,
      this.circle2.position
    );
    let plane_center2 = this.solve(
      this.circle5.position,
      this.circle8.position,
      this.circle7.position,
      this.circle6.position
    );

    let cube_center = this.solve(
      this.circle2.position,
      this.circle7.position,
      this.circle3.position,
      this.circle6.position
    );

    const [
      center,
      front_left_mid,
      front_right_mid,
      back_left_mid,
      back_right_mid,
      ellipse_center,
      angle,
      major,
      minor,
    ] = this.defineEllipse(
      this.circle1,
      this.circle2,
      this.circle3,
      this.circle4,
      1
    );
    this.base1 = front_left_mid;
    this.base2 = front_right_mid;
    this.base3 = back_left_mid;
    this.base4 = back_right_mid;

    const ellipse = new paper.Path.Ellipse({
      center: [ellipse_center.x, ellipse_center.y],
      radius: [major, minor],
      //dashArray: [2, 4],
    });
    ellipse.rotate(angle);
    this.base = ellipse;

    this.center1 = plane_center1;
    this.center2 = plane_center2;
    this.coneTip = plane_center2;
    this.coneTipRadius = new paper.Path.Circle({
      center: this.coneTip,
      radius: this.masterCircleSize,
    });

    // Solve for leftmost and rightmost points
    const horizontal_guideline = new paper.Path.Line({
      from: [0, ellipse_center.y],
      to: [this.canvasWidth, ellipse_center.y],
    });
    let intersections = horizontal_guideline.getIntersections(ellipse);
    this.leftBase = intersections[0].point;
    this.rightBase = intersections[1].point;
    if (intersections[0].point.x > intersections[1].point.x) {
      this.leftBase = intersections[1].point;
      this.rightBase = intersections[0].point;
    }
    this.leftSide = new paper.Path.Line({
      from: this.leftBase,
      to: this.coneTip,
    });
    this.rightSide = new paper.Path.Line({
      from: this.rightBase,
      to: this.coneTip,
    });

    // Keep track of the points that define the plane and the length of the combined path
    this.perfectShape = {
      c1: this.circle1.position,
      c2: this.circle2.position,
      c3: this.circle3.position,
      c4: this.circle4.position,
      c5: this.circle5.position,
      c6: this.circle6.position,
      c7: this.circle7.position,
      c8: this.circle8.position,
      base: this.base,
      leftSide: this.leftSide,
      rightSide: this.rightSide,
      coneTip: this.coneTip,
      leftBase: this.leftBase,
      rightBase: this.rightBase,
      length: this.base.length + this.leftSide.length + this.rightSide.length,
    };

    this.corners = [
      this.perfectShape.c1,
      this.perfectShape.c2,
      this.perfectShape.c3,
      this.perfectShape.c4,
      this.perfectShape.c5,
      this.perfectShape.c6,
      this.perfectShape.c7,
      this.perfectShape.c8,
    ];

    this.adjacencyList = {
      0: [1, 2, 4],
      1: [0, 3, 5],
      2: [0, 3, 6],
      3: [1, 2, 7],
      4: [5, 6, 0],
      5: [4, 7, 1],
      6: [4, 7, 2],
      7: [5, 6, 3],
    };

    if (this.variation === "scaffold") {
      // Make the cube visible
      let _line;
      _line = new paper.Path.Line({
        from: this.circle1.position,
        to: this.circle2.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle1.position,
        to: this.circle3.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle4.position,
        to: this.circle2.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle4.position,
        to: this.circle3.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle5.position,
        to: this.circle6.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle5.position,
        to: this.circle7.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle8.position,
        to: this.circle6.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle8.position,
        to: this.circle7.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle1.position,
        to: this.circle5.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle2.position,
        to: this.circle6.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle3.position,
        to: this.circle7.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle4.position,
        to: this.circle8.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      // Add points along plane definition lines
      // this.circle1.strokeColor = "#1889d3";
      // this.circle2.strokeColor = "#1889d3";
      // this.circle3.strokeColor = "#1889d3";
      // this.circle4.strokeColor = "#1889d3";

      // Add points along diagonals
      let temp = ellipse.getIntersections(
        new paper.Path.Line({
          to: this.center1,
          from: this.circle1.position,
        })
      )[0].point;
      let diag1_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });
      temp = this.perfectShape.base.getIntersections(
        new paper.Path.Line(this.center1, this.circle2.position)
      )[0].point;
      let diag2_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });

      temp = this.perfectShape.base.getIntersections(
        new paper.Path.Line(this.center1, this.circle3.position)
      )[0].point;
      let diag3_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });

      temp = this.perfectShape.base.getIntersections(
        new paper.Path.Line(this.center1, this.circle4.position)
      )[0].point;
      let diag4_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });

      // Add points along edges
      let edge_circle;
      temp = ellipse.getIntersections(
        new paper.Path.Line({
          to: this.circle1.position,
          from: this.circle2.position,
        })
      )[0].point;
      edge_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });
      temp = this.perfectShape.base.getIntersections(
        new paper.Path.Line(this.circle1.position, this.circle3.position)
      )[0].point;
      edge_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });

      temp = this.perfectShape.base.getIntersections(
        new paper.Path.Line(this.circle4.position, this.circle3.position)
      )[0].point;
      edge_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });

      temp = this.perfectShape.base.getIntersections(
        new paper.Path.Line(this.circle4.position, this.circle2.position)
      )[0].point;
      edge_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });

      // Solve for midpoints on front lower plane
      let _mp1measure = new paper.Path.Line({
        from: new paper.Point(
          this.circle1.position.x - this.squareHeight / 2,
          this.circle1.position.y
        ),
        to: mp1,
      });

      // Guideline to determine foreshortening toward vp2
      let _mp2measure = new paper.Path.Line({
        from: new paper.Point(
          this.circle1.position.x + this.squareWidth / 2,
          this.circle1.position.y
        ),
        to: mp2,
      });

      // Determine front-facing sides based on measure points
      let _p2 = _mp1measure.getIntersections(
        paper.Path.Line({
          from: this.circle1.position,
          to: this.vp1,
        })
      )[0].point;

      let _p3 = _mp2measure.getIntersections(
        paper.Path.Line({
          from: this.circle1.position,
          to: this.vp2,
        })
      )[0].point;

      let dotted_line = new paper.Path.Line({
        from: _p2,
        to: this.vp2,
        dashArray: [1, 5],
        strokeColor: "rgb(20, 20, 20, 20)",
      });

      dotted_line = new paper.Path.Line({
        from: _p3,
        to: this.vp1,
        dashArray: [1, 5],
        strokeColor: "rgb(20, 20, 20, 20)",
      });

      // Solve for midpoints on front upper plane
      _mp1measure = new paper.Path.Line({
        from: new paper.Point(
          this.circle5.position.x - this.squareHeight / 2,
          this.circle5.position.y
        ),
        to: mp1,
      });

      // Guideline to determine foreshortening toward vp2
      _mp2measure = new paper.Path.Line({
        from: new paper.Point(
          this.circle5.position.x + this.squareWidth / 2,
          this.circle5.position.y
        ),
        to: mp2,
      });

      // Determine front-facing sides based on measure points
      _p2 = _mp1measure.getIntersections(
        paper.Path.Line({
          from: this.circle5.position,
          to: this.vp1,
        })
      )[0].point;

      _p3 = _mp2measure.getIntersections(
        paper.Path.Line({
          from: this.circle5.position,
          to: this.vp2,
        })
      )[0].point;

      dotted_line = new paper.Path.Line({
        from: _p2,
        to: this.vp2,
        dashArray: [1, 5],
        strokeColor: "rgb(20, 20, 20, 20)",
      });

      dotted_line = new paper.Path.Line({
        from: _p3,
        to: this.vp1,
        dashArray: [1, 5],
        strokeColor: "rgb(20, 20, 20, 20)",
      });
    }

    return true;
  }

  coneSideCheck(strokes) {
    // Confirm that the sides exist

    let left_side = this.combinePath(this.left_strokes);
    let right_side = this.combinePath(this.right_strokes);

    let left_check = left_side.intersects(this.coneTipRadius);
    let right_check = right_side.intersects(this.coneTipRadius);
    // console.log("coneSideCheck:", left_check, right_check);
    return left_check && right_check;
  }

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }

    // Sort strokes into left, right, and base
    let path;
    for (let i = 0; i < strokes.length; i++) {
      path = strokes[i];
      if (this.straightLineCheck(path)) {
        let avg_x =
          path.segments
            .map((val) => val.point.x)
            .reduce((a, b) => {
              return a + b;
            }) / path.segments.length;

        if (avg_x < this.coneTip.x) {
          this.left_strokes.push(path);
        } else {
          this.right_strokes.push(path);
        }
      } else {
        this.base_strokes.push(path);
      }
    }

    let combinedPath = this.combinePath(this.base_strokes);
    // console.log(
    //   combinedPath.intersects(this.base1),
    //   combinedPath.intersects(this.base2),
    //   combinedPath.intersects(this.base3),
    //   combinedPath.intersects(this.base4),
    //   this.coneSideCheck()
    // );

    //Do the checks
    if (
      combinedPath.intersects(this.base1) &&
      combinedPath.intersects(this.base2) &&
      combinedPath.intersects(this.base3) &&
      combinedPath.intersects(this.base4) &&
      this.coneSideCheck()
    ) {
      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }

    // Clear the helper arrays
    this.base_strokes = [];
    this.left_strokes = [];
    this.right_strokes = [];
    return false;
  }

  getPrecision(strokes) {
    let totalDeviation = 0;
    let num_misses = 0;

    // Calculate deviation
    let base_ellipse = this.combinePath(this.base_strokes);
    totalDeviation += this.ellipseError(
      base_ellipse,
      this.base,
      this.showFeedback
    );

    this.base_deviation = this.ellipseDeviation(base_ellipse, this.base);

    let left_side = this.combinePath(this.left_strokes);
    // console.log("left",left_side);
    if (left_side.length !== 0) {
      totalDeviation += this.checkLine(
        left_side,
        this.leftBase,
        this.coneTip,
        this.showFeedback
      );
    } else {
      num_misses += 1;
    }

    let right_side = this.combinePath(this.right_strokes);
    // console.log("right",right_side);
    if (right_side.length !== 0) {
      totalDeviation += this.checkLine(
        right_side,
        this.rightBase,
        this.coneTip,
        this.showFeedback
      );
    } else {
      num_misses += 1;
    }

    let avgDeviation = totalDeviation / this.perfectShape.length;
    //console.log("Average Deviation: " + avgDeviation);

    return 100 - avgDeviation - (num_misses * 100) / 3;
  }

  getSmoothness(strokes) {
    let avgAbsAngle = 0;
    avgAbsAngle += this.smoothnessHelper(this.base_deviation);
    avgAbsAngle += this.smoothnessHelper(
      this.left_strokes.map((val) => val.point)
    );
    avgAbsAngle += this.smoothnessHelper(
      this.right_strokes.map((val) => val.point)
    );

    avgAbsAngle /= this.perfectShape.length;
    let normalizedSmoothness = this.normalizeSmoothness(avgAbsAngle);
    return normalizedSmoothness;
  }
}

export default ConeLesson;
