import Lesson from "../Lesson/Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class ExercisePage extends Lesson {
  // For individual exercise processing
  choppedStrokes = [];
  choppedTime = [];
  choppedPressure = [];
  choppedIndices = [];

  perfectShape = [];

  cols = ["red", "orange", "gold", "green", "blue", "purple", "magenta"];

  // Helper function for finding the closest exercise on single page
  SFTproximityCheck = (first, last, ptA, ptB, idx, min_dev, min_idx) => {
    let dev = this.checkDistance(first, ptA) + this.checkDistance(last, ptB);
    if (dev < min_dev) {
      min_dev = dev;
      min_idx = idx;
    }
    return [min_dev, min_idx];
  };

  SFTsorter = (strokes, prompts) => {
    // Sort the strokes by finding the best matching prompt
    // Match determined by min distance from stroke endpoint to point on prompt

    this.choppedIndices = prompts.map((ele) => []);

    let min = Number.MAX_SAFE_INTEGER;
    let min_index = 0;
    let first, last, dist;
    for (let i = 0; i < strokes.length; i++) {
      first = strokes[i].firstSegment.point;
      last = strokes[i].lastSegment.point;
      min = Number.MAX_SAFE_INTEGER;
      min_index = 0;

      // For each prompt
      for (let j = 0; j < prompts.length; j++) {
        // For each circle in each prompt
        for (let k = 0; k < prompts[j].length; k++) {
          dist = Math.min(
            first.getDistance(prompts[j][k].position),
            last.getDistance(prompts[j][k].position)
          );

          if (dist < min) {
            min = dist;
            min_index = j;
          }
        }
      }

      this.choppedIndices[min_index].push(i);
    }

    return;
  };

  processSketch = (strokes) => {
    // Default: sort input using SFTsorter
    this.SFTsorter(strokes, this.circle_array);
    return;
  };

  evaluateSketch(strokes) {
    if (strokes.length === 0) {
      // No strokes were drawn
      return {
        precision: 0,
        smoothness: 0,
        speed: 0,
        avgPressure: 0,
        exp: 0,
        bonusPrecision: 0,
        bonusSmoothness: 0,
        bonusSpeed: 0,
      };
    }

    this.processSketch(strokes); // define choppedIndices

    let precision = this.getPrecision(strokes);
    let smoothness = this.getSmoothness(strokes);
    let speed = this.getSpeed(strokes);
    let avgPressure = this.getAveragePressure();

    const scores = {
      precision,
      smoothness,
      speed,
      avgPressure,
      exp: this.exp,
    };

    //let middlePoint = path.getPointAt(path.length / 2);
    let middlePoint = new paper.Point(
      this.canvasWidth / 2,
      this.canvasHeight / 2
    );
    const bonuses = this.generateBonuses(
      precision,
      smoothness,
      speed,
      middlePoint
    );

    return { ...scores, ...bonuses };
  }

  getPrecisionArray(strokes) {
    // strokes is rawChopped which has already been sorted
    const precision_array = [];
    // let choppedStrokes;
    // for (let i = 0; i < this.choppedIndices.length; i++) {
    //   choppedStrokes = strokes.filter((stroke, ind) =>
    //     this.choppedIndices[i].includes(ind)
    //   );
    //   precision_array.push(
    //     this.precisionHelper(choppedStrokes, this.perfectShape[i])
    //   );
    // }
    for (let i = 0; i < strokes.length; i++) {
      precision_array.push(
        this.precisionHelper(strokes[i], this.perfectShape[i])
      );
    }
    return precision_array;
  }

  smoothnessDef = (strokes) => {
    // Definition matches getSmoothness(strokes) of Lesson.js which gets overwritten by inheritence

    if (strokes.length === 0) {
      return 0;
    }

    let avgAbsAngle = 0;
    let length = 0;
    for (let ind = 0; ind < strokes.length; ind++) {
      length += strokes[ind].length;
      avgAbsAngle += this.smoothnessHelper(strokes[ind]);
    }
    avgAbsAngle = length !== 0 ? avgAbsAngle / length : 0;
    return this.normalizeSmoothness(avgAbsAngle);
  };

  getSmoothnessArray(strokes) {
    const smoothness_array = [];
    for (let i = 0; i < strokes.length; i++) {
      smoothness_array.push(this.smoothnessDef(strokes[i]));
    }
    return smoothness_array;
  }

  getCurvedSmoothnessArray(strokes) {
    const smoothness_array = [];
    let path, deviation_line;
    for (let i = 0; i < strokes.length; i++) {
      path = this.combinePath(strokes[i]);
      if (path.length === 0) {
        smoothness_array.push(0);
      } else {
        deviation_line = this.ellipseDeviation(path, this.perfectShape[i]);
        smoothness_array.push(this.smoothnessDef([deviation_line]));
      }
    }
    return smoothness_array;
  }

  speedDef(strokes, timeValues) {
    let totalTime = 0;
    let length = 0;

    for (let i = 0; i < strokes.length; i++) {
      length += strokes[i].length;
      totalTime += timeValues[i][timeValues[i].length - 1] - timeValues[i][0];
    }

    let speed = totalTime === 0 ? 0 : (length / totalTime) * 1000;
    return speed;
  }

  getSpeedArray = (strokes) => {
    const speed_array = [];
    let choppedTime;
    for (let i = 0; i < strokes.length; i++) {
      choppedTime = this.timeValues.filter((stroke, ind) =>
        this.choppedIndices[i].includes(ind)
      );
      speed_array.push(this.speedDef(strokes[i], choppedTime));
    }
    return speed_array;
  };

  getPrecision = (strokes) => {
    if (typeof this.perfectShape === "undefined") {
      return 0;
    }
    const precision_array = this.getPrecisionArray(strokes);
    return (
      precision_array.reduce((a, b) => a + b, 0) / this.perfectShape.length
    );
  };

  getSmoothness = (strokes) => {
    if (this.choppedIndices.length === 0) {
      return 0;
    }
    const smoothness_array = this.getSmoothnessArray(strokes);
    return (
      smoothness_array.reduce((a, b) => a + b, 0) / this.perfectShape.length
    );
  };

  getCurvedSmoothness = (strokes) => {
    if (this.choppedIndices.length === 0) {
      return 0;
    }
    const smoothness_array = this.getCurvedSmoothnessArray(strokes);
    return (
      smoothness_array.reduce((a, b) => a + b, 0) / this.perfectShape.length
    );
  };

  getSpeed = (strokes) => {
    const speed_array = this.getSpeedArray(strokes);
    return speed_array.reduce((a, b) => a + b, 0) / speed_array.length;
  };

  recalculateMetrics() {
    this.transformPerfectShape();

    const rawChopped = [];
    let arr = [];
    for (let i = 0; i < this.choppedIndices.length; i++) {
      for (let j = 0; j < this.choppedIndices[i].length; j++) {
        arr.push(
          new paper.Path(this.raw_sketch[this.choppedIndices[i][j]].segments)
        );
      }
      rawChopped.push(arr);
      arr = [];
    }

    let precision = this.getPrecision(rawChopped).toPrecision(4);
    let smoothness = this.getSmoothness(rawChopped).toPrecision(4);
    let speed = this.getSpeed(rawChopped).toPrecision(4);
    return { precision, smoothness, speed };
  }

  solveCanvasDims = () => {
    // For SFT data without stored canvasDims

    // Ensure raw_sketch has been loaded
    this.loadRawSketch();

    // Find the scaling of the bounding box relative to the canvasDims
    const prompt_points = this.generatePromptPoints();
    let prompt_bb_pts;
    if (this.name !== "SFTEllipses") {
      prompt_bb_pts = this.getBBPoints(
        this.findBoundingBox(prompt_points.flat())
      );
    } else {
      const ellipse_objects = this.generateEllipseObjects(false);
      const ellipses = ellipse_objects.map((ele) => ele.ellipse);
      const pts = [];
      for (let i = 0; i < ellipses.length; i++) {
        pts.push(ellipses[i].bounds.topLeft);
        pts.push(ellipses[i].bounds.bottomRight);
      }
      prompt_bb_pts = this.getBBPoints(this.findBoundingBox(pts));
    }

    const prompt_scale_x = prompt_bb_pts.map((ele) => ele.x / this.canvasWidth);
    const prompt_scale_y = prompt_bb_pts.map(
      (ele) => ele.y / this.canvasHeight
    );

    // Get points from raw sketch's bounding box
    const raw_bb_pts = [
      this.raw_bb.topLeft,
      this.raw_bb.topRight,
      this.raw_bb.bottomLeft,
      this.raw_bb.bottomRight,
    ];

    const width_values = [];
    const height_values = [];
    for (let i = 0; i < raw_bb_pts.length; i++) {
      if (prompt_scale_x[i] !== 0) {
        width_values.push(raw_bb_pts[i].x / prompt_scale_x[i]);
      }
      if (prompt_scale_y[i] !== 0) {
        height_values.push(raw_bb_pts[i].y / prompt_scale_y[i]);
      }
    }

    const solvedCanvasWidth =
      width_values.reduce((a, b) => a + b) / width_values.length;
    const solvedCanvasHeight =
      height_values.reduce((a, b) => a + b) / height_values.length;

    return {
      width: solvedCanvasWidth,
      height: solvedCanvasHeight,
      topLeft: new paper.Point(0, 0),
    };
  };

  getBoundingBox = () => {
    this.hasCanvasDims =
      "canvasWidth" in this.lessonData && "canvasHeight" in this.lessonData;
    let bb = this.hasCanvasDims
      ? {
          width: this.lessonData.canvasWidth,
          height: this.lessonData.canvasHeight,
          topLeft: new paper.Point(0, 0),
        }
      : this.solveCanvasDims();
    return { bb_width: bb.width, bb_height: bb.height, bb_topLeft: bb.topLeft };
  };

  transformPerfectShape = () => {
    const bb = this.getBoundingBox();
    const scale_hor = bb.bb_width / this.canvasWidth;
    const scale_ver = bb.bb_height / this.canvasHeight;

    for (let i = 0; i < this.perfectShape.length; i++) {
      this.perfectShape[i].transform(
        new paper.Matrix(scale_hor, 0, 0, scale_ver, 0, 0)
      );
    }
  };

  loadSketch = () => {
    // Plots the user's sketch

    this.loadRawSketch();
    this.loadPrompt(); // Define perfectShape
    const new_sketch = this.plotTransformedSketch();
    this.processSketch(new_sketch);

    return new_sketch;
  };

  //  #############################

  async loadRawPromptAsync() {
    this.loadRawSketch();
    this.loadPrompt(); // Define perfectShape
    return;
  }

  async processSketchAsync() {
    this.processSketch(this.plotTransformedSketch());
    return;
  }

  async recalculateMetricsArrays() {
    this.transformPerfectShape();

    const rawChopped = [];
    let arr = [];
    for (let i = 0; i < this.choppedIndices.length; i++) {
      for (let j = 0; j < this.choppedIndices[i].length; j++) {
        arr.push(
          new paper.Path(this.raw_sketch[this.choppedIndices[i][j]].segments)
        );
      }
      rawChopped.push(arr);
      arr = [];
    }

    let precision = this.getPrecisionArray(rawChopped);
    let smoothness = this.getSmoothnessArray(rawChopped);
    let speed = this.getSpeedArray(rawChopped);
    return { precision, smoothness, speed };
  }

  async chopSketchMetrics(pair) {
    // Process sketch and return metrics

    // Load and process sketch
    await this.loadRawPromptAsync();
    await this.processSketchAsync();
    const { precision, smoothness, speed } =
      await this.recalculateMetricsArrays();

    return new Promise((resolve, reject) => {
      // Create packets from the chopped data
      const values = [];
      let sketchObject;
      for (let j = 0; j < this.perfectShape.length; j++) {
        sketchObject = {
          sketchId: pair[0],
          sketchType: pair[1],
          chopId: j,
          precision: precision[j],
          smoothness: smoothness[j],
          speed: speed[j],
          timestamp: new Date().toISOString().slice(0, 19).replace("T", " "),
        };
        values.push(sketchObject);
      }
      return resolve(values);
    });
  }
}

export default ExercisePage;
