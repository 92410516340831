import React from "react";

const LessonProgressBar = (props) => {
  const barWidth = Math.floor((props.index) / props.numLessons * 100)+"%";
  return (
    <React.Fragment>
      <div id="lessonProgressText">
        <strong>EXERCISE {props.index+1}</strong> OUT OF {props.numLessons}
      </div>
      <div className="lessonProgress">
        <div id="lessonProgressBar" style={{width:barWidth}}></div>
      </div>
    </React.Fragment>
  );
};

export default LessonProgressBar;
