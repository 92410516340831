import LineLesson from "../Lesson/LessonType/LineLesson";
import ArcLesson from "../Lesson/LessonType/ArcLesson";
import SquareLesson from "../Lesson/LessonType/SquareLesson";
import CircleLesson from "../Lesson/LessonType/CircleLesson";
import SymmetryLesson from "../Lesson/LessonType/SymmetryLesson";
import ContourLesson from "../Lesson/LessonType/ContourLesson";
import PlaneLesson from "../Lesson/LessonType/PlaneLesson";
import EllipseLesson from "../Lesson/LessonType/EllipseLesson";
import CubeLesson from "../Lesson/LessonType/CubeLesson";
import CylinderLesson from "../Lesson/LessonType/CylinderLesson";
import ConeLesson from "../Lesson/LessonType/ConeLesson";
import SphereLesson from "../Lesson/LessonType/SphereLesson";
import CombinationsAddLesson from "../Lesson/LessonType/CombinationsAddLesson";

import SFTWarmup from "../SketchingFoundationsTest/0_WarmUp";
import SFTHorizontalLines from "../SketchingFoundationsTest/1_HorizontalLines";
import SFTDiagonalLines from "../SketchingFoundationsTest/2_DiagonalLines";
import SFTSquares from "../SketchingFoundationsTest/3_Squares";
import SFTCircles from "../SketchingFoundationsTest/4_Circles";
import SFTEllipses from "../SketchingFoundationsTest/5_Ellipses";
import SFTCube from "../SketchingFoundationsTest/6_Cube";
import SFTCylinder from "../SketchingFoundationsTest/7_Cylinder";
import SFTCamera from "../SketchingFoundationsTest/8_Camera";

const getLessonFromType = (name, canvasParams) => {
  if (name === "Lines") {
    return new LineLesson(canvasParams);
  } else if (name === "Squares") {
    return new SquareLesson(canvasParams);
  } else if (name === "Arcs") {
    return new ArcLesson(canvasParams);
  } else if (name === "Circles") {
    return new CircleLesson(canvasParams);
  } else if (name === "Symmetry") {
    return new SymmetryLesson(canvasParams);
  } else if (name === "Contours") {
    return new ContourLesson(canvasParams);
  } else if (name === "Planes") {
    return new PlaneLesson(canvasParams);
  } else if (name === "Ellipses") {
    return new EllipseLesson(canvasParams);
  } else if (name === "Cubes") {
    return new CubeLesson(canvasParams);
  } else if (name === "Cylinders") {
    return new CylinderLesson(canvasParams);
  } else if (name === "Cones") {
    return new ConeLesson(canvasParams);
  } else if (name === "Spheres") {
    return new SphereLesson(canvasParams);
  } else if (name === "Additive Combos") {
    return new CombinationsAddLesson(canvasParams);
  } else if (name === "SFTWarmup") {
    return new SFTWarmup(canvasParams);
  } else if (name === "SFTHorizontalLines") {
    return new SFTHorizontalLines(canvasParams);
  } else if (name === "SFTDiagonalLines") {
    return new SFTDiagonalLines(canvasParams);
  } else if (name === "SFTSquares") {
    return new SFTSquares(canvasParams);
  } else if (name === "SFTCircles") {
    return new SFTCircles(canvasParams);
  } else if (name === "SFTEllipses") {
    return new SFTEllipses(canvasParams);
  } else if (name === "SFTCube") {
    return new SFTCube(canvasParams);
  } else if (name === "SFTCylinder") {
    return new SFTCylinder(canvasParams);
  } else if (name === "SFTCamera") {
    return new SFTCamera(canvasParams);
  } 
  return undefined;
};

export default getLessonFromType;
