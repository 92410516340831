import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class LineLesson extends Lesson {
  //Properties
  exp = 1;
  p1 = new paper.Point(0, 0);
  p2 = new paper.Point(0, 0);
  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  perfectShape = new paper.Path.Line(this.p1, this.p2);

  clipEnds(path) {
    if (this.variation === "horizontal" || this.variation === "diagonal") {
      for (let i = 0; i < path.segments.length; i++) {
        if (path.segments[i].point.x < this.circle1.position.x - 3) {
          path.removeSegment(i);
          i = i - 1;
        } else if (path.segments[i].point.x > this.circle2.position.x + 3) {
          path.removeSegment(i);
          i = i - 1;
        }
      }
    } else {
      for (let i = 0; i < path.segments.length; i++) {
        if (path.segments[i].point.y < this.circle1.position.y - 3) {
          path.removeSegment(i);
          i = i - 1;
        } else if (path.segments[i].point.y > this.circle2.position.y + 3) {
          path.removeSegment(i);
          i = i - 1;
        }
      }
    }
  }

  exportLessonData = () => {
    return {
      name: "Lines",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      circle1: this.circle1,
      circle2: this.circle2,
      perfectShape: this.perfectShape,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData

    // perfectShape is a paper.js Line
    this.circle1 = this.recoverCircle(this.lessonData.circle1);
    this.circle2 = this.recoverCircle(this.lessonData.circle2);
    this.perfectShape = new paper.Path.Line(
      this.circle1.position,
      this.circle2.position
    );
  };

  generateLesson() {
    let x1;
    let y1;
    let x2;
    let y2;
    //Is it horizontal?
    if (this.variation === "horizontal") {
      x1 = 0.1 * this.canvasWidth + Math.random() * 0.3 * this.canvasWidth;
      y1 = 0.5 * this.canvasHeight;
      x2 = this.canvasWidth - x1;
      y2 = y1;
    }
    //Is it vertical?
    else if (this.variation === "vertical") {
      x1 = 0.3 * this.canvasWidth + Math.random() * 0.4 * this.canvasWidth;
      y1 = 0.2 * this.canvasHeight + Math.random() * 0.2 * this.canvasHeight;
      x2 = x1;
      y2 = this.canvasHeight - y1;
    }
    //Is it diaganol?
    else if (this.variation === "diagonal") {
      x1 = 0.1 * this.canvasWidth + Math.random() * 0.3 * this.canvasWidth;
      y1 = 0.2 * this.canvasHeight + Math.random() * 0.7 * this.canvasHeight;
      x2 =
        this.canvasWidth -
        (0.1 * this.canvasWidth + Math.random() * 0.3 * this.canvasWidth);
      y2 = 0.2 * this.canvasHeight + Math.random() * 0.7 * this.canvasHeight;
    }

    // DEBUG
    // x1 = 0.2 * this.canvasWidth;
    // x2 = this.canvasWidth - x1;
    // y1 = 0.5 * this.canvasHeight;
    // y2 = y1;

    this.p1.x = x1;
    this.p1.y = y1;
    this.circle1.position.x = x1;
    this.circle1.position.y = y1;

    this.p2.x = x2;
    this.p2.y = y2;
    this.circle2.position.x = x2;
    this.circle2.position.y = y2;

    this.circle1.fillColor = this.masterCircleColor;
    this.circle2.fillColor = this.masterCircleColor;
    //Ensure there is a perfect line
    this.perfectShape = new paper.Path.Line(this.p1, this.p2);

    return true;
  }

  recognize(strokes) {
    let path = this.combinePath(strokes);
    if (
      (path.intersects(this.circle1) || path.intersects(this.circle2)) &&
      this.lengthTest(path)
    ) {
      this.clipEnds(path);
      playAudio("sketch");

      return this.evaluateSketch(strokes);
    }
    return false;
  }

  getPrecision(strokes) {
    let path = this.combinePath(strokes);
    if (path.length === 0) {
      return 0;
    }
    let deviation = this.checkLine(
      path,
      this.perfectShape.segments[0].point,
      this.perfectShape.segments[1].point,
      this.showFeedback
    );
    // console.log("Deviation =", deviation, "length =", this.perfectShape.length);

    //Average deviation
    deviation /= this.perfectShape.length;
    // console.log("Score = ", 100 - deviation);

    const perfect_ink = [];
    let pt;
    for (let i = 0; i < this.perfectShape.length; i++) {
      pt = this.perfectShape.getPointAt(i);
      perfect_ink.push({ x: pt.x, y: pt.y });
    }

    // const ink = path.segments.map((segment) => {
    //   return { x: segment.point.x, y: segment.point.y };
    // });
    const ink = [];
    for (let i = 0; i < path.length; i++) {
      pt = path.getPointAt(i);
      ink.push({ x: pt.x, y: pt.y });
    }
    // console.log("perfect ink:", perfect_ink);
    // console.log("ink", ink);

    // const intersection = new Set([...perfect_ink].filter((x) => ink.has(x)));
    // const union = new Set(perfect_ink);
    // for (const ele of ink) {
    //   union.add(ele);
    // }
    const intersection = perfect_ink.filter(
      (pt1) =>
        ink.filter(
          (pt2) =>
            (pt2.x - pt1.x) * (pt2.x - pt1.x) +
              (pt2.y - pt1.y) * (pt2.y - pt1.y) <
            4 * this.masterCircleSize * this.masterCircleSize
        ).length > 0
    );
    // Note: intersection can double count points
    const union = perfect_ink.length + ink.length - intersection.length;
    // console.log("intersection:", intersection.length, "union =", union);

    // console.log("Score:", 100 - deviation, "vs", intersection.length / union*100);

    return 100 - deviation;
  }
}

export default LineLesson;
