import ExercisePage from "./ExercisePage";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SFTSquares extends ExercisePage {
  //Properties

  letter_array = ["A", "B", "C", "D", "E"];
  circle_array = this.letter_array.map((ele) => [
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
  ]);

  squareHeight = Math.min(this.canvasWidth, this.canvasHeight) / 5;

  horiz_offset = (this.canvasWidth - 3 * this.squareHeight) / 4;
  initial_x1 = this.horiz_offset;
  initial_y1 = this.canvasHeight / 2 - this.canvasHeight / 12;

  example_array = [
    new paper.Path.Circle({
      center: [this.initial_x1, this.initial_y1],
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    }),
    new paper.Path.Circle({
      center: [this.initial_x1, this.initial_y1 - this.squareHeight],
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    }),
    new paper.Path.Circle({
      center: [this.initial_x1 + this.squareHeight, this.initial_y1],
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    }),
    new paper.Path.Circle({
      center: [
        this.initial_x1 + this.squareHeight,
        this.initial_y1 - this.squareHeight,
      ],
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    }),
  ];

  // For splitShape
  adjacencyList = {
    0: [1, 3],
    1: [0, 2],
    2: [1, 3],
    3: [0, 2],
  };

  generatePromptPoints = () => {
    // Generate corner for each of the 6 squares (excluding example) [2 rows of 3]

    const prompt_points = [];
    let x1 = this.initial_x1;
    let y1 = this.initial_y1;

    x1 += this.squareHeight + this.horiz_offset; // skip example location

    let pts = [];
    for (let i = 0; i < 2; i++) {
      pts = [];
      pts.push(new paper.Point(x1, y1));
      pts.push(new paper.Point(x1, y1 - this.squareHeight));
      pts.push(new paper.Point(x1 + this.squareHeight, y1));
      pts.push(new paper.Point(x1 + this.squareHeight, y1 - this.squareHeight));

      x1 += this.squareHeight + this.horiz_offset;
      prompt_points.push(pts);
    }

    x1 = this.horiz_offset;
    y1 += this.squareHeight + (2 * this.canvasHeight) / 12;

    for (let i = 0; i < 3; i++) {
      pts = [];
      pts.push(new paper.Point(x1, y1));
      pts.push(new paper.Point(x1, y1 - this.squareHeight));
      pts.push(new paper.Point(x1 + this.squareHeight, y1));
      pts.push(new paper.Point(x1 + this.squareHeight, y1 - this.squareHeight));

      x1 += this.squareHeight + this.horiz_offset;
      prompt_points.push(pts);
    }
    return prompt_points;
  };

  plotLabels = (i) => {
    let label, sign;
    for (let j = 0; j < 4; j++) {
      sign = j < 2 ? -1 : 1;
      label = new paper.PointText(
        new paper.Point(
          this.circle_array[i][j].position.x + sign * this.masterCircleSize * 4,
          this.circle_array[i][j].position.y + this.masterCircleSize * 0.75
        )
      );
      label.content = this.letter_array[i];
      label.style = {
        fontSize: this.masterCircleSize * 2,
        justification: "center",
      };
    }

    // let label2 = new paper.PointText(
    //   new paper.Point(
    //     this.circle_array[i][1].position.x - this.masterCircleSize * 4,
    //     this.circle_array[i][1].position.y + this.masterCircleSize * 0.75
    //   )
    // );
    // label2.content = this.letter_array[i];
    // label2.style = {
    //   fontSize: this.masterCircleSize * 2,
    //   justification: "center",
    // };

    // let label3 = new paper.PointText(
    //   new paper.Point(
    //     this.circle_array[i][2].position.x + this.masterCircleSize * 4,
    //     this.circle_array[i][2].position.y + this.masterCircleSize * 0.75
    //   )
    // );
    // label3.content = this.letter_array[i];
    // label3.style = {
    //   fontSize: this.masterCircleSize * 2,
    //   justification: "center",
    // };

    // let label4 = new paper.PointText(
    //   new paper.Point(
    //     this.circle_array[i][3].position.x + this.masterCircleSize * 4,
    //     this.circle_array[i][3].position.y + this.masterCircleSize * 0.75
    //   )
    // );
    // label4.content = this.letter_array[i];
    // label4.style = {
    //   fontSize: this.masterCircleSize * 2,
    //   justification: "center",
    // };
  };

  plotExample = () => {
    // Plot the title, instructions, and example square

    const title = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20)
    );
    title.content = "SQUARES";
    title.style = {
      fontWeight: "bold",
      fontSize: 20,
      fillColor: "black",
      justification: "center",
    };

    const instructions = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20 + 20)
    );
    instructions.content =
      "Connect the lettered dots to form squares. \n Sketch quick, confident, continuous lines that do not waver.";
    instructions.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };

    // Add Example Square

    // Left Vertical
    let example1 = new paper.Path.Line({
      from: [
        this.example_array[0].position.x,
        this.example_array[0].position.y + this.masterCircleSize * 2,
      ],
      to: [
        this.example_array[1].position.x,
        this.example_array[1].position.y - this.masterCircleSize * 2,
      ],
    });
    example1.strokeColor = "black";

    // Bottom Horizontal
    let example2 = new paper.Path.Line({
      from: [
        this.example_array[0].position.x - this.masterCircleSize * 2,
        this.example_array[0].position.y,
      ],
      to: [
        this.example_array[2].position.x + this.masterCircleSize * 2,
        this.example_array[2].position.y,
      ],
    });
    example2.strokeColor = "black";

    // Right Vertical
    let example3 = new paper.Path.Line({
      from: [
        this.example_array[3].position.x,
        this.example_array[3].position.y - this.masterCircleSize * 2,
      ],
      to: [
        this.example_array[2].position.x,
        this.example_array[2].position.y + this.masterCircleSize * 2,
      ],
    });
    example3.strokeColor = "black";

    // Top Horizontal
    let example4 = new paper.Path.Line({
      from: [
        this.example_array[3].position.x + this.masterCircleSize * 2,
        this.example_array[3].position.y,
      ],
      to: [
        this.example_array[1].position.x - this.masterCircleSize * 2,
        this.example_array[1].position.y,
      ],
    });
    example4.strokeColor = "black";

    const exampleLabel = new paper.PointText(
      new paper.Point(
        this.example_array[1].position.x + 20,
        this.example_array[1].position.y - 20
      )
    );
    exampleLabel.content = "EXAMPLE";
    exampleLabel.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };
  };

  generateLesson() {
    // Generate prompts based on screen size
    const prompt_points = this.generatePromptPoints();

    for (let i = 0; i < prompt_points.length; i++) {
      for (let j = 0; j < prompt_points[i].length; j++) {
        this.circle_array[i][j].position = prompt_points[i][j];
        this.circle_array[i][j].fillColor = this.masterCircleColor;
      }
      this.plotLabels(i);
    }

    this.plotExample();

    this.perfectShape = this.circle_array.map(
      (square) =>
        new paper.Path.Rectangle(square[0].position, square[3].position)
    );
  }

  precisionHelper = (strokes, prompt) => {
    if (strokes.length === 0) {
      return 0;
    }

    // paper.Path.Rectangle segments are in the order of:
    // Bottom Left, Top Left, Top Right, Bottom Right
    this.corners = prompt.segments.map((ele) => ele.point);
    //console.log("precisionHelper corners", this.corners);

    let segments = this.splitShape(strokes);
    let { total_deviation, num_misses } = this.alignSegments(segments);

    let avgDeviation = total_deviation / prompt.length;
    return Math.max(100 - avgDeviation - (100 * num_misses) / 4, 0);
  };

  loadPrompt = () => {
    const prompt_points = this.generatePromptPoints();
    
    for (let i = 0; i < prompt_points.length; i++) {
      for (let j = 0; j < prompt_points[i].length; j++) {
        this.circle_array[i][j].position = prompt_points[i][j];
        this.circle_array[i][j].fillColor = this.cols[i]; //this.masterCircleColor;
      }
    }

    this.perfectShape = this.circle_array.map(
      (square) =>
        new paper.Path.Rectangle(square[0].position, square[3].position)
    );
  };

  exportLessonData = () => {
    return {
      name: "SFTSquares",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };
}

export default SFTSquares;
