import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import navLogo from "../../images/logo.svg";

const SideNav = (props) => {
  const [showSidenav, setShowSidenav] = useState(false);
  //const showChallenges = useSelector((state) => state.login.showChallenges);
  const SFTMode = useSelector((state) => state.login.SFTMode);
  const debugSFT = useSelector((state) => state.login.debugSFT);
  const showChallenges = debugSFT;

  const showLessons = !SFTMode;
  const showNumCompleted = !SFTMode;

  const toggleSidenav = () => {
    //playAudio('zap');
    console.log("Sidenav Audio Zap");
    setShowSidenav((prev) => !prev);
  };

  const showLessonsPage = () => {
    setShowSidenav((prev) => !prev);
    props.toLessonsPage();
  };

  const showChallengesPage = () => {
    setShowSidenav((prev) => !prev);
    props.toChallengesPage();
  };

  const showArcade = () => {
    setShowSidenav((prev) => !prev);
    props.toArcade();
  };

  const showSFTPage = () => {
    setShowSidenav((prev) => !prev);
    props.toSFT();
  };

  const showLoadPage = () => {
    setShowSidenav((prev) => !prev);
    props.toLoad();
  };

  const showLessonsCompleted = () => {
    setShowSidenav((prev) => !prev);
    props.toLessonsCompleted();
  };

  const showAllCompleted = () => {
    setShowSidenav((prev) => !prev);
    props.toAllCompleted();
  }

  return (
    <Fragment>
      <ul
        className="sidenav"
        style={{
          transform: showSidenav
            ? "translate3d(-0vw, 0, 0)"
            : "translate3d(-105vw, 0, 0)",
          transition: showSidenav
            ? "opacity 0.3s"
            : "opacity 0.3s, transform .3s cubic-bezier(0, .52, 0, 1)",
        }}
      >
        <li>
          <div className="user-view">
            <div className="background">
              <img src={navLogo} />
            </div>
          </div>
        </li>
        {showLessons && (
          <li>
            <a onClick={showLessonsPage}>
              <i className="material-icons white-text">assignment</i>Lessons
            </a>
          </li>
        )}
        {showChallenges && (
          <li>
            <a onClick={showChallengesPage}>
              <i className="material-icons white-text">stars</i>Process Data
            </a>
          </li>
        )}
        {debugSFT && (
          <li>
            <a onClick={showSFTPage}>
              <i className="material-icons white-text">dashboard</i>Sketching Test
            </a>
          </li>
        )}
        {/* {debugSFT && (<li>
          <a onClick={showArcade}>
            {" "}
            <i className="material-icons white-text">videogame_asset</i>Arcade
          </a>
        </li>)} */}
        {/* <li>
          <a onClick={props.toSketchbook}><i className="material-icons white-text">edit</i>Sketchbook</a>
        </li> */}
        {debugSFT && (
          <li>
            <a onClick={showLoadPage}>
              <i className="material-icons white-text">view_list</i>Load Sketches
            </a>
          </li>
        )}
        {(!SFTMode || debugSFT) && (
          <li>
            <a onClick={showLessonsCompleted}>
              <i className="material-icons white-text">dashboard</i>Assignments
            </a>
          </li>
        )}
        {(debugSFT) && (
          <li>
            <a onClick={showAllCompleted}>
              <i className="material-icons white-text">dashboard</i>All Lessons
            </a>
          </li>
        )}
        <li>
          <a onClick={props.logout}>
            <i className="material-icons white-text">exit_to_app</i>Logout
          </a>
        </li>
      </ul>
      <div
        onClick={toggleSidenav}
        className="sidenav-trigger button-collapse toggle-menu"
        style={{
          clear: "none",
          float: "left",
        }}
      />
      <div
        className="sidenav-overlay"
        style={{ display: showSidenav ? "block" : "none" }}
        onClick={toggleSidenav}
      />
    </Fragment>
  );
};

export default SideNav;
