import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SquareLesson extends Lesson {
  //Properties
  exp = 4;
  center = new paper.Point(this.canvasWidth / 2, this.canvasHeight / 2);
  squareHeight = 0;
  squareWidth = 0;
  topLeft;
  bottomRight;
  perfectShape;
  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });

  adjacencyList = {
    0: [1, 3],
    1: [0, 2],
    2: [1, 3],
    3: [0, 2],
  };

  exportLessonData = () => {
    return {
      name: "Squares",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      squareHeight: this.squareHeight,
      squareWidth: this.squareWidth,
      topLeft: this.topLeft,
      bottomRight: this.bottomRight,
      perfectShape: this.perfectShape,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData

    // perfectShape is a paper.js Rectangle

    if (this.lessonData.squareHeight !== 0) {
      this.perfectShape = new paper.Path.Rectangle({
        from: this.recoverPoint(this.lessonData.topLeft),
        to: this.recoverPoint(this.lessonData.bottomRight),
      });
    } else {
      // Edge case for bad sample with only squareHeight=0 and squareWidth=0
      this.perfectShape = new paper.Path.Rectangle({
        from: this.circle2.position,
        to: this.circle4.position,
      });
    }

    // Set corners using perfectShape
    this.circle1.position = this.perfectShape.segments[0].point; // Bottom Left
    this.circle2.position = this.perfectShape.segments[1].point; // Top Left
    this.circle3.position = this.perfectShape.segments[2].point; // Top Right
    this.circle4.position = this.perfectShape.segments[3].point; // Bottom Right
    this.circle1.fillColor = "white";
    this.circle2.fillColor = "white";
    this.circle3.fillColor = "white";
    this.circle4.fillColor = "white";

    // Keep track of corner locations and adjacency list for recognize
    this.corners = [
      this.circle1.position,
      this.circle2.position,
      this.circle3.position,
      this.circle4.position,
    ];
  };

  generateLesson() {
    //Random size
    this.squareHeight =
      this.canvasHeight * 0.2 + Math.random() * this.canvasHeight * 0.2;

    if (this.variation === "square") {
      this.squareWidth = this.squareHeight;
      this.topLeft = new paper.Point(
        this.center.x - this.squareHeight * 0.5,
        this.center.y - this.squareHeight * 0.5
      );
      this.bottomRight = new paper.Point(
        this.center.x + this.squareHeight * 0.5,
        this.center.y + this.squareHeight * 0.5
      );
      this.perfectShape = new paper.Path.Rectangle(
        this.topLeft,
        this.bottomRight
      );
    } else {
      //Random length
      this.squareWidth =
        this.canvasWidth * 0.1 + Math.random() * this.canvasWidth * 0.4;
      this.topLeft = new paper.Point(
        this.center.x - this.squareWidth * 0.5,
        this.center.y - this.squareHeight * 0.5
      );
      this.bottomRight = new paper.Point(
        this.center.x + this.squareWidth * 0.5,
        this.center.y + this.squareHeight * 0.5
      );
      this.perfectShape = new paper.Path.Rectangle(
        this.topLeft,
        this.bottomRight
      );
    }

    //Display helper points
    this.circle1.position = this.perfectShape.segments[0].point; // Bottom Left
    this.circle2.position = this.perfectShape.segments[1].point; // Top Left
    this.circle3.position = this.perfectShape.segments[2].point; // Top Right
    this.circle4.position = this.perfectShape.segments[3].point; // Bottom Right

    // Keep track of corner locations and adjacency list for recognize
    this.corners = [
      this.circle1.position,
      this.circle2.position,
      this.circle3.position,
      this.circle4.position,
    ];

    return true;
  }

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }

    let combinedPath = this.combinePath(strokes);
    let isCompleteShape = this.isCompleteShape(strokes);

    if (
      isCompleteShape &&
      combinedPath.intersects(this.circle1) &&
      combinedPath.intersects(this.circle2) &&
      combinedPath.intersects(this.circle3) &&
      combinedPath.intersects(this.circle4)
    ) {
      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }
    return false;
  }

  getPrecision(strokes) {
    return this.linearPrecision(strokes, 4);
  }
}

export default SquareLesson;
