import { useEffect, useState } from "react";
import { canvasActions } from "../../store/canvas-context";
import { useSelector } from "react-redux";

const SketchPadTools = (props) => {

  const eraserClass = props.activeTool === "eraser" ? "active" : "";
  const bluepencilClass = props.activeTool === "bluepencil" ? "active" : "";
  const pencilClass = props.activeTool === "pencil" ? "active" : "";
  const shapeListenerClass = props.activeTool === "shapeListener" ? "active" : "";
  const markerfineClass = props.activeTool === "markerfine" ? "active" : "";
  const markerClass = props.activeTool === "marker" ? "active" : "";
  const marker20Class = props.activeTool === "marker20" ? "active" : "";
  const marker40Class = props.activeTool === "marker40" ? "active" : "";
  const marker60Class = props.activeTool === "marker60" ? "active" : "";
  const marker80Class = props.activeTool === "marker80" ? "active" : "";
  const marker100Class = props.activeTool === "marker100" ? "active" : "";

  const eraserHandler = () => {
    props.updateTool("eraser");
  };

  const bluepencilHandler = () => {
    props.updateTool("bluepencil");
  };

  const pencilHandler = () => {
    props.updateTool("pencil");
  };

  const shapeListenerHandler = () => {
    props.updateTool("shapeListener");
  }

  const markerfineHandler = () => {
    props.updateTool("markerfine");
  };

  const markerHandler = () => {
    props.updateTool("marker");
  };

  const marker20Handler = () => {
    props.updateTool("marker20");
  };

  const marker40Handler = () => {
    props.updateTool("marker40");
  };

  const marker60Handler = () => {
    props.updateTool("marker60");
  };

  const marker80Handler = () => {
    props.updateTool("marker80");
  };

  const marker100Handler = () => {
    props.updateTool("marker100");
  };


  let toolButtons = [
    {
      id: "eraser",
      key: "eraser",
      className: eraserClass,
      onClick: eraserHandler,
    },
    // {
    //   id: "bluepencil",
    //   key: "bluepencil",
    //   className: bluepencilClass,
    //   onClick: bluepencilHandler,
    // },
    {
      id: "pencil",
      key: "pencil",
      className: pencilClass,
      onClick: pencilHandler,
    },
    // {
    //   id: "markerfine",
    //   key: "markerfine",
    //   className: markerfineClass,
    //   onClick: markerfineHandler,
    // },
    // {
    //   id: "marker",
    //   key: "marker",
    //   className: markerClass,
    //   onClick: markerHandler,
    // },
    // {
    //   id: "marker20",
    //   key: "marker20",
    //   className: marker20Class,
    //   onClick: marker20Handler,
    // },
    // {
    //   id: "marker40",
    //   key: "marker40",
    //   className: marker40Class,
    //   onClick: marker40Handler,
    // },
    // {
    //   id: "marker60",
    //   key: "marker60",
    //   className: marker60Class,
    //   onClick: marker60Handler,
    // },
    // {
    //   id: "marker80",
    //   key: "marker80",
    //   className: marker80Class,
    //   onClick: marker80Handler,
    // },
    // {
    //   id: "marker100",
    //   key: "marker100",
    //   className: marker100Class,
    //   onClick: marker100Handler,
    // },
  ];

  if (props.eraserOnly) {
    toolButtons = [
      {
        id: "eraser",
        key: "eraser",
        className: eraserClass,
        onClick: eraserHandler,
      },
      {
        id: "shapeListener",
        key: "shapeListener",
        className: shapeListenerClass,
        onClick: shapeListenerHandler,
      },
    ];
  }

  let content = toolButtons.map((button) => (
    <li
      id={button.id}
      key={button.key}
      className={button.className}
      onClick={button.onClick}
    ></li>
  ));

  return (
    <div id="sketchpadTools" className="active">
      {/* <div id="sketchTitle" className="active" onClick="toggleSketchTitle()">
        Untitled Sketch
      </div> */}
      <div id="editSketchTitle">
        <input
          style={{
            display: "inline-block",
          }}
          id="sketchTitleInput"
          // onKeyDown="enterSketchTitle(event)"
        />
        <div
          className="button"
          style={{
            display: "inline-block",
          }}
          // onClick="toggleSketchTitle()"
        >
          Change
        </div>
      </div>

      <div className="tools">
        <ul>{content}</ul>
      </div>

      {!props.eraserOnly && <div className="options">
        <ul>
          <li id="grid-button" onClick={props.gridToggle}></li>
          <li id="onePt-button" onClick={props.onePointToggle}></li>
          <li id="twoPt-button" onClick={props.twoPointToggle}></li>
          {/* <li id="save" onClick="saveImage()"></li> */}
        </ul>
      </div>}

      <div id="gridOptions" style={{ display: "none" }}>
        <ul>
          <li id="1pt" className="onePt-button"></li>
          <li id="2pt" className="twoPt-button"></li>
        </ul>
      </div>

      {/* <div id="help" onClickName="toggleLessonOverlay()"></div> */}
    </div>
  );
};

export default SketchPadTools;
