import ExercisePage from "./ExercisePage";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SFTDiagonalLines extends ExercisePage {
  // "A" is the example
  letter_array = ["B", "C", "D", "E", "F", "G"];
  circle_array = this.letter_array.map((ele) => [
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
  ]);

  // Create circle to determine prompt locations
  radius = Math.min(this.canvasWidth, this.canvasHeight) / 3;
  center = new paper.Point(this.canvasWidth / 2, this.canvasHeight / 2);
  outline = new paper.Path.Circle({
    center: this.center,
    radius: this.radius,
  });
  centerCircle = new paper.Path.Circle({
    center: this.center,
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });

  // for spacing of lines
  num_points = 20;
  prompt_ind1 = 3;
  prompt_ind2 = 13;

  perfectShape = [];

  generatePromptPoints = () => {
    const prompt_points = [];
    let ind1 = this.prompt_ind1 - 1;
    let ind2 = this.prompt_ind2 - 1;
    let pt1, pt2;

    for (let i = 0; i < this.circle_array.length; i++) {
      pt1 = this.outline.getPointAt(
        (ind1 * this.outline.length) / this.num_points
      );
      pt2 = this.outline.getPointAt(
        (ind2 * this.outline.length) / this.num_points
      );

      ind1 -= 1;
      ind2 -= 1;

      if (ind1 < 0) {
        ind1 = this.num_points - 1;
      }

      prompt_points.push([pt1, pt2]);
    }

    return prompt_points;
  };

  promptHelper = (i, pts) => {
    // Plot the prompt circles for each line and add labels
    const [pt1, pt2] = pts;

    this.circle_array[i][0].position = pt1;
    this.circle_array[i][0].fillColor = this.masterCircleColor;

    let label1 = new paper.PointText(
      new paper.Point(
        pt1.x - this.masterCircleSize * 4,
        pt1.y + this.masterCircleSize * 0.75
      )
    );
    label1.content = this.letter_array[i];
    label1.style = {
      fontSize: this.masterCircleSize * 2,
      justification: "center",
    };

    this.circle_array[i][1].position = pt2;
    this.circle_array[i][1].fillColor = this.masterCircleColor;

    let label2 = new paper.PointText(
      new paper.Point(
        pt2.x + this.masterCircleSize * 4,
        pt2.y + this.masterCircleSize * 0.75
      )
    );
    label2.content = this.letter_array[i];
    label2.style = {
      fontSize: this.masterCircleSize * 2,
      justification: "center",
    };
  };

  plotExample = () => {
    // Plot the title, instructions, and example line (A)

    const title = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20)
    );
    title.content = "STRAIGHT LINES 2";
    title.style = {
      fontWeight: "bold",
      fontSize: 20,
      fillColor: "black",
      justification: "center",
    };

    const instructions = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20 + 20)
    );
    instructions.content =
      "Connect the lettered dots with crossing lines, \n being sure to pass through the center point. \n Make quick confident lines.";
    instructions.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };

    let pt1 = this.outline.getPointAt(
      (this.prompt_ind1 * this.outline.length) / this.num_points
    );
    let label1 = new paper.PointText(
      new paper.Point(
        pt1.x - this.masterCircleSize * 8,
        pt1.y + this.masterCircleSize * 0.75
      )
    );
    label1.content = "EXAMPLE";
    label1.style = {
      fontSize: this.masterCircleSize * 2,
      justification: "center",
    };
    let circle1 = new paper.Path.Circle({
      center: pt1,
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    });

    let pt2 = this.outline.getPointAt(
      (this.prompt_ind2 * this.outline.length) / this.num_points
    );
    let label2 = new paper.PointText(
      new paper.Point(
        pt2.x + this.masterCircleSize * 8,
        pt2.y + this.masterCircleSize * 0.75
      )
    );
    label2.content = "EXAMPLE";
    label2.style = {
      fontSize: this.masterCircleSize * 2,
      justification: "center",
    };
    let circle2 = new paper.Path.Circle({
      center: pt2,
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    });

    let exampleLine = new paper.Path.Line(pt1, pt2);
    exampleLine.strokeColor = "black";
  };

  generateLesson() {
    // Generate lines based on screen size

    const prompt_points = this.generatePromptPoints();
    for (let i = 0; i < prompt_points.length; i++) {
      this.promptHelper(i, prompt_points[i]);
    }

    this.plotExample(); // plot instructions, title, and example line A

    this.perfectShape = this.circle_array.map(
      (pair) =>
        new paper.Path.Line({ from: pair[0].position, to: pair[1].position })
    );
  }

  processSketch = (strokes) => {
    // Sort input strokes by slope

    const prompt_y = this.perfectShape.map(
      (ele) =>
        (ele.lastSegment.point.y - ele.firstSegment.point.y) /
        (ele.lastSegment.point.x - ele.firstSegment.point.x)
    );
    this.choppedIndices = this.perfectShape.map((ele) => []);

    let min = Number.MAX_SAFE_INTEGER;
    let min_index = 0;

    let path, metric, diff;
    for (let i = 0; i < strokes.length; i++) {
      path = strokes[i];

      for (let j = 0; j < prompt_y.length; j++) {
        metric =
          (path.lastSegment.point.y - path.firstSegment.point.y) /
          (path.lastSegment.point.x - path.firstSegment.point.x);

        diff = Math.abs(prompt_y[j] - metric);

        if (diff < min) {
          min = diff;
          min_index = j;
        }
      }

      this.choppedIndices[min_index].push(i);

      min = Number.MAX_SAFE_INTEGER;
      min_index = 0;
    }
  };

  precisionHelper = (strokes, prompt) => {
    let deviation = 0;
    let path = this.combinePath(strokes);
    if (path.segments.length !== 0) {
      deviation = this.checkLine(
        path,
        prompt.segments[0].point,
        prompt.segments[1].point,
        true
      );

      return 100 - deviation / prompt.length;
    }
    return 0;
  };

  loadPrompt = () => {
    // Get prompts' initial location
    const prompt_points = this.generatePromptPoints();

    for (let i = 0; i < prompt_points.length; i++) {
      for (let j = 0; j < prompt_points[i].length; j++) {
        this.circle_array[i][j].position = prompt_points[i][j];
        this.circle_array[i][j].fillColor = this.cols[i]; //this.masterCircleColor;
      }
    }

    this.perfectShape = this.circle_array.map(
      (pair) =>
        new paper.Path.Line({ from: pair[0].position, to: pair[1].position })
    );
  };

  // translatePerfectShape = () => {
  //   const bb = this.getBoundingBox();
  //   const new_width = bb.width / this.canvasWidth;
  //   const new_height = bb.height / this.canvasHeight;

  //   for (let i = 0; i < this.perfectShape.length; i++) {
  //     this.perfectShape[i].scale(new_width, new_height);
  //   }
  // };

  exportLessonData = () => {
    return {
      name: "SFTDiagonalLines",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };
}

export default SFTDiagonalLines;
