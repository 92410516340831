import { useState, useRef } from "react";
import RegisterInput from "./RegisterInput";
import { useDispatch } from "react-redux";
import { loginActions } from "../../store/login-context";

const RegisterPage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [registrationCode, setRegistrationCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [selectRight, setSelectRight] = useState(true);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const dispatch = useDispatch();

  const setRight = () => {
    setSelectRight(true);
  };
  const setLeft = () => {
    setSelectRight(false);
  };

  const clearForm = () => {
    setUsername("");
    setPassword("");
    setPasswordConfirm("");
    setRegistrationCode("");
    setFirstName("");
    setLastName("");
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setSubmitDisabled(true);

    if (registrationCode === "") {
      alert("Invalid Registration Code");
      clearForm();
      return;
    }

    if (password !== passwordConfirm) {
      alert("Passwords don't match");
      clearForm();
      return;
    }

    if (username === "") {
      alert("Username cannot be empty");
      return;
    }

    if (password === "") {
      alert("Password cannot be empty");
      return;
    }

    fetch("/api/registrationCode", {
      method: "POST",
      body: JSON.stringify({
        registrationCode: registrationCode,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          if (data) {
            // Valid registration code
            fetch("/api/register", {
              method: "POST",
              body: JSON.stringify({
                username: username,
                password: password,
                registrationCode: data["RegistrationCode"],
                firstName: firstName,
                lastName: lastName,
                handedness: selectRight ? "R" : "L",
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }).then((res) => {
              if (res.ok) {
                res.json().then((data) => {
                  if (data.status) {
                    // Login newly registered user
                    fetch("/api/loginUser/", {
                      method: "POST",
                      body: JSON.stringify({
                        username: username,
                        password: password,
                      }),
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }).then((res) => {
                      if (res.ok) {
                        res.json().then((loginData) => {
                          clearForm();
                          props.toggleRegister();
                          dispatch(loginActions.login(loginData));
                          props.showFrontPage();
                        });
                      } else {
                        alert("Login after registration failed");
                        return;
                      }
                    });
                  }
                  else {
                    alert(data.message);
                    return;
                  }
                });
              } else {
                alert("Registration failed");
                return;
              }
            });
          } else {
            alert(
              "Cannot Register; Invalid Registration Code"
            );
            return;
          }
        });
      } else {
        alert("Registration Code Check Failed");
        return;
      }
    });
    setSubmitDisabled(false);
  };

  return (
    <div id="registerOverlay" className={props.showRegister ? "active" : ""}>
      <div id="exitProfile" onClick={props.toggleRegister} />

      <div className="container">
        <div className="clear20" />
        <h2 className="center-align">Register a New Account</h2>

        <form id="registerForm" onSubmit={submitHandler}>
          <div className="row">
            <div className="col s12 m5 l5">
              <RegisterInput
                name="firstName"
                label="First Name"
                onChange={setFirstName}
                type="text"
              />

              <RegisterInput
                name="lastName"
                label="Last Name"
                onChange={setLastName}
                type="text"
              />

              <div id="left-vs-right">
                <div
                  id="lefty"
                  className={!selectRight ? "active" : ""}
                  onClick={setLeft}
                />
                <div
                  id="righty"
                  className={selectRight ? "active" : ""}
                  onClick={setRight}
                />
                <div style={{ clear: "both" }} />
                <p
                  style={{
                    float: "left",
                    marginTop: "0px",
                    marginLeft: "15px",
                  }}
                >
                  LEFTY
                </p>
                <p
                  style={{
                    float: "right",
                    marginTop: "0px",
                    marginRight: "15px",
                  }}
                >
                  RIGHTY
                </p>
              </div>

              <div style={{ clear: "both" }} />

              <input
                type="radio"
                id="rightHandedRadio"
                name="handedness"
                value="R"
                style={{ display: "none" }}
              />
              <input
                type="radio"
                id="leftHandedRadio"
                name="handedness"
                style={{ display: "none" }}
                value="L"
              />

              <RegisterInput
                name="registrationCode"
                label="Registration Code"
                onChange={setRegistrationCode}
                type="text"
              />
            </div>

            <div className="col s12 m2 l2" />

            <div className="col s12 m5 l5">
              <RegisterInput
                name="username"
                label="Username"
                onChange={setUsername}
                type="text"
              />

              <RegisterInput
                name="password"
                label="Password"
                type="password"
                onChange={setPassword}
              />

              <RegisterInput
                name="password_confirm"
                label="Password (again)"
                type="password"
                onChange={setPasswordConfirm}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={submitDisabled}
            className="waves-light light-blue lighten-1 btn register-button white-text"
          >
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
