import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class ContourLesson extends Lesson {
  exp = 7;
  silhouette = new paper.Path();
  perfectShape = new paper.Path();

  exportLessonData = () => {
    return {
      name: "Contour",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      silhouette: this.silhouette,
      perfectShape: this.perfectShape,
    };
  };

  generateLesson() {
    //Generate center line
    let centerLine = new paper.Path({
      segments: [
        [this.canvasWidth * 0.5, 0],
        [this.canvasWidth * 0.5, this.canvasHeight],
      ],
      strokeColor: this.masterCircleColor,
      strokeCap: "round",
    });

    //Generate random silhouette
    let random = Math.floor(Math.random() * 1);
    if (random === 0) {
      //Montreal skyline
      this.silhouette = new paper.Path({
        segments: [
          [0, 941],
          [0, 346],
          [81, 422],
          [81, 551],
          [158, 551],
          [159, 516],
          [194, 516],
          [195, 360],
          [345, 361],
          [343, 510],
          [405, 510],
          [405, 503],
          [596, 502],
          [596, 350],
          [608, 340],
          [662, 340],
          [662, 350],
          [684, 350],
          [689, 590],
          [714, 590],
          [714, 558],
          [748, 558],
          [751, 439],
          [774, 439],
          [775, 431],
          [822, 431],
          [822, 438],
          [855, 438],
          [856, 454],
          [862, 454],
          [863, 600],
          [917, 600],
          [918, 440],
          [927, 440],
          [928, 396],
          [960, 396],
          [960, 941],
        ],
        fillColor: this.masterCircleColor,
        strokeWidth: this.sketchSize,
        opacity: 0.5,
      });
    }

    //Scale it
    let ratio = this.canvasWidth / 1920;
    for (let i = 1; i < this.silhouette.segments.length - 1; i++) {
      this.silhouette.segments[i].point.x *= ratio;
      this.silhouette.segments[i].point.y *= ratio;
    }
    this.silhouette.lastSegment.point.x = this.canvasWidth / 2;

    //Make perfectShape and hide it//
    this.perfectShape = this.silhouette.clone();
    this.perfectShape.firstSegment.remove();
    this.perfectShape.lastSegment.remove();
    for (let i = 0; i < this.perfectShape.segments.length; i++) {
      this.perfectShape.segments[i].point.x += this.canvasWidth / 2;
    }
    this.perfectShape.opacity = 0;

    return true;
  }

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }

    let combinedPath = this.combinePath(strokes);

    //Do the checks
    if (this.lengthTest(combinedPath)) {
      //console.log("it's a contour!");

      //this.clipEnds(path);
      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }
    return false;
  }

  // mirror(pathCopy) {
  //     let pathCenter = pathCopy.position;
  //     let xDistance = Math.abs((canvasWidth / 2) - pathCenter.x);
  //     let mirroredCenter = new paper.Point(xDistance*2, 0);
  //     pathCopy.scale(-1,1);
  //     if (pathCenter.x < (canvasWidth / 2)) {
  //     pathCopy.translate(mirroredCenter);
  //     }
  //     else {
  //     pathCopy.translate(-mirroredCenter);
  //     }
  //     this.perfectShape = pathCopy;
  //     this.perfectShape.opacity = 0;
  // }

  getPrecision(strokes) {
    let path = this.combinePath(strokes);
    let deviationAvg = 0;
    let deviationAvgSquare = 0;
    let localPerfectHalf = new paper.Path();
    let errorline = new paper.Path.Line(0, 0);

    //Let's factor in stroke direction
    // Calculating the inittial point of the perfect half
    let pointA = new paper.Point(
      this.perfectShape.segments[0].point.x,
      this.perfectShape.segments[0].point.y
    );
    let pointB = new paper.Point(
      this.perfectShape.segments[this.perfectShape.segments.length - 1].point.x,
      this.perfectShape.segments[this.perfectShape.segments.length - 1].point.y
    );
    // Check for the Reverse of the path
    let distOne = path.segments[0].point.getDistance(pointA);
    let distTwo = path.segments[0].point.getDistance(pointB);
    if (distTwo > distOne) {
      // Forward Direction Line
      localPerfectHalf = this.perfectShape;
    } else {
      // Reverse Direction
      for (let i = 0; i < this.perfectShape.segments.length; i++) {
        localPerfectHalf.add(
          this.perfectShape.segments[this.perfectShape.segments.length - 1 - i]
            .point
        );
      }
    }

    //Let's check deviation
    for (let i = 1; i < localPerfectHalf.length; i++) {
      let offset = (i / localPerfectHalf.length) * path.length;

      let deviation = Math.abs(
        localPerfectHalf.getPointAt(i).getDistance(path.getPointAt(offset))
      );
      deviationAvg += deviation;
      deviationAvgSquare += deviation * deviation;

      if (deviation > this.deviationThreshold) {
        errorline = new paper.Path.Line(
          localPerfectHalf.getPointAt(i),
          path.getPointAt(offset)
        );
        errorline.strokeColor = "rgba(255,0,0,0.2)";
      }
    }
    //Show perfect shape
    this.perfectShape.opacity = 1;
    this.perfectShape.strokeColor = "#ff0000";
    this.perfectShape.fillColor = "rgba(0,0,0,0)";

    //Average deviation
    deviationAvg /= this.perfectShape.length;
    deviationAvgSquare /= Math.pow(this.perfectShape.length, 2);

    return 100 - deviationAvg;
  }
}

export default ContourLesson;
