import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import "./filter.css";

const AllLessonsCompleted = (props) => {
  const lessonNames = useSelector((state) => state.login.lessonNames);
  const loginId = useSelector((state) => state.login.userId);
  const loginToken = useSelector((state) => state.login.token);
  //const lessonsCompleted = useSelector((state) => state.login.lessonsCompleted);

  const [selectedCode, setSelectedCode] = useState("---");
  const [selectedUser, setSelectedUser] = useState("---");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [registrationCodes, setRegistrationCodes] = useState([]);
  const [lessonsCompleted, setLessonsCompleted] = useState({});

  useEffect(() => {
    loadCodes();
    loadNames();

    return () => {
      //clearTimeout(refresh_canvas);
    };
  }, []);

  const loadNames = () => {
    fetch("/api/loadUserNames", {
      method: "POST",
      body: JSON.stringify({
        userId: loginId,
        token: loginToken,
      }),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          if (data) {
            setUsers(data);
          }
        });
      } else {
        alert("Load Names failed");
      }
    });
  };

  const loadCodes = () => {
    fetch("/api/loadRegistrationCodes", {
      method: "POST",
      body: JSON.stringify({
        userId: loginId,
        token: loginToken,
      }),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          if (data) {
            setRegistrationCodes(data);
          }
        });
      } else {
        alert("Load Codes failed");
      }
    });
  };

  const loadLessonsCompleted = (selected) => {
    fetch("/api/loadLessonsCompleted", {
      method: "POST",
      body: JSON.stringify({
        userId: selected,
      }),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setLessonsCompleted(data);
        });
      } else {
        alert("Load Lessons Completed failed");
      }
    });
  };

  let lessons = lessonNames.map((lessonName) => (
    <tr key={lessonName}>
      <td>{lessonName}</td>
      <td>{lessonsCompleted[lessonName]}</td>
    </tr>
  ));

  let codeOptions = registrationCodes.map((code) => (
    <option key={code.RegistrationCode} value={code.RegistrationCode}>
      {code.RegistrationCode}
    </option>
  ));

  let userOptions = filteredUsers.map((user) => (
    <option key={user.UserId} value={user.UserId}>
      {user.FirstName === null || user.LastName === null
        ? "UserId:" + user.UserId
        : user.FirstName + " " + user.LastName}
    </option>
  ));

  const codeFilterHandler = (event) => {
    let selected = event.target.value;
    let _code = false;
    for (let i = 0; i < registrationCodes.length; i++) {
      if (registrationCodes[i].RegistrationCode === selected) {
        _code = registrationCodes[i];
        break;
      }
    }
    if (!_code) {
      return;
    }
    setSelectedCode(_code.RegistrationCode);
    setFilteredUsers(
      users.filter((user) => user.RegistrationCode == _code.RegistrationCode)
    );
  };

  const userFilterHandler = (event) => {
    let selected = event.target.value;
    let _user = false;
    for (let i = 0; i < filteredUsers.length; i++) {
      if (filteredUsers[i].UserId == selected) {
        _user = filteredUsers[i];
        break;
      }
    }
    if (!_user) {
      return;
    }
    setSelectedUser(selected);
    loadLessonsCompleted(_user.UserId);
  };

  return (
    <Fragment>
      <div className="filterCard">
        <div className="filter">
          <div className="filter__control">
            <label>Select Registration Code</label>
            <select value={selectedCode} onChange={codeFilterHandler}>
              <option value="---">---</option>
              {codeOptions}
            </select>
          </div>
        </div>
      </div>

      <div className="filterCard">
        <div className="filter">
          <div className="filter__control">
            <label>Select Student</label>
            <select value={selectedUser} onChange={userFilterHandler}>
              <option value="---">---</option>
              {userOptions}
            </select>
          </div>
        </div>
      </div>

      <div className="clear100" />
      <div className="row">
        <div className="col s3" />
        <div className="col s9">
          <table style={{ width: "70%", backgroundColor:"rgb(0,0,0,0.2)" }}>
            <thead>
              <tr>
                <th>Lesson Name</th>
                <th># Completed</th>
              </tr>
            </thead>

            <tbody>{lessons}</tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default AllLessonsCompleted;
