import { Fragment, useRef, useContext } from "react";
//import LoginContext from "../../store/login-context";
import { useDispatch } from "react-redux";
import { loginActions } from "../../store/login-context";
import navLogo from "../../images/logo.svg";

const LoginPage = (props) => {
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();
  //const loginCtx = useContext(LoginContext);
  const dispatch = useDispatch();

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredUsername = usernameInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    if (enteredUsername === "") {
      return;
    }

    if (enteredPassword === "") {
      return;
    }

    // Get UserId
    fetch("/api/loginUser", {
      method: "POST",
      body: JSON.stringify({
        username: enteredUsername,
        password: enteredPassword,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((loginData) => {
          // console.log("Got login data");
          if (loginData) {
            dispatch(loginActions.login(loginData));
            props.showFrontPage();
          } else {
            passwordInputRef.current.value = "";
            alert("Invalid username or password");
          }
        });
      } else {
        alert("Login error");
      }
    });
  };

  return (
    <Fragment>
      <div className="container">
        <div className="loginLogo">
          <img src={navLogo} />
        </div>
      </div>

      <div className="loginBox">
        <form id="loginForm" onSubmit={submitHandler}>
          <fieldset style={{ border: 0 }}>
            <p>
              <label
                htmlFor="username"
                style={{ fontSize: "18px", color: "#fff" }}
              >
                Username or Email
              </label>
              <input
                type="text"
                autoCapitalize="none"
                style={{ fontSize: "14px", color: "#fff" }}
                name="username"
                id="username"
                ref={usernameInputRef}
              />
            </p>
            <p>
              <label
                htmlFor="password"
                style={{ fontSize: "18px", color: "#fff" }}
              >
                Password
              </label>
              <input
                type="password"
                style={{ fontSize: "14px", color: "#fff" }}
                name="password"
                id="password"
                ref={passwordInputRef}
              />
            </p>
          </fieldset>

          <button
            type="submit"
            className="waves-light light-blue lighten-1 btn login-button white-text"
          >
            Login
          </button>
          <div
            className="center-align reset-button"
            onClick={props.toggleRegister}
          >
            REGISTER
          </div>
          {/* <div
              className="center-align reset-button"
              // onclick toggle forgotpassword
            >
              FORGOT PASSWORD?
            </div> */}
        </form>
      </div>
    </Fragment>
  );
};

export default LoginPage;
