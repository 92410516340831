import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SymmetryLesson extends Lesson {
  exp = 5;
  p1 = new paper.Point(0, 0);
  p2 = new paper.Point(0, 0);
  p3 = new paper.Point(0, 0);
  p4 = new paper.Point(0, 0);
  p5 = new paper.Point(0, 0);
  p6 = new paper.Point(0, 0);
  p7 = new paper.Point(0, 0);
  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  perfectShape = new paper.Path();

  mirror = (pathCopy) => {
    let pathCenter = pathCopy.position;
    let xDistance = Math.abs(this.canvasWidth / 2 - pathCenter.x);
    let mirroredCenter = new paper.Point(xDistance * 2, 0);
    pathCopy.scale(-1, 1);
    if (pathCenter.x < this.canvasWidth / 2) {
      pathCopy.translate(mirroredCenter);
    } else {
      pathCopy.translate(-mirroredCenter);
    }
    this.perfectShape = pathCopy;
    this.perfectShape.opacity = 0;
  };

  exportLessonData = () => {
    return {
      name: "Symmetry",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      perfectShape: this.perfectShape,
    };
  };

  generateLesson() {
    //Generate lines
    let topLine = new paper.Path({
      segments: [
        [0, this.canvasHeight * 0.2],
        [this.canvasWidth, this.canvasHeight * 0.2],
      ],
      strokeColor: this.masterCircleColor,
    });
    let line2 = new paper.Path({
      segments: [
        [0, this.canvasHeight * 0.3],
        [this.canvasWidth, this.canvasHeight * 0.3],
      ],
      strokeColor: this.masterCircleColor,
    });
    let line3 = new paper.Path({
      segments: [
        [0, this.canvasHeight * 0.4],
        [this.canvasWidth, this.canvasHeight * 0.4],
      ],
      strokeColor: this.masterCircleColor,
    });
    let line4 = new paper.Path({
      segments: [
        [0, this.canvasHeight * 0.6],
        [this.canvasWidth, this.canvasHeight * 0.6],
      ],
      strokeColor: this.masterCircleColor,
    });
    let line5 = new paper.Path({
      segments: [
        [0, this.canvasHeight * 0.7],
        [this.canvasWidth, this.canvasHeight * 0.7],
      ],
      strokeColor: this.masterCircleColor,
    });
    let bottomLine = new paper.Path({
      segments: [
        [0, this.canvasHeight * 0.8],
        [this.canvasWidth, this.canvasHeight * 0.8],
      ],
      strokeColor: this.masterCircleColor,
    });
    let centerLine = new paper.Path({
      segments: [
        [this.canvasWidth * 0.5, 0],
        [this.canvasWidth * 0.5, this.canvasHeight],
      ],
      strokeColor: this.masterCircleColor,
      strokeCap: "round",
      dashArray: [20, 10],
    });

    //Generate points
    this.p1.x = this.canvasWidth * 0.3 + this.canvasWidth * 0.15 * Math.random();
    this.p1.y = topLine.segments[0].point.y;
    this.p1.fillColor = "red";

    this.p2.x = this.canvasWidth * 0.3 + this.canvasWidth * 0.15 * Math.random();
    this.p2.y = line2.segments[0].point.y;
    this.p2.fillColor = "orange";

    this.p3.x = this.canvasWidth * 0.3 + this.canvasWidth * 0.15 * Math.random();
    this.p3.y = line3.segments[0].point.y;
    this.p3.fillColor = "yellow";

    this.p4.x = this.canvasWidth * 0.3 + this.canvasWidth * 0.15 * Math.random();
    this.p4.y = line4.segments[0].point.y;
    this.p4.fillColor = "green";

    this.p5.x = this.canvasWidth * 0.3 + this.canvasWidth * 0.15 * Math.random();
    this.p5.y = line5.segments[0].point.y;
    this.p5.fillColor = "blue";

    this.p6.x = this.canvasWidth * 0.3 + this.canvasWidth * 0.15 * Math.random();
    this.p6.y = bottomLine.segments[0].point.y;
    this.p6.fillColor = "purple";

    this.circle1.position.x = this.canvasWidth * 0.5;
    this.circle1.position.y = topLine.segments[0].point.y;
    this.circle1.fillColor = this.masterCircleColor;

    this.circle2.position.x = this.canvasWidth * 0.5;
    this.circle2.position.y = bottomLine.segments[0].point.y;
    this.circle2.fillColor = this.masterCircleColor;

    //Finally, let's ensure we have a perfect Half
    let completedHalf = new paper.Path({
      segments: [
        [this.canvasWidth * 0.5, topLine.segments[0].point.y],
        [this.p1.x, this.p1.y],
        [this.p2.x, this.p2.y],
        [this.p3.x, this.p3.y],
        [this.p4.x, this.p4.y],
        [this.p5.x, this.p5.y],
        [this.p6.x, this.p6.y],
        [this.canvasWidth * 0.5, bottomLine.segments[0].point.y],
      ],
      strokeColor: this.sketchColor,
      strokeWidth: this.sketchSize,
      opacity: 0.5,
    });
    console.log("why", completedHalf);
    //Duplicate
    let pathCopy = completedHalf.clone();
    this.mirror(pathCopy);

    return true;
  }

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }

    let combinedPath = this.combinePath(strokes);

    //Do the checks
    if (
      combinedPath.intersects(this.circle1) &&
      combinedPath.intersects(this.circle2) &&
      this.lengthTest(combinedPath)
    ) {
      //console.log("it's a half!");

      //this.clipEnds(path);
      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }
    return false;
  }

  getPrecision(strokes) {
    let path = this.combinePath(strokes);
    let deviationAvg = 0;
    let deviationAvgSquare = 0;
    let localPerfectHalf = new paper.Path();
    let errorline = new paper.Path.Line(0, 0);

    //Let's factor in stroke direction
    // Calculating the inittial point of the perfect half
    let pointA = new paper.Point(
      this.perfectShape.segments[0].point.x,
      this.perfectShape.segments[0].point.y
    );
    let pointB = new paper.Point(
      this.perfectShape.segments[this.perfectShape.segments.length - 1].point.x,
      this.perfectShape.segments[this.perfectShape.segments.length - 1].point.y
    );
    // Check for the Reverse of the path
    let distOne = path.segments[0].point.getDistance(pointA);
    let distTwo = path.segments[0].point.getDistance(pointB);
    if (distTwo > distOne) {
      // Forward Direction Line
      localPerfectHalf = this.perfectShape;
    } else {
      // Reverse Direction
      for (let i = 0; i < this.perfectShape.segments.length; i++) {
        localPerfectHalf.add(
          this.perfectShape.segments[this.perfectShape.segments.length - 1 - i]
            .point
        );
      }
    }

    //Let's check deviation
    for (let i = 1; i < localPerfectHalf.length; i++) {
      let offset = (i / localPerfectHalf.length) * path.length;

      let deviation = Math.abs(
        localPerfectHalf.getPointAt(i).getDistance(path.getPointAt(offset))
      );
      deviationAvg += deviation;
      deviationAvgSquare += deviation * deviation;

      if (deviation > this.deviationThreshold) {
        errorline = new paper.Path.Line(
          localPerfectHalf.getPointAt(i),
          path.getPointAt(offset)
        );
        errorline.strokeColor = "rgba(255,0,0,0.2)";
      }
    }
    //Average deviation
    deviationAvg /= this.perfectShape.length;
    deviationAvgSquare /= Math.pow(this.perfectShape.length, 2);

    return 100 - deviationAvg;
  }
}

export default SymmetryLesson;