import React from "react";
import LessonStatus from "./LessonStatus";

const LessonCard = (props) => {
  return (
    // <div className="col s3 m3 l3" style={{ marginRight: "10%" }}>
    <div className="col s5">
      <div className="lesson" onClick={props.beginFunction}>
        <div
          className="lessonIcon"
          id={"lesson" + props.lessonName.split(" ").join("")}
          style={{ alignSelf: "center" }}
        />
        <div className="lessonTitle">{props.lessonTitle}</div>
        <LessonStatus
          // lessonTitle={props.lessonTitle}
          lessonName={props.lessonName}
        />
        {/* <div className="lessonStars" id={props.starsName}></div> */}
      </div>
    </div>
  );
};

export default LessonCard;
