import React, { useState, useEffect, Fragment, useRef } from "react";
import { useSelector } from "react-redux";
import "./filter.css";
import FilteredList from "./FilteredList";
import { decodeData } from "./decodeData";
import paper from "paper";
import getLessonFromType from "../utils/getLessonFromType";

let height_scale = 0.5;
let width_scale = 0.5;

const LoadSketch = (props) => {
  const lesson = useRef();
  const canvasRef = useRef();

  // --- CanvasCtx ---
  const canvasWidth = useRef(0);
  const canvasHeight = useRef(0);

  let canvasId = "sketchCanvas";

  const masterCircleColor = useSelector(
    (state) => state.canvas.masterCircleColor
  );
  const masterCircleSize = useSelector(
    (state) => state.canvas.masterCircleSize
  );
  const SFTCircleColor = useSelector((state) => state.canvas.SFTCircleColor);
  const SFTCircleSize = useSelector((state) => state.canvas.SFTCircleSize);

  // ######################################################

  // Main filters to select specific sketches
  const [selectedCode, setSelectedCode] = useState("---");
  const [selectedUser, setSelectedUser] = useState("---");
  const [selectedLesson, setSelectedLesson] = useState("---");
  const [selectedSketch, setSelectedSketch] = useState("---");

  // Determine Lesson object to create
  const [sketchCategory, setSketchCategory] = useState("");
  const [sketchType, setSketchType] = useState("");

  // Secondary filters to select strokes
  const [startIndex, setStartIndex] = useState("---");
  const [endIndex, setEndIndex] = useState("---");

  // Display the metrics stored in the database
  const [precisionMetric, setPrecisionMetric] = useState("---");
  const [smoothnessMetric, setSmoothnessMetric] = useState("---");
  const [speedMetric, setSpeedMetric] = useState("---");

  // Track the number of strokes
  const [numStrokes, setNumStrokes] = useState(0);
  const recalStrokes =
    startIndex === "---" || endIndex === "---"
      ? numStrokes
      : endIndex - startIndex;

  // Display the recalculated metrics from the sketch data
  const [precisionRecal, setPrecisionRecal] = useState(0);
  const [smoothnessRecal, setSmoothnessRecal] = useState(0);
  const [speedRecal, setSpeedRecal] = useState(0);

  // #######################################################

  const canvasParams = {
    name: "",
    variation: props.variation,
    canvasHeight: 0,
    canvasWidth: 0,
    masterCircleColor:
      sketchCategory === "SFT" ? SFTCircleColor : masterCircleColor,
    masterCircleSize:
      sketchCategory === "SFT" ? SFTCircleSize : masterCircleSize,
    vp1: new paper.Point(0, 0),
    vp2: new paper.Point(0, 0),
  };

  const resetMetrics = () => {
    setPrecisionMetric("---");
    setSmoothnessMetric("---");
    setSpeedMetric("---");
    setNumStrokes(0);
    setStartIndex("---");
    setEndIndex("---");
  };

  const codeHandler = (val) => {
    setSelectedCode(val);
    setSelectedUser("---");
    setSelectedLesson("---");
    setSelectedSketch("---");

    resetMetrics();
  };
  const userHandler = (val) => {
    setSelectedUser(val);
    setSelectedLesson("---");
    setSelectedSketch("---");

    resetMetrics();
  };
  const lessonHandler = (val) => {
    setSelectedLesson(val);
    setSelectedSketch("---");

    resetMetrics();
  };
  const sketchHandler = (val) => {
    setSelectedSketch(val);

    resetMetrics();
  };
  const startIndexHandler = (val) => {
    setStartIndex(val);
  };
  const endIndexHandler = (val) => {
    setEndIndex(val);
  };
  const sketchCategoryHandler = (val) => {
    setSketchCategory(val);
  };
  const sketchTypeHandler = (val) => {
    setSketchType(val);
  };

  // #######################################################

  useEffect(() => {
    // Associate Paper JS with Canvas
    paper.setup(canvasRef.current);

    // Make note of canvas size
    canvasHeight.current = paper.view.size.height; // * height_scale;
    canvasWidth.current = paper.view.size.width; // * width_scale;

    // Add perspective params
    canvasParams.canvasHeight = canvasHeight.current;
    canvasParams.canvasWidth = canvasWidth.current;
    // console.log("canvas", canvasWidth.current, canvasHeight.current);

    // Load selected sketch
    if (selectedSketch !== "---") {
      // Initialize lesson object
      canvasParams.name = sketchType;
      lesson.current = getLessonFromType(sketchType, canvasParams);
      lesson.current.setIndices(startIndex, endIndex);

      fetch("/api/loadSketchDataById", {
        method: "POST",
        body: JSON.stringify({
          sketchId: selectedSketch.SketchId,
        }),
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            if (data.length !== 0) {
              let temp = data[0];

              // Pass loaded data over to the lesson object
              lesson.current.importData(
                decodeData(temp.SketchData.data),
                decodeData(temp.LessonData.data),
                decodeData(temp.TimeValues.data),
                decodeData(temp.PressureValues.data)
              );
              // timeValues.slice(startIndex, endIndex),
              //   pressureValues.slice(startIndex, endIndex)

              // Draw sketch on canvas
              lesson.current.loadSketch();
              setTimeout(() => {
                lesson.current.setIndices(startIndex, endIndex);

                // setNumStrokes(lesson.current.numStrokes);
                // if (startIndex === "---") {
                //   setStartIndex(0);
                //   setEndIndex(lesson.current.numStrokes);
                // }

                // Recalculate metrics
                let { precision, smoothness, speed } =
                  lesson.current.recalculateMetrics();
                setPrecisionRecal(precision);
                setSmoothnessRecal(smoothness);
                setSpeedRecal(speed);

                // Add database metrics to table
                setPrecisionMetric(temp.PrecisionMetric);
                setSmoothnessMetric(temp.SmoothnessMetric);
                setSpeedMetric(temp.SpeedMetric);
              }, 100);

              paper.view.update();
            } else {
              console.log("Empty sketch");
            }
          });
        } else {
          alert("Load failed");
        }
      });
    }
  }, [selectedSketch, startIndex, endIndex]);

  return (
    <Fragment>
      <div className="row">
        <div className="col s3">
          <FilteredList
            id="RegistrationCode"
            title="Registration Code"
            name="Codes"
            route="loadRegistrationCodes"
            isIndependent={true}
            updateChoice={codeHandler}
          />
        </div>

        <div className="col s3">
          <FilteredList
            id="UserId"
            title="Name"
            name="Names"
            route="loadUserNames"
            isIndependent={false}
            updateChoice={userHandler}
            filterId="RegistrationCode"
            filterValue={selectedCode.RegistrationCode}
          />
        </div>

        <div className="col s3">
          <FilteredList
            id="LessonId"
            title="Lesson"
            name="Lessons"
            route="loadLessonIds"
            isIndependent={false}
            updateChoice={lessonHandler}
            filterId="FK_UserId"
            filterValue={selectedUser.UserId}
            updateSketchCategory={sketchCategoryHandler}
            sketchCategory={sketchCategory}
            updateSketchType={sketchTypeHandler}
            sketchType={sketchType}
          />
        </div>
        <div className="col s3">
          <FilteredList
            id="SketchId"
            title="Sketch"
            name="Sketches"
            route="loadSketchIds"
            isIndependent={false}
            updateChoice={sketchHandler}
            filterId="FK_LessonId"
            filterValue={selectedLesson.LessonId}
            updateSketchCategory={sketchCategoryHandler}
            sketchCategory={sketchCategory}
            updateSketchType={sketchTypeHandler}
            sketchType={sketchType}
          />
        </div>
      </div>

      <div className="clear100" />

      <canvas
        id={canvasId}
        style={{
          height: 100 * height_scale + "%",
          width: 100 * width_scale + "%",
          top: "25%",
          left: "10%",
        }}
        ref={canvasRef}
      />

      <table
        style={{
          position: "absolute",
          width: "25%",
          top: "25%",
          left: "65%",
          backgroundColor: "rgb(0,0,0,0.2)",
        }}
      >
        <thead>
          <tr>
            <th>Metric</th>
            <th>Value</th>
            {/* <th>Recal</th> */}
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Precision</td>
            <td>{precisionMetric}</td>
            {/* <td>{precisionRecal}</td> */}
          </tr>
          <tr>
            <td>Smoothness</td>
            <td>{smoothnessMetric}</td>
            {/* <td>{smoothnessRecal}</td> */}
          </tr>
          <tr>
            <td>Speed</td>
            <td>{speedMetric}</td>
            {/* <td>{speedRecal}</td> */}
          </tr>
          {/* <tr>
            <td># Strokes</td>
            <td>{numStrokes}</td>
            <td>{recalStrokes}</td>
          </tr> */}
        </tbody>
      </table>

      {/* <div
        style={{
          position: "absolute",
          left: "3%",
          bottom: "5%",
          maxWidth: "25%",
        }}
      >
        <FilteredList
          id="number"
          title="StartIndex"
          name="StartIndex"
          isIndependent={true}
          updateChoice={startIndexHandler}
          number={numStrokes}
        />
      </div>

      <div
        style={{
          position: "absolute",
          right: "3%",
          bottom: "5%",
          maxWidth: "25%",
        }}
      >
        <FilteredList
          id="number"
          title="EndIndex"
          name="EndIndex"
          isIndependent={true}
          updateChoice={endIndexHandler}
          number={numStrokes}
        />
      </div> */}
    </Fragment>
  );
};

export default LoadSketch;
