import { createSlice } from "@reduxjs/toolkit";

const sumObject = (obj) => {
  let sum = 0;
  for (let key in obj) {
    sum += obj[key];
  }
  return sum;
};

const lessonNames = [
  "Lines",
  "Squares",
  "Circles",
  "Arcs",
  "Planes",
  "Ellipses",
  "Cubes",
  "Cylinders",
  "Cones",
  "Spheres",
];

const initialState = {
  loggedIn: false,
  userId: "",
  registrationCode: "",
  token: "",

  lessonNames: lessonNames,
  lessonsCompleted: {},
  numCompleted: 0,

  assignmentList: [],

  activeLesson: "",
  showAllFlag: "",
  displayAll: true,

  enableSFT: false,
  partialSFT: false,
  SFTOnly: false,

  SFT_COUNTER: 8,
  timeForSFT: false,
  SFTMode: false,

  showFeedback: true,
  showChallenges: true,

  afterInitial: false,
};

const loginCtxSlice = createSlice({
  name: "loginCtx",
  initialState: initialState,
  reducers: {
    login(state, action) {
      const userInfo = action.payload.userInfo;
      const lessonsCompleted = action.payload.lessonsCompleted;
      const lessonTimestamps = action.payload.lessonTimestamps;
      const assignmentList = action.payload.assignmentList;
      const SFTInfo = action.payload.SFTInfo;
      const registrationSettings = action.payload.registrationSettings;
      const token = action.payload.token;

      state.loggedIn = true;
      state.userId = userInfo.userId;
      state.registrationCode = userInfo.registrationCode;
      state.token = token;

      state.lessonsCompleted = lessonNames.reduce((map, obj) => {
        map[obj] = 0;
        return map;
      }, {});
      for (let key in lessonsCompleted) {
        if (key !== "SFT") {
          state.lessonsCompleted[key] = lessonsCompleted[key];
        }
      }
      state.numCompleted = sumObject(state.lessonsCompleted);

      state.assignmentList = assignmentList;
      state.lessonTimestamps = lessonTimestamps;

      state.SFTInfo = SFTInfo;

      for (const val in registrationSettings) {
        state[val] = registrationSettings[val];
      }

      if (state.enableSFT) {
        let check = state.numCompleted % state.SFT_COUNTER === 0;
        state.timeForSFT = check;
        state.SFTMode = check && !SFTInfo.IsCompleted;
      }

      if (state.SFTOnly) {
        state.timeForSFT = true;
        state.SFTMode = true;
      }

      // console.log("SFTMode", state.SFTMode, "SFTInfo", SFTInfo.IsCompleted);
      // console.log(
      //   "debug",
      //   sumObject(state.lessonsCompleted),
      //   state.SFT_COUNTER,
      //   state.numCompleted % state.SFT_COUNTER === 0
      // );

      state.afterInitial = false;
    },

    logout(state, action) {
      state = { ...initialState };
    },

    setActiveLesson(state, action) {
      state.activeLesson = action.payload;
      state.displayAll = state.activeLesson === state.showAllFlag;

      if (state.afterInitial && state.enableSFT && state.timeForSFT) {
        state.SFTMode = true;
      }
    },

    updateCompleted(state, action) {
      state.lessonsCompleted[action.payload] += 1;
      state.lessonTimestamps.push({
        LessonName: action.payload,
        Timestamp: Date.now(),
      });
      state.numCompleted = sumObject(state.lessonsCompleted);

      if (state.enableSFT) {
        // Check if it's time for SFT
        state.timeForSFT = state.numCompleted % state.SFT_COUNTER === 0;
        // console.log(
        //   "debug",
        //   sumObject(state.lessonsCompleted),
        //   state.SFT_COUNTER,
        //   state.numCompleted % state.SFT_COUNTER === 0
        // );
      }

      // Prevent timeForSFT to immediately flip after loading status from database
      state.afterInitial = true;
    },

    completeSFT(state, action) {
      if (!state.SFTOnly) {
        state.timeForSFT = false;
        state.SFTMode = false;
        state.SFTInfo.SFTIndex = 9;
      }
    },
  },
});

export const loginActions = loginCtxSlice.actions;
export default loginCtxSlice.reducer;
