import React from "react";

// TEMP - Importing all ProTip images and switching based on props
import protip1 from "../../images/protips/protip1.png";
import protip2 from "../../images/protips/protip2.png";
import protip3 from "../../images/protips/protip3.png";
import arc1 from "../../images/protips/arc1.png";
import arc2 from "../../images/protips/arc2.png";
import square1 from "../../images/protips/square1.png";
import circle1 from "../../images/protips/circle1.png";
import circle2 from "../../images/protips/circle2.png";
import circle3 from "../../images/protips/circle3.png";
import contour1 from "../../images/protips/contour1.png";
import contour2 from "../../images/protips/contour2.png";
import symmetry1 from "../../images/protips/symmetry1.png";
import symmetry2 from "../../images/protips/symmetry2.png";

const ProTipsImages = {
  "protip1.png": protip1,
  "protip2.png": protip2,
  "protip3.png": protip3,
  "arc1.png": arc1,
  "arc2.png": arc2,
  "square1.png": square1,
  "circle1.png": circle1,
  "circle2.png": circle2,
  "circle3.png": circle3,
  "contour1.png": contour1,
  "contour2.png": contour2,
  "symmetry1.png": symmetry1,
  "symmetry2.png": symmetry2,
};

const ProTip = (props) => {
  return (
    <React.Fragment>
      <div className="col s4 m4 l4">
        <div id="leoDialogue">
          <p>
            <strong>
              TIP {props.index + 1} OF {props.length}
            </strong>
          </p>
          <p style={{ whiteSpace: "pre-line" }}>{props.text}</p>
        </div>
        <div id="prevProTip" onClick={props.prevTip} />
        <div id="nextProTip" onClick={props.nextTip} />
      </div>
      <div className="col s5 m5 l5">
        <div id="proTip">
          {props.img === "video" && (
            <div className="video-responsive">
              <iframe
                // width="560"
                // height="315"
                style={{
                  marginTop: "10%",
                  verticalAlign: "middle",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                src={`https://www.youtube.com/embed/${props.videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          )}
          {props.img !== "video" && (
            <img src={ProTipsImages[props.img]} alt="No Image" />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProTip;
