import ExercisePage from "./ExercisePage";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SFTEllipses extends ExercisePage {
  //Properties

  letter_array = ["A", "B", "C", "D", "E", "F"];
  circle_array = this.letter_array.map((ele) => [
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
  ]);

  exampleEllipse = {};
  perfectShape = [];

  squareHeight = Math.min(this.canvasWidth, this.canvasHeight) / 4;
  squareWidth = this.squareHeight;

  example_x1 = this.canvasWidth / 3;
  example_y1 = (5 * this.canvasHeight) / 16;

  initial_x1 = this.canvasWidth / 6;
  initial_y1 = this.canvasHeight / 2;

  horiz_spacing = this.canvasWidth * 0.5;
  vert_spacing = this.canvasHeight / 3;

  example_scale = 1.1; // make example slightly bigger

  ellipseHelper = (pts, scaffold, isHorizontal, lbl) => {
    const [p1, p2, p3, p4] = pts;

    // Draw label
    if (scaffold > 0) {
      const label_location = isHorizontal
        ? new paper.Point(
            p2.x - this.masterCircleSize * 2,
            p4.y - this.masterCircleSize * 2
          )
        : new paper.Point(
            p2.x - this.masterCircleSize * 6,
            p4.y + this.masterCircleSize * 3
          );
      let label2 = new paper.PointText(label_location);
      label2.content = lbl;
      label2.style = {
        fontSize: lbl.length === 1 ? this.masterCircleSize * 2 : 15,
        justification: "center",
      };
    }

    const plane_circles = [p1, p2, p3, p4].map(
      (pt) =>
        new paper.Path.Circle({
          center: [pt.x, pt.y],
          radius: this.masterCircleSize,
        })
    );

    const [
      center,
      circle1,
      circle2,
      circle3,
      circle4,
      ellipse_center,
      angle,
      major,
      minor,
    ] = isHorizontal
      ? this.defineEllipse(...plane_circles, scaffold)
      : this.defineVerticalEllipse(...plane_circles, false, scaffold);

    let ellipse = new paper.Path.Ellipse({
      center: [ellipse_center.x, ellipse_center.y],
      radius: [major, minor],
      //strokeColor: "red",
    });
    ellipse.rotate(angle, ellipse_center);

    return {
      ellipse: ellipse,
      circles: [circle1, circle2, circle3, circle4],
    };
  };

  plotExample = () => {
    const title = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20)
    );
    title.content = "ELLIPSES";
    title.style = {
      fontWeight: "bold",
      fontSize: 20,
      fillColor: "black",
      justification: "center",
    };

    const instructions = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20 + 20)
    );
    instructions.content =
      "Sketch ellipses that fit inside these planes in perspective. \n It may help to “ghost” the ellipses before applying pressure.";
    instructions.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };

    // Plot example ellipse

    const x1 = this.example_x1;
    const y1 = this.example_y1;
    const scaffold = 2;
    const isHorizontal = true;
    const lbl = "EXAMPLE";

    this.squareHeight *= this.example_scale; // for example

    this.vp1.x = x1 - 1.2 * this.horiz_spacing;
    this.vp2.x = x1 + this.horiz_spacing;
    this.horizonHelper(y1);

    const p1 = new paper.Point(x1, y1);
    // Calculate measure points
    const [mp1, mp2] = this.findMeasurePoints(p1.x);

    // Define plane: get center points for plane circles 2-4
    const [p2, p3, p4] = isHorizontal
      ? this.definePlane(p1.x, p1.y, mp1, mp2)
      : this.defineVerticalPlane(p1.x, p1.y, mp1, mp2, false);

    // Create ellipse
    this.exampleEllipse = this.ellipseHelper(
      [p1, p2, p3, p4],
      scaffold,
      isHorizontal,
      lbl
    );
    this.exampleEllipse.ellipse.strokeColor = "black";
    let midpoint_label = new paper.PointText(
      new paper.Point(
        this.exampleEllipse.circles[1].position.x + this.masterCircleSize * 2,
        this.exampleEllipse.circles[1].position.y + this.masterCircleSize
      )
    );
    midpoint_label.content = "MID-POINT \nOF EACH \nSIDE";
    midpoint_label.style = {
      fontSize: this.masterCircleSize * 2,
      fillColor: this.masterCircleColor,
    };

    this.squareHeight /= this.example_scale; // return to normal
  };

  horizonHelper = (y1) => {
    this.horizonHeight = y1 - this.vert_spacing;
    this.vp1.y = this.horizonHeight;
    this.vp2.y = this.horizonHeight;
  };

  generatePromptPoints = () => {
    const prompt_points = [];
    let x1 = this.initial_x1;
    let y1 = this.initial_y1;
    this.horizonHelper(y1);

    let p1, p2, p3, p4;
    let isHorizontal = true;
    const first_row = 0; // Ellipse A
    const second_row = 3; // Ellipse D
    let mp1, mp2;
    for (let i = 0; i < this.circle_array.length; i++) {
      if (i === second_row) {
        // At D, reset x, move horizon down
        x1 = this.canvasWidth / 10;
        y1 = (7 * this.canvasHeight) / 8;
        this.horizonHelper(y1);
      }

      // Move the vanishing points and determine orientation
      this.vp1.x = x1 - 1.2 * this.horiz_spacing;
      this.vp2.x = x1 + this.horiz_spacing;
      isHorizontal = i < second_row;

      p1 = new paper.Point(x1, y1);
      // Calculate measure points
      [mp1, mp2] = this.findMeasurePoints(p1.x);

      // Define plane: get center points for plane circles 2-4
      [p2, p3, p4] = isHorizontal
        ? this.definePlane(p1.x, p1.y, mp1, mp2)
        : this.defineVerticalPlane(p1.x, p1.y, mp1, mp2, false);

      prompt_points.push([p1, p2, p3, p4]);

      // Move x along
      x1 += this.canvasWidth / 3;

      if (i === first_row || i === second_row) {
        // Move y down a little after A and D
        y1 -= this.canvasHeight / 20;
        this.horizonHelper(y1);
      }
    }
    return prompt_points;
  };

  generateEllipseObjects = (useScaffold = true) => {
    const prompt_points = this.generatePromptPoints();
    const ellipse_objects = [];

    const first_row = 0; // Ellipse A
    const second_row = 3; // Ellipse D
    let isHorizontal = true;
    let scaffold = useScaffold ? 2 : -1;
    let x1, y1;

    for (let i = 0; i < prompt_points.length; i++) {
      // Grab frontmost point of plane from prompt_points to update perspective
      x1 = prompt_points[i][0].x;
      y1 = prompt_points[i][0].y;
      this.horizonHelper(y1);

      // Move the vanishing points and determine orientation
      this.vp1.x = x1 - 1.2 * this.horiz_spacing;
      this.vp2.x = x1 + this.horiz_spacing;
      isHorizontal = i < second_row;

      if (i === second_row && useScaffold) {
        // At D, reset scaffold
        scaffold = 2;
      }

      // Generate ellipse
      ellipse_objects.push(
        this.ellipseHelper(
          prompt_points[i],
          scaffold,
          isHorizontal,
          this.letter_array[i]
        )
      );

      // Adjust scaffolding
      scaffold -= 1;
    }

    return ellipse_objects;
  };

  generateLesson() {
    // Generate prompts based on screen size

    this.plotExample();
    const ellipse_objects = this.generateEllipseObjects();
    this.perfectShape = ellipse_objects.map((ele) => ele.ellipse);
    this.circle_array = ellipse_objects.map((ele) => ele.circles);
  }

  precisionHelper = (strokes, prompt) => {
    let path = this.combinePath(strokes);
    if (path.length === 0) {
      return 0;
    }
    let totalDeviation = this.ellipseError(path, prompt, this.showFeedback);
    this.deviation_line = this.ellipseDeviation(path, prompt);
    let avgDeviation = totalDeviation / (prompt.length / 2);
    return 100 - avgDeviation;
  };

  getSmoothness = (strokes) => {
    return this.getCurvedSmoothness(strokes);
  }

  getSmoothnessArray = (strokes) => {
    return this.getCurvedSmoothnessArray(strokes);
  }

  loadPrompt = () => {
    const ellipse_objects = this.generateEllipseObjects(false);

    this.perfectShape = ellipse_objects.map((ele) => ele.ellipse);
    this.circle_array = ellipse_objects.map((ele) => ele.circles);

    for(let i = 0; i < this.circle_array.length; i++) {
      for (let j = 0; j < this.circle_array[i].length; j++) {
        this.circle_array[i][j].fillColor = this.cols[i];//this.masterCircleColor;
      }
    }
  };

  exportLessonData = () => {
    return {
      name: "SFTEllipses",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };
}

export default SFTEllipses;
