import React, { Fragment } from "react";
import { useSelector } from "react-redux";

const AssignmentList = (props) => {
  const lessonNames = useSelector((state) => state.login.lessonNames);
  //const lessonsCompleted = useSelector((state) => state.login.lessonsCompleted);

  let filteredLessons = lessonNames.filter(
    (lessonName) => lessonName in props.info && props.info[lessonName] !== 0
  );

  let dueDate = new Date(props.info["DueDate"])

  let content = filteredLessons.map((lessonName) => (
    <tr key={lessonName}>
      <td>{lessonName}</td>
      <td>
        {props.status[lessonName]}/{props.info[lessonName]}
      </td>
      <td>{dueDate.toDateString()}</td>
      <td>
        {props.status[lessonName] >= props.info[lessonName]
          ? "Complete"
          : "Incomplete"}
      </td>
    </tr>
  ));

  let assignmentLabel = "Assignment " + props.info.Number;

  return (
    <Fragment>
      <tr key={assignmentLabel}>
        <td>{assignmentLabel}</td>
      </tr>
      {content}
    </Fragment>
  );
};

export default AssignmentList;
