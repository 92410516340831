import React, { useState, useReducer, useRef, Fragment } from "react";
import LessonCard from "./LessonCard";
import ProTipOverlay from "../ProTips/ProTipOverlay";
import SketchCanvas from "../SketchCanvas/SketchCanvas";
import ResultsOverlay from "../Results/ResultsOverlay";
import { useDispatch, useSelector } from "react-redux";
import { loginActions } from "../../store/login-context";
import ExerciseReview from "./ExerciseReview";

const playAudio = (type) => {
  console.log("LessonManager playAudio", type);
};

// May replace with database call later
const initialMetrics = {
  precisionPerformance: [],
  smoothnessPerformance: [],
  speedPerformance: [],
  pressurePerformance: [],
  totalEXP: 0,
  bonusPrecision: 0,
  bonusSmoothness: 0,
  bonusSpeed: 0,
  avgPrecision: 0,
  avgSmoothness: 0,
  avgSpeed: 0,
  avgPressure: 0,
  starRating: 2,
};

const reducer = (state, action) => {
  if (action.type === "update") {
    // Payload is the values passed up from the Lesson
    return {
      ...state,
      precisionPerformance: [
        ...state.precisionPerformance,
        action.payload.precision,
      ],
      smoothnessPerformance: [
        ...state.smoothnessPerformance,
        action.payload.smoothness,
      ],
      speedPerformance: [...state.speedPerformance, action.payload.speed],
      pressurePerformance: [
        ...state.pressurePerformance,
        action.payload.avgPressure,
      ],
      totalEXP: state.totalEXP + action.payload.exp,
      bonusPrecision: state.bonusPrecision + action.payload.bonusPrecision,
      bonusSmoothness: state.bonusSmoothness + action.payload.bonusSmoothness,
      bonusSpeed: state.bonusSpeed + action.payload.bonusSpeed,
    };
  } else if (action.type === "average") {
    // Payload is the props.lessonList.length
    const sum_reducer = (prev, curr) => prev + curr;
    //console.log("Reducer debug:", state);
    return {
      ...state,
      avgPrecision:
        state.precisionPerformance.reduce(sum_reducer) / action.payload,
      avgSmoothness:
        state.smoothnessPerformance.reduce(sum_reducer) / action.payload,
      avgSpeed: state.speedPerformance.reduce(sum_reducer) / action.payload,
      avgPressure:
        state.pressurePerformance.reduce(sum_reducer) / action.payload,
    };
  } else if (action.type === "star_rating") {
    // Update star rating
    let stars = 2;
    if (state.avgPrecision >= 90) {
      stars = 5;
    } else if (state.avgPrecision >= 80) {
      stars = 4;
    } else if (state.avgPrecision >= 70) {
      stars = 3;
    }
    return {
      ...state,
      starRating: stars,
    };
  } else if (action.type === "reset") {
    return {
      ...initialMetrics,
    };
  }
  console.log("Invalid type given to metricReducer");
  return state;
};

const LessonManager = (props) => {
  //console.log("Inside LessonManager");

  //const loginCtx = useContext(LoginContext);
  const userId = useSelector((state) => state.login.userId);
  const lessonId = useRef(-1);
  //const SFTIndex = useSelector((state) => state.login.SFTInfo.LastIndex);
  // const SFTCompleted = useSelector((state) => state.login.SFTInfo.IsCompleted);
  const SFTOnly = useSelector((state) => state.login.SFTOnly);
  const timeForSFT = useSelector((state) => state.login.timeForSFT);

  const surveyCode = useSelector((state) => state.login.surveyCode);

  const showFeedback = useSelector((state) => state.login.showFeedback);
  const reduxDispatch = useDispatch();

  // const [index, setIndex] = useState(
  //   props.category === "SFT"
  //     ? SFTIndex === 9 || SFTIndex === 0
  //       ? 0
  //       : SFTIndex + 1
  //     : 0
  // );
  const [index, setIndex] = useState(0);
  // const [displayType, setDisplayType] = useState(
  //   SFTOnly && SFTCompleted ? 4 : SFTIndex > 0 ? 1 : 0
  // );
  // const [displayType, setDisplayType] = useState(
  //   SFTOnly && SFTCompleted ? 4 : 0
  // );
  const [displayType, setDisplayType] = useState(0);
  const [canvasDiagonal, setCanvasDiagonal] = useState(0);
  const [state, metricDispatch] = useReducer(reducer, initialMetrics);

  const activeLesson = useSelector((state) => state.login.activeLesson);
  const showAllFlag = useSelector((state) => state.login.showAllFlag);

  const sketchData = useRef([]);
  const lessonData = useRef({});
  const timeValues = useRef([]);
  const pressureValues = useRef([]);
  const tiltValues = useRef([]);
  const metrics = useRef({});

  // ##############################################

  const saveSketchData = () => {
    let sketchObject = {
      userId: userId,
      lessonId: lessonId.current,
      sketchData: JSON.stringify(sketchData.current),
      lessonData: JSON.stringify(lessonData.current),
      timeValues: JSON.stringify(timeValues.current),
      pressureValues: JSON.stringify(pressureValues.current),
      tiltValues: JSON.stringify(tiltValues.current),
      precision: metrics.current.precision,
      smoothness: metrics.current.smoothness,
      speed: metrics.current.speed,
      bonusPrecision: metrics.current.bonusPrecision,
      bonusSmoothness: metrics.current.bonusSmoothness,
      bonusSpeed: metrics.current.bonusSpeed,
      timestamp: new Date().toISOString().slice(0, 19).replace("T", " "),
    };

    // Save sketch data
    fetch("/api/saveSketchData/", {
      method: "POST",
      body: JSON.stringify(sketchObject),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.ok) {
        console.log("SketchData Saved");
      }
    });

    // Update lesson metrics
    let lessonObject = {
      lessonId: lessonId.current,
      precision: metrics.current.precision,
      smoothness: metrics.current.smoothness,
      speed: metrics.current.speed,
      bonusPrecision: metrics.current.bonusPrecision,
      bonusSmoothness: metrics.current.bonusSmoothness,
      bonusSpeed: metrics.current.bonusSpeed,
    };
    fetch("/api/updateLesson/", {
      method: "POST",
      body: JSON.stringify(lessonObject),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      if (res.ok) {
        //console.log("Lesson Updated");
      }
    });
  };

  const startLesson = (userId, lessonName) => {
    let lessonObject = {
      userId: userId,
      lessonName: lessonName,
      timestamp: new Date().toISOString().slice(0, 19).replace("T", " "),
    };

    fetch("/api/startLesson/", {
      method: "POST",
      body: JSON.stringify(lessonObject),
      headers: { "Content-Type": "application/json" },
    }).then((res) => {
      // After successful POST, GET is called to get lessonId
      if (res.ok) {
        //console.log("Lesson Started");
        res.json().then((data) => {
          lessonId.current = data.LessonId;
        });
      }
    });
  };

  // ##############################################

  const showLessonsPage = () => {
    // Handler: switch to Lessons Page (0)
    setDisplayType(0);
    props.onSelect("");
    playAudio("zap");
  };

  const showProTips = () => {
    // Handler: switch to ProTips (1)
    setDisplayType(1);
    props.onSelect(props.name);
    playAudio("zap");
  };

  const showLesson = () => {
    // Handler: switch to Canvas (2)
    setDisplayType(2);
    setIndex(0);
    metricDispatch({ type: "reset" });
    startLesson(userId, props.name);
  };

  const showResults = () => {
    // Handler: switch to Results (3)
    if (!showFeedback) {
      setDisplayType(4); // go to "All Done!" page
    } else {
      setDisplayType(3); // show Results
    }
  };

  // ##############################################

  const startSFT = () => {
    // Handler: switch to Canvas (1)
    setDisplayType(1);
    startLesson(userId, props.name);
    playAudio("zap");

    // Update in database that SFT isn't completed
    fetch("/api/updateSFTstatus/", {
      method: "POST",
      body: JSON.stringify({ userId, bool: false }),
      headers: { "Content-Type": "application/json" },
    });
  };

  const endSFT = () => {
    // Handler: switch to final page (2)
    setDisplayType(4);
    //props.endSFT();
    playAudio("zap");

    // Update in database that SFT isn't completed
    fetch("/api/updateSFTstatus/", {
      method: "POST",
      body: JSON.stringify({ userId, bool: true }),
      headers: { "Content-Type": "application/json" },
    });
  };

  const submitSFT = () => {
    // Update the routing
    //setDisplayType(0);
    //props.completedSFTHandler();
    reduxDispatch(loginActions.completeSFT());
  };

  // ##############################################

  const sketchDataHandler = (sketch) => {
    sketchData.current = sketch;
  };

  const lessonDataHandler = (data) => {
    lessonData.current = data;
  };

  const timeValuesHandler = (values) => {
    timeValues.current = values;
  };

  const pressureValuesHandler = (values) => {
    pressureValues.current = values;
  };

  const tiltValuesHandler = (values) => {
    tiltValues.current = values;
  };

  const metricsHandler = (values) => {
    metrics.current = values;
    metricDispatch({ type: "update", payload: values });
  };

  const endExercise = () => {
    //console.log("END EXERCISE");
    metricDispatch({ type: "average", payload: props.lessonList.length });
    metricDispatch({ type: "star_rating" });
    if (props.category !== "SFT") {
      reduxDispatch(loginActions.updateCompleted(props.name));
      showResults();
    } else {
      endSFT();
    }
  };

  const nextLessonHandler = () => {
    saveSketchData();

    // if (props.category === "SFT") {
    //   if (index === props.lessonList.length - 2) {
    //     setDisplayType(2); // Switch to Break page
    //   }
    // }

    if (index === props.lessonList.length - 1) {
      //No more lessons left
      playAudio("perfect");
      endExercise();
    } else {
      setIndex((prev) => prev + 1);
    }
  };

  let content;
  if (props.category === "SFT") {
    if (displayType === 0) {
      content = (
        <div
          style={{
            textAlign: "center",
            height: "100px",
            lineHeight: "100px",
          }}
        >
          <span
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "normal",
              borderRadius: "12px",
              boxShadow: "0 1px 8px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#009a9a",
              margin: "2%",
              padding: "20px",
              width: "80%",
            }}
          >
            <h2>Time for a Sketching Checkpoint!</h2>
          </span>

          <div style={{ width: "100%" }} />

          <span
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "normal",
              borderRadius: "12px",
              boxShadow: "0 1px 8px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#009a9a",
              margin: "12px",
              padding: "20px",
              width: "50%",
            }}
          >
            <h3>
            These checkpoints will show up periodically to track your progress. 
            Use these for extra practice to continue to hone your sketching skills!
            </h3>
          </span>
          <a
            className="waves-effect waves-light btn"
            onClick={startSFT}
            style={{
              position: "absolute",
              top: "90%",
              left: "80%",
              marginLeft: "5%",
            }}
          >
            START
          </a>
        </div>
      );
    } else if (displayType === 1) {
      content = (
        <SketchCanvas
          name={props.lessonList[index]}
          category="SFT"
          variation="SFT"
          index={index}
          nextLesson={nextLessonHandler}
          sketchDataHandler={sketchDataHandler}
          lessonDataHandler={lessonDataHandler}
          timeValuesHandler={timeValuesHandler}
          pressureValuesHandler={pressureValuesHandler}
          tiltValuesHandler={tiltValuesHandler}
          metricsHandler={metricsHandler}
        />
      );
    } else if (displayType === 2) {
      content = (
        <div
          style={{
            textAlign: "center",
            height: "100px",
            lineHeight: "100px",
          }}
        >
          <span
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "normal",
              borderRadius: "12px",
              boxShadow: "0 1px 8px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#009a9a",
              margin: "12px",
              padding: "20px",
            }}
          >
            <h3>BREAK 1</h3>
            <p>Continue to the last exercise when ready by pressing "Next"</p>
            <a
              className="waves-effect waves-light btn"
              onClick={() => {
                setDisplayType(3);
              }}
              style={{
                position: "absolute",
                top: "90%",
                left: "80%",
                marginLeft: "5%",
              }}
            >
              NEXT
            </a>
          </span>
        </div>
      );
    } else if (displayType === 3) {
      content = (
        <SketchCanvas
          name={props.lessonList[index]}
          category="SFT"
          variation="SFT"
          index={index}
          nextLesson={nextLessonHandler}
          sketchDataHandler={sketchDataHandler}
          lessonDataHandler={lessonDataHandler}
          timeValuesHandler={timeValuesHandler}
          pressureValuesHandler={pressureValuesHandler}
          tiltValuesHandler={tiltValuesHandler}
          metricsHandler={metricsHandler}
        />
      );
    } else if (displayType === 4) {
      content = (
        <div
          style={{
            textAlign: "center",
            height: "100px",
            lineHeight: "100px",
            position: "absolute",
            top: "45vh",
            left: "45vw",
          }}
        >
          <span
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "normal",
              borderRadius: "12px",
              boxShadow: "0 1px 8px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#009a9a",
              margin: "12px",
              padding: "20px",
            }}
          >
            <h1>All done!</h1>
            {!SFTOnly && (
              <a
                className="waves-effect waves-light btn"
                onClick={submitSFT}
                // style={{
                //   position: "absolute",
                //   top: "90%",
                //   left: "80%",
                //   marginLeft: "5%",
                // }}
              >
                Return
              </a>
            )}
            {SFTOnly && (
              <a
                className="waves-effect waves-light btn"
                href={surveyCode}
                // style={{
                //   position: "absolute",
                //   top: "90%",
                //   left: "80%",
                //   marginLeft: "5%",
                // }}
              >
                Survey
              </a>
            )}
          </span>
        </div>
      );
    }
  } else {
    if (activeLesson === showAllFlag) {
      content = (
        <LessonCard
          beginFunction={showProTips}
          lessonName={props.name}
          lessonTitle={props.label}
          starsName={"stars_" + props.name}
        />
      );
    } else if (displayType === 1) {
      content = (
        <ProTipOverlay
          onBack={showLessonsPage}
          onStart={showLesson}
          name={props.name}
          proTips={props.proTips}
          proTipsImages={props.proTipsImages}
          videoId={props.videoId}
        />
      );
    } else if (displayType === 2) {
      content = (
        <React.Fragment>
          <SketchCanvas
            name={props.name}
            category={props.category}
            variation={props.lessonList[index].variation}
            index={index}
            numLessons={props.lessonList.length}
            nextLesson={nextLessonHandler}
            sketchDataHandler={sketchDataHandler}
            lessonDataHandler={lessonDataHandler}
            timeValuesHandler={timeValuesHandler}
            pressureValuesHandler={pressureValuesHandler}
            tiltValuesHandler={tiltValuesHandler}
            metricsHandler={metricsHandler}
          />
        </React.Fragment>
      );
    } else if (displayType === 3) {
      content = (
        <ResultsOverlay
          starRating={state.starRating}
          avgPrecision={state.avgPrecision}
          avgSmoothness={state.avgSmoothness}
          avgSpeed={state.avgSpeed}
          totalEXP={state.totalEXP}
          bonusPrecision={state.bonusPrecision}
          bonusSmoothness={state.bonusSmoothness}
          bonusSpeed={state.bonusSpeed}
          diagonal={canvasDiagonal}
          onStart={showLessonsPage}
          // onStart={showLesson}
          disableStart={timeForSFT}
        />
      );
    } else if (displayType === 4) {
      // For no-feedback condition instead of Results Page
      content = (
        <div
          style={{
            textAlign: "center",
            height: "100px",
            lineHeight: "100px",
            position: "absolute",
            top: "45vh",
            left: "45vw",
          }}
        >
          <span
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              lineHeight: "normal",
              borderRadius: "12px",
              boxShadow: "0 1px 8px rgba(0, 0, 0, 0.25)",
              backgroundColor: "#009a9a",
              margin: "12px",
              padding: "20px",
            }}
          >
            <h1>All done!</h1>
            <a
              className="waves-effect waves-light btn"
              onClick={showLessonsPage}
              // style={{
              //   position: "absolute",
              //   top: "90%",
              //   left: "80%",
              //   marginLeft: "5%",
              // }}
            >
              Return
            </a>
          </span>
        </div>
      );
    }
  }

  return <React.Fragment>{content}</React.Fragment>;
};

export default LessonManager;
