import ExercisePage from "./ExercisePage";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SFTHorizontalLines extends ExercisePage {
  //Properties

  perfectShape = [];

  letter_array = ["A", "B", "C", "D", "E", "F", "G"];
  circle_array = this.letter_array.map((ele) => [
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
  ]);

  initial_x1 = this.canvasWidth / 2 - this.canvasWidth / 8;
  initial_x2 = this.canvasWidth / 2 + this.canvasWidth / 8;
  initial_y = ((this.canvasHeight / 4) * 2) / 3;

  plotExample = () => {
    // Plot title, instructions, and example

    const title = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20)
    );
    title.content = "STRAIGHT LINES";
    title.style = {
      fontWeight: "bold",
      fontSize: 20,
      fillColor: "black",
      justification: "center",
    };

    const instructions = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20 + 20)
    );
    instructions.content =
      "Connect the lettered dots with horizontal lines. \n Sketch quick confident, continuous lines that do not waver.";
    instructions.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };

    const example1 = new paper.Path.Circle({
      center: [this.initial_x1, this.initial_y],
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    });
    const example2 = new paper.Path.Circle({
      center: [this.initial_x2, this.initial_y],
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    });

    let label1 = new paper.PointText(
      new paper.Point(
        this.initial_x1 - this.masterCircleSize * 8,
        this.initial_y + this.masterCircleSize * 0.75
      )
    );
    label1.content = "EXAMPLE";
    label1.style = {
      fontSize: this.masterCircleSize * 2,
      justification: "center",
    };

    let label2 = new paper.PointText(
      new paper.Point(
        this.initial_x2 + this.masterCircleSize * 8,
        this.initial_y + this.masterCircleSize * 0.75
      )
    );
    label2.content = "EXAMPLE";
    label2.style = {
      fontSize: this.masterCircleSize * 2,
      justification: "center",
    };

    const exampleLine = new paper.Path.Line(
      new paper.Point(
        this.initial_x1 - this.masterCircleSize * 2,
        this.initial_y
      ),
      new paper.Point(
        this.initial_x2 + this.masterCircleSize * 2,
        this.initial_y
      )
    );
    exampleLine.strokeColor = "black";
  };

  generatePromptPoints = () => {
    const prompt_points = [];

    // Define the buffers of space for each line
    const vert_space = (this.canvasHeight * 3) / 4 / 7;
    const horiz_space = this.canvasWidth / 4 / 7;

    // Define starting positions for Line A
    let x1 = this.initial_x1;
    let _y = this.canvasHeight / 4;
    let x2 = this.initial_x2;

    for (let i = 0; i < this.circle_array.length; i++) {
      prompt_points.push([
        new paper.Point({ x: x1, y: _y }),
        new paper.Point({ x: x2, y: _y }),
      ]);

      x1 -= horiz_space;
      x2 += horiz_space;
      _y += vert_space;
    }

    return prompt_points;
  };

  generateLesson() {
    // Generate lines based on screen size

    this.plotExample();

    const prompt_points = this.generatePromptPoints();
    let label1, label2;
    for (let i = 0; i < prompt_points.length; i++) {
      this.circle_array[i][0].position = prompt_points[i][0];
      this.circle_array[i][0].fillColor = this.masterCircleColor;
      this.circle_array[i][1].position = prompt_points[i][1];
      this.circle_array[i][1].fillColor = this.masterCircleColor;

      label1 = new paper.PointText(
        new paper.Point(
          prompt_points[i][0].x - this.masterCircleSize * 4,
          prompt_points[i][0].y + this.masterCircleSize * 0.75
        )
      );
      label1.content = this.letter_array[i];
      label1.style = {
        fontSize: this.masterCircleSize * 2,
        justification: "center",
      };

      label2 = new paper.PointText(
        new paper.Point(
          prompt_points[i][1].x + this.masterCircleSize * 4,
          prompt_points[i][1].y + this.masterCircleSize * 0.75
        )
      );
      label2.content = this.letter_array[i];
      label2.style = {
        fontSize: this.masterCircleSize * 2,
        justification: "center",
      };
    }

    this.perfectShape = prompt_points.map(
      (pair) => new paper.Path.Line({ from: pair[0], to: pair[1] })
    );
  }

  processSketch = (strokes) => {
    // Sort input strokes by avg_y value

    const prompt_y = this.perfectShape.map((ele) => ele.firstSegment.point.y);
    this.choppedIndices = this.perfectShape.map((ele) => []);

    let min = Number.MAX_SAFE_INTEGER;
    let min_index = 0;

    let path, metric, diff;
    for (let i = 0; i < strokes.length; i++) {
      path = strokes[i];

      for (let j = 0; j < prompt_y.length; j++) {
        metric =
          path.segments
            .map((val) => val.point.y)
            .reduce((a, b) => {
              return a + b;
            }) / path.segments.length;
        diff = Math.abs(prompt_y[j] - metric);
        if (diff < min) {
          min = diff;
          min_index = j;
        }
      }

      this.choppedIndices[min_index].push(i);

      min = Number.MAX_SAFE_INTEGER;
      min_index = 0;
    }
  };

  precisionHelper = (strokes, prompt) => {
    let deviation = 0;
    let path = this.combinePath(strokes);
    if (path.segments.length !== 0) {
      deviation = this.checkLine(
        path,
        prompt.segments[0].point,
        prompt.segments[1].point,
        true
      );

      return 100 - deviation / prompt.length;
    }
    return 0;
  };

  exportLessonData = () => {
    return {
      name: "SFTHorizontalLines",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };

  loadPrompt = () => {
    const prompt_points = this.generatePromptPoints();

    for (let i = 0; i < this.circle_array.length; i++) {
      for (let j = 0; j < this.circle_array[i].length; j++) {
        this.circle_array[i][j].position = prompt_points[i][j];
        this.circle_array[i][j].fillColor = this.cols[i]; //this.masterCircleColor;
      }
    }

    this.perfectShape = this.circle_array.map(
      (pair) => new paper.Path.Line(pair[0].position, pair[1].position)
    );
  };
}

export default SFTHorizontalLines;
