import React from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import AssignmentList from "./AssignmentList";

const AssignmentsCompleted = (props) => {
  const assignmentList = useSelector((state) => state.login.assignmentList);
  const lessonNames = useSelector((state) => state.login.lessonNames);
  //const lessonsCompleted = useSelector((state) => state.login.lessonsCompleted);
  const lessonTimestamps = useSelector((state) => state.login.lessonTimestamps);

  let content = [];
  let offset = new Date().getTimezoneOffset();

  if (assignmentList.length !== 0) {
    // const remainingCompleted = {};
    // for (const lessonName of lessonNames) {
    //   remainingCompleted[lessonName] = lessonsCompleted[lessonName];
    // }
    const lessonObj = lessonTimestamps.map((lesson) => {
      const obj = {};
      obj["lessonName"] = lesson.LessonName;
      obj["timestamp"] = new Date(lesson.Timestamp);
      obj["timestamp"].setMinutes(obj["timestamp"].getMinutes() - offset);
      return obj;
    });
    const remainingCompleted = {};
    for (const lessonName of lessonNames) {
      remainingCompleted[lessonName] = lessonObj.filter(
        (obj) => obj.lessonName === lessonName
      );
    }

    let today = new Date(3600000 * Math.floor(Date.now() / 3600000));
    for (let i = 0; i < assignmentList.length; i++) {
      let startDate = new Date(assignmentList[i]["StartDate"]);
      let endDate = new Date(assignmentList[i]["DueDate"]);

      if (startDate.getYear() == 0 || startDate > today) {
        continue;
      }

      // Determine assigned amount and completed (for that assignment)
      const assignmentStatus = {};
      for (const lessonName of lessonNames) {
        if (
          lessonName in assignmentList[i] &&
          assignmentList[i][lessonName] !== 0
        ) {
          assignmentStatus[lessonName] = 0;
          for (let j = 0; j < remainingCompleted[lessonName].length; j++) {
            if (
              (assignmentStatus[lessonName] < assignmentList[i][lessonName] &&
                remainingCompleted[lessonName][j].timestamp > startDate) ||
              (assignmentStatus[lessonName] >= assignmentList[i][lessonName] &&
                remainingCompleted[lessonName][j].timestamp <= endDate)
            ) {
              assignmentStatus[lessonName] += 1;
              remainingCompleted[lessonName].splice(j, 1);
              j -= 1;
            }
          }

          // Check if complete from running totals
          // if (remainingCompleted[lessonName] >= assignmentList[i][lessonName]) {
          //   assignmentStatus[lessonName] = assignmentList[i][lessonName];
          //   remainingCompleted[lessonName] -= assignmentList[i][lessonName];
          // } else {
          //   assignmentStatus[lessonName] = remainingCompleted[lessonName];
          //   remainingCompleted[lessonName] = 0;
          // }
        }
      }
      content.push(
        <AssignmentList info={assignmentList[i]} status={assignmentStatus} />
      );
    }
  }

  if (content.length === 0) {
    // No active assignments
    content = (
      <tr key={"None"}>
        <td>No Assignments</td>
      </tr>
    );
  }

  return (
    <Fragment>
      <div className="clear100" />
      <div className="row">
        <div className="col s3" />
        <div className="col s9">
          <table style={{ width: "70%", backgroundColor: "rgb(0,0,0,0.2)" }}>
            <thead>
              <tr key={"header"}>
                <th>Lesson Name</th>
                <th># Completed</th>
                <th>Due Date</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>{content}</tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default AssignmentsCompleted;
