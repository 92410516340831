import React from "react";

const Performance = (props) => {
  const precisionWidth = props.avgPrecision+"%";
  const smoothnessWidth = props.avgSmoothness+"%";
  //const speedWidth = Math.min(100,100*props.avgSpeed/(props.diagonal*0.8))+"%";
  const speedWidth = props.avgSpeed >= 1000 ? 100 + "%" : props.avgSpeed/10 +"%";

  return (
    <div className="col s6 m6 l6">
      <div id="performance">
        <div className="stat-container">
          <div id="precision-total" style={{width:precisionWidth}}>Precision</div>
          <div className="stat-value" id="precision-value">
            {Math.floor(props.avgPrecision) + "%"}
          </div>
        </div>

        <div className="stat-container">
          <div id="smoothness-total" style={{width:smoothnessWidth}}>Smoothness</div>
          <div className="stat-value" id="smoothness-value">
            {Math.floor(props.avgSmoothness) + "%"}
          </div>
        </div>

        <div className="stat-container">
          <div id="speed-total" style={{width:speedWidth}}>Speed</div>
          <div className="stat-value" id="speed-value">
            {Math.floor(props.avgSpeed) + "px/s"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Performance;
