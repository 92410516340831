import StarRating from "../UI/StarRating";
import Performance from "./Performance";
import Notifications from "../UI/Notifications";
import LessonNav from "../UI/LessonNav";

const ResultsOverlay = (props) => {
  //"Great job! Try sketching a little faster while retaining\n precision to improve the smoothness of your lines.";
  let leoFeedback =
    "Great job! Your sketch was smooth, quick, and\n precise. Keep practicing and keep improving!";

  if (props.avgSpeed < 800) {
    leoFeedback =
      "Try sketching a little faster! You may see a drop\n in precision, but both will increase as you get more practice!";
  } else if (props.avgPrecision < 80 && props.avgSmoothness < 80) {
    leoFeedback =
      "You deviated a bit from the prompt. Confident strokes\n can improve both your smoothness and precision!";
  } else if (props.avgPrecision < 80) {
    leoFeedback =
      "Keep an eye on the prompt! You'll get better at visualizing\n the shapes in perspective.";
  } else if (props.avgSmoothness < 80) {
    leoFeedback =
      "Your lines are a bit wavy. Try focusing on your pen control\n on the next exercise!";
  }

  return (
    <div id="lessonOverlay" className="active">
      <div className="container overlayContent">
        <div id="overlayTitle" className="overlayTitle">
          {props.name}
        </div>
        {/* <div id="best-stars">Best</div> */}
        <div style={{ clear: "both" }}></div>
        <div id="overlayResults" style={{ display: "block" }}>
          <StarRating starRating={props.starRating} />
          <div className="row">
            <div className="col s3 m3 l3">
              <div id="leoLesson">
                <div className="leoName">Leo</div>
              </div>
            </div>
            <div className="col s3 m3 l3">
              <div id="leoFeedback">
                <p>{leoFeedback}</p>
              </div>
            </div>
            <Performance
              avgPrecision={props.avgPrecision}
              avgSmoothness={props.avgSmoothness}
              avgSpeed={props.avgSpeed}
              diagonal={props.diagonal}
            />
          </div>
        </div>
        {/* <Notifications
          totalEXP={props.totalEXP}
          bonusPrecision={props.bonusPrecision}
          bonusSmoothness={props.bonusSmoothness}
          bonusSpeed={props.bonusSpeed}
        /> */}
        <LessonNav
          // onBack={props.onBack}
          onStart={props.onStart}
          beginText="Back to Lessons"
          disableBack={true}
        />
      </div>
    </div>
  );
};

export default ResultsOverlay;
