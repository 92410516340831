import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class CombinationsAddLesson extends Lesson {
  // Free draw perspective

  exportLessonData = () => {
    return {
      name: "CombinationsAdd",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };

  generateLesson() {
    // Make vanishing points visible

    this.vp2.x = this.canvasWidth * 1.3;
    this.vp1.x = -this.canvasWidth * 0.7;
    this.horizonHeight = this.canvasHeight * 0;
    this.vp2.y = this.horizonHeight;
    this.vp1.y = this.horizonHeight;

    // let x1 = (11 * this.canvasWidth) / 20;
    // let y1 = (9 * this.canvasHeight) / 10;
    // this.circle1.position = new paper.Point(x1, y1);

    // // Calculate measure points
    // const [mp1, mp2] = this.findMeasurePoints(x1);
  }

  getAngleDegrees = (stroke) => {
    let adj = stroke.getPointAt(stroke.length).x - stroke.getPointAt(0).x;
    let opp = stroke.getPointAt(0).y - stroke.getPointAt(stroke.length).y;
    let result = Math.atan2(adj, opp) * (180 / Math.PI);
    return result;
  };

  getPrecision = (strokes) => {
    let num_correct = 0;
    let num_incorrect = 0;

    let angle;
    let perspectiveAngle;
    let angleDiff;
    for (let i = 0; i < strokes.length; i++) {
      // Consider straightened path
      let startpoint = strokes[i].firstSegment.point;
      let endpoint = strokes[i].lastSegment.point;

      // Consider which direction the stroke started from
      let straightPath;
      let straightZero;
      let straightEnd;
      if (startpoint.y > endpoint.y) {
        straightPath = new paper.Path.Line(startpoint, endpoint);
        straightZero = startpoint;
        straightEnd = endpoint;
      } else {
        straightPath = new paper.Path.Line(endpoint, startpoint);
        straightZero = endpoint;
        straightEnd = startpoint;
      }

      angle = this.getAngleDegrees(straightPath);

      // Is it vertical? Not important
      if (angle < 10 && angle > -10) {
        num_correct += 1;
      }

      //Is it going to left VP?
      else if (angle <= 0) {
        let perspectivePath = new paper.Path.Line(straightZero, this.vp1);
        perspectiveAngle = this.getAngleDegrees(perspectivePath);
        angleDiff = Math.abs(perspectiveAngle - angle);

        if (angleDiff > 3) {
          num_incorrect += 1;
        } else {
          num_correct += 1;
        }
      }
      //Must be going to right VP
      else {
        let perspectivePath = new paper.Path.Line(straightZero, this.vp2);
        perspectiveAngle = this.getAngleDegrees(perspectivePath);
        angleDiff = Math.abs(perspectiveAngle - angle);

        if (angleDiff > 3) {
          num_incorrect += 1;
        } else {
          num_correct += 1;
        }
      }
    }

    return num_correct / (num_correct + num_incorrect);
  };
}

export default CombinationsAddLesson;
