import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class CylinderLesson extends Lesson {
  //Properties
  exp = 8;
  center1 = new paper.Point(this.canvasWidth / 2, this.canvasHeight / 2);
  center2 = new paper.Point(this.canvasWidth / 2, this.canvasHeight / 2);
  squareHeight = 0;
  squareWidth = 0;
  topLeft;
  bottomRight;
  perfectShape = {
    ellipse1: "",
    ellipse2: "",
    length: 0,
  };

  // p1 is closest to viewer (front)
  // p2 is closest to vp1 (left)
  // p3 is closest to vp2 (right)
  // p4 is closest to horizon (back)

  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });

  circle5 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle6 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle7 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  circle8 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });

  // Points that define the planes containing the ellipses
  planeCircle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });

  planeCircle5 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle6 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle7 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });
  planeCircle8 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
  });

  // Ellipse points
  circle_array = [
    this.circle1,
    this.circle2,
    this.circle3,
    this.circle4,
    this.circle5,
    this.circle6,
    this.circle7,
    this.circle8,
  ];

  // Plane points
  plane_circle_array = [];

  // Diagaonls
  diag_circle_array = [];

  // Sort the strokes based on guidelines
  ellipse1_strokes = [];
  ellipse2_strokes = [];
  left_strokes = [];
  right_strokes = [];

  cylinderColor = "#1889d3";

  exportLessonData = () => {
    return {
      name: "Cylinders",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      center1: this.center1,
      center2: this.center2,
      perfectShape: this.perfectShape,
      vp1: this.vp1,
      vp2: this.vp2,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData
    // Define helper circles; clean up generateLesson

    this.vp1 = this.recoverPoint(this.lessonData.vp1);
    this.vp2 = this.recoverPoint(this.lessonData.vp2);

    // console.log(
    //   "Ellipse1 = ",
    //   this.lessonData.perfectShape.ellipse1,
    //   this.lessonData.center1
    // );
    const [ellipse1, center1] = this.recoverEllipse(
      this.lessonData.perfectShape.ellipse1,
      this.lessonData.center1
    );
    // console.log(
    //   "Ellipse2 = ",
    //   this.lessonData.perfectShape.ellipse2,
    //   this.lessonData.center2
    // );
    const [ellipse2, center2] = this.recoverEllipse(
      this.lessonData.perfectShape.ellipse2,
      this.lessonData.center2
    );

    this.perfectShape.ellipse1 = ellipse1;
    this.center1 = center1;
    this.perfectShape.ellipse2 = ellipse2;
    this.center2 = center2;

    const [p1, p2, p3, p4] = this.solvePlane(
      this.perfectShape.ellipse1,
      this.center1
    );
    const [p5, p6, p7, p8] = this.solvePlane(
      this.perfectShape.ellipse2,
      this.center2
    );

    this.planeCircle1.center = p1;
    this.planeCircle2.center = p2;
    this.planeCircle3.center = p3;
    this.planeCircle4.center = p4;
    this.planeCircle5.center = p5;
    this.planeCircle6.center = p6;
    this.planeCircle7.center = p7;
    this.planeCircle8.center = p8;

    this.generateDiagCircles();

    this.perfectShape.length =
      this.perfectShape.ellipse1.length +
      this.perfectShape.ellipse2.length +
      this.diag2_circle.position.getDistance(this.diag6_circle.position) +
      this.diag3_circle.position.getDistance(this.diag7_circle.position);
  };

  generateDiagCircles = () => {
    let temp = this.perfectShape.ellipse2.getIntersections(
      new paper.Path.Line(this.center2, this.planeCircle5.center)
    )[0].point;
    this.diag5_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse2.getIntersections(
      new paper.Path.Line(this.center2, this.planeCircle6.center)
    )[0].point;
    this.diag6_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse2.getIntersections(
      new paper.Path.Line(this.center2, this.planeCircle7.center)
    )[0].point;
    this.diag7_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse2.getIntersections(
      new paper.Path.Line(this.center2, this.planeCircle8.center)
    )[0].point;
    this.diag8_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse1.getIntersections(
      new paper.Path.Line(this.center1, this.planeCircle1.center)
    )[0].point;
    this.diag1_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });
    temp = this.perfectShape.ellipse1.getIntersections(
      new paper.Path.Line(this.center1, this.planeCircle2.center)
    )[0].point;
    this.diag2_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse1.getIntersections(
      new paper.Path.Line(this.center1, this.planeCircle3.center)
    )[0].point;
    this.diag3_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });

    temp = this.perfectShape.ellipse1.getIntersections(
      new paper.Path.Line(this.center1, this.planeCircle4.center)
    )[0].point;
    this.diag4_circle = new paper.Path.Circle({
      center: [temp.x, temp.y],
      radius: this.masterCircleSize,
    });
  };

  plotGuidelines = () => {
    // Add lines 1-2, 1-3, 2-4, 3-4, 5-6, 5-7, 6-8, 7-8
    let pairs = [
      [this.planeCircle1.center, this.planeCircle2.center],
      [this.planeCircle1.center, this.planeCircle3.center],
      [this.planeCircle4.center, this.planeCircle2.center],
      [this.planeCircle4.center, this.planeCircle3.center],
      [this.planeCircle5.center, this.planeCircle6.center],
      [this.planeCircle5.center, this.planeCircle7.center],
      [this.planeCircle8.center, this.planeCircle6.center],
      [this.planeCircle8.center, this.planeCircle7.center],
    ];
    for (let i = 0; i < pairs.length; i++) {
      let temp = new paper.Path.Line({
        from: pairs[i][0],
        to: pairs[i][1],
        strokeColor: this.masterCircleColor,
      });
    }

    if (this.variation === "scaffold") {
      // Add color to plane lines and diagonal points

      this.generateDiagCircles();

      // Add points along plane definition lines
      // this.circle1.strokeColor = "purple";
      // this.circle2.strokeColor = "orange";
      // this.circle3.strokeColor = "yellow";
      // this.circle4.strokeColor = "#1889d3";
      this.circle1.strokeColor = this.cylinderColor;
      this.circle2.strokeColor = this.cylinderColor;
      this.circle3.strokeColor = this.cylinderColor;
      this.circle4.strokeColor = this.cylinderColor;
      this.circle5.strokeColor = this.cylinderColor;
      this.circle6.strokeColor = this.cylinderColor;
      this.circle7.strokeColor = this.cylinderColor;
      this.circle8.strokeColor = this.cylinderColor;

      // Add points along diagonals
      // this.diag1_circle.strokeColor = "red";
      // this.diag2_circle.strokeColor = "red";
      // this.diag3_circle.strokeColor = "red";
      // this.diag4_circle.strokeColor = "red";
      // this.diag5_circle.strokeColor = "red";
      // this.diag6_circle.strokeColor = "red";
      // this.diag7_circle.strokeColor = "red";
      // this.diag8_circle.strokeColor = "red";
      this.diag1_circle.strokeColor = this.cylinderColor;
      this.diag2_circle.strokeColor = this.cylinderColor;
      this.diag3_circle.strokeColor = this.cylinderColor;
      this.diag4_circle.strokeColor = this.cylinderColor;
      this.diag5_circle.strokeColor = this.cylinderColor;
      this.diag6_circle.strokeColor = this.cylinderColor;
      this.diag7_circle.strokeColor = this.cylinderColor;
      this.diag8_circle.strokeColor = this.cylinderColor;

      // Add verticals
      pairs = [
        [this.planeCircle1.center, this.planeCircle5.center],
        [this.planeCircle2.center, this.planeCircle6.center],
        [this.planeCircle3.center, this.planeCircle7.center],
        [this.planeCircle4.center, this.planeCircle8.center],
      ];
      for (let i = 0; i < pairs.length; i++) {
        let temp = new paper.Path.Line({
          from: pairs[i][0],
          to: pairs[i][1],
          strokeColor: this.masterCircleColor,
        });
      }

      // Add dotted lines
      pairs = [
        [this.vp1, this.circle3.position],
        [this.vp2, this.circle1.position],
        [this.vp1, this.circle7.position],
        [this.vp2, this.circle5.position],
      ];
      for (let i = 0; i < pairs.length; i++) {
        let temp = new paper.Path.Line({
          from: pairs[i][0],
          to: pairs[i][1],
          strokeColor: "rgb(20,20,20,20)",
          dashArray: [1, 5],
        });
      }
    }
  };

  generateLesson() {
    let checkVisible = false;
    let count = 0;
    //Random size
    let min_dim = Math.min(this.canvasWidth, this.canvasHeight);
    this.squareHeight = min_dim * 0.3 + Math.random() * min_dim * 0.05;
    this.squareWidth = this.squareHeight;
    while (!checkVisible) {
      // Frontmost point
      const x1 = this.canvasWidth / 2;
      let y1 = this.canvasHeight * 0.7;
      if (count > 20) {
        // if (this.horizonHeight > 0) {
        //   y1 = this.canvasHeight * 0.3;
        // }
        this.squareHeight *= 0.9;
        this.squareWidth = this.squareHeight;
      }

      this.circle1.position = new paper.Point(x1, y1);

      // Calculate measure points
      const [mp1, mp2] = this.findMeasurePoints(x1);
      if (!mp1) {
        // Generated an impossible point in the given perspective
        continue;
      }

      // Define first plane: get center points for circles 2-4
      const [p2, p3, p4] = this.definePlane(x1, y1, mp1, mp2);
      this.circle2.position = p2;
      this.circle3.position = p3;
      this.circle4.position = p4;

      // Keep track of the first plane
      this.planeCircle1.center = new paper.Point(x1, y1);
      this.planeCircle2.center = p2;
      this.planeCircle3.center = p3;
      this.planeCircle4.center = p4;

      const [
        center_1,
        front_left_mid_1,
        front_right_mid_1,
        back_left_mid_1,
        back_right_mid_1,
        ellipse_center_1,
        angle_1,
        major_1,
        minor_1,
      ] = this.defineEllipse(
        this.circle1,
        this.circle2,
        this.circle3,
        this.circle4,
        -1
      );

      this.center1 = center_1;

      // Move the points from the plane corners to the ellipse points
      this.circle1 = front_left_mid_1;
      this.circle2 = back_left_mid_1;
      this.circle3 = front_right_mid_1;
      this.circle4 = back_right_mid_1;

      this.perfectShape.ellipse1 = new paper.Path.Ellipse({
        center: [ellipse_center_1.x, ellipse_center_1.y],
        radius: [major_1, minor_1],
        //strokeColor: "red",
      });
      this.perfectShape.ellipse1.rotate(angle_1);

      // ###################################################################

      const x5 = x1; // second plane aligns with anchor from first
      const y5 = y1 - min_dim * 0.4;
      this.circle5.position = new paper.Point(x5, y5);
      // console.log("CYLINDERS: currently only below horizon");

      // Define second plane: get center points for circles 6-8
      const [p6, p7, p8] = this.definePlane(x5, y5, mp1, mp2);
      this.circle6.position = p6;
      this.circle7.position = p7;
      this.circle8.position = p8;

      // Keep track of the second plane
      this.planeCircle5.center = new paper.Point(x5, y5);
      this.planeCircle6.center = p6;
      this.planeCircle7.center = p7;
      this.planeCircle8.center = p8;

      const [
        center_2,
        front_left_mid_2,
        front_right_mid_2,
        back_left_mid_2,
        back_right_mid_2,
        ellipse_center_2,
        angle_2,
        major_2,
        minor_2,
      ] = this.defineEllipse(
        this.circle5,
        this.circle6,
        this.circle7,
        this.circle8,
        -1
      );

      this.center2 = center_2;

      this.circle5 = front_left_mid_2;
      this.circle6 = back_left_mid_2;
      this.circle7 = front_right_mid_2;
      this.circle8 = back_right_mid_2;

      this.perfectShape.ellipse2 = new paper.Path.Ellipse({
        center: [ellipse_center_2.x, ellipse_center_2.y],
        radius: [major_2, minor_2],
        //strokeColor: "red",
      });
      this.perfectShape.ellipse2.rotate(angle_2);

      checkVisible = true;

      this.circle_array = [
        this.circle1,
        this.circle2,
        this.circle3,
        this.circle4,
        this.circle5,
        this.circle6,
        this.circle7,
        this.circle8,
      ];

      checkVisible = this.checkPerspectiveVisible(this.circle_array);
      count += 1;

      this.perfectShape.length =
        this.perfectShape.ellipse1.length +
        this.perfectShape.ellipse2.length +
        2 * this.squareHeight;

      if (count > 23) {
        console.log("Perspective on edge of screen");
        break;
      }
    }

    //this.generateDiagCircles();

    this.plotGuidelines();

    return true;
  }

  withinBounds = (pt, checkEllipse1) => {
    let circleA;
    let circleB;
    let circleC;
    let circleD;
    if (checkEllipse1) {
      circleA = this.circle1;
      circleB = this.circle2;
      circleC = this.circle3;
      circleD = this.circle4;
    } else {
      circleA = this.circle5;
      circleB = this.circle6;
      circleC = this.circle7;
      circleD = this.circle8;
    }

    let leftBound = Math.min(
      circleA.position.x,
      circleB.position.x,
      circleC.position.x,
      circleD.position.x
    );
    let rightBound = Math.max(
      circleA.position.x,
      circleB.position.x,
      circleC.position.x,
      circleD.position.x
    );
    let topBound = Math.min(
      circleA.position.y,
      circleB.position.y,
      circleC.position.y,
      circleD.position.y
    );
    let bottomBound = Math.max(
      circleA.position.y,
      circleB.position.y,
      circleC.position.y,
      circleD.position.y
    );

    //console.log(leftBound, rightBound, topBound, bottomBound);

    let within_horiz =
      pt.x >= leftBound - 3 * this.masterCircleSize &&
      pt.x <= rightBound + 3 * this.masterCircleSize;
    let within_vert =
      pt.y >= topBound - 3 * this.masterCircleSize &&
      pt.y <= bottomBound + 3 * this.masterCircleSize;

    // console.log("horiz:", within_horiz, "vert:", within_vert);

    return true;
    return within_horiz && within_vert;
  };

  verticalLineCheck = (strokes) => {
    let left_side = this.combinePath(this.left_strokes);
    let right_side = this.combinePath(this.right_strokes);

    let found_left = false;
    let found_right = false;

    if (left_side.length !== 0) {
      let left_start = left_side.firstSegment.point;
      let left_end = left_side.lastSegment.point;
      if (
        (this.withinBounds(left_start, true) &&
          this.withinBounds(left_end, false)) ||
        (this.withinBounds(left_start, false) &&
          this.withinBounds(left_end, true))
      ) {
        found_left = true;
      }
    }

    if (right_side.length !== 0) {
      let right_start = right_side.firstSegment.point;
      let right_end = right_side.lastSegment.point;

      if (
        (this.withinBounds(right_start, true) &&
          this.withinBounds(right_end, false)) ||
        (this.withinBounds(right_start, false) &&
          this.withinBounds(right_end, true))
      ) {
        found_right = true;
      }
    }

    // console.log("left:", found_left, "right:", found_right);

    return found_left && found_right;
  };

  sortStrokes = (strokes) => {
    // Sort strokes into left, right, ellipse1, and ellipse2

    let vertical_guideline =
      Math.abs(this.center1.y - this.center2.y) / 2 +
      Math.min(this.center1.y, this.center2.y);

    let horizontal_guideline =
      Math.abs(this.center1.x - this.center2.x) / 2 +
      Math.min(this.center1.x, this.center2.x);

    let avg_x,
      avg_y = 0;
    for (let i = 0; i < strokes.length; i++) {
      let stroke = strokes[i];

      if (this.straightLineCheck(stroke)) {
        avg_x =
          stroke.segments
            .map((val) => val.point.x)
            .reduce((a, b) => {
              return a + b;
            }) / stroke.segments.length;
        if (avg_x < horizontal_guideline) {
          this.left_strokes.push(stroke);
        } else {
          this.right_strokes.push(stroke);
        }
      } else {
        avg_y =
          stroke.segments
            .map((val) => val.point.y)
            .reduce((a, b) => {
              return a + b;
            }) / stroke.segments.length;
        if (avg_y < vertical_guideline) {
          this.ellipse2_strokes.push(stroke);
        } else {
          this.ellipse1_strokes.push(stroke);
        }
      }
    }
  };

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }

    this.sortStrokes(strokes);

    let combinedPath1 = this.combinePath(this.ellipse1_strokes);
    let combinedPath2 = this.combinePath(this.ellipse2_strokes);

    //Do the checks
    if (
      combinedPath1.intersects(this.circle1) &&
      combinedPath1.intersects(this.circle2) &&
      combinedPath1.intersects(this.circle3) &&
      combinedPath1.intersects(this.circle4) &&
      combinedPath2.intersects(this.circle5) &&
      combinedPath2.intersects(this.circle6) &&
      combinedPath2.intersects(this.circle7) &&
      combinedPath2.intersects(this.circle8) &&
      this.verticalLineCheck(strokes)
    ) {
      // console.log("it's a cylinder!");
      // console.log(
      //   "Can add checks for closeness of midpoints of lines if desired"
      // );

      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }

    // Clear the helper arrays
    this.ellipse1_strokes = [];
    this.ellipse2_strokes = [];
    this.left_strokes = [];
    this.right_strokes = [];

    return false;
  }

  getPrecision(strokes) {
    let totalDeviation = 0;
    let num_misses = 0;

    this.sortStrokes(strokes);

    // Calculate deviation
    let combined_ellipse1 = this.combinePath(this.ellipse1_strokes);
    if (combined_ellipse1.length === 0) {
      num_misses += 1;
    } else {
      totalDeviation += this.ellipseError(
        combined_ellipse1,
        this.perfectShape.ellipse1,
        this.showFeedback
      );
    }

    let combined_ellipse2 = this.combinePath(this.ellipse2_strokes);
    if (combined_ellipse2.length === 0) {
      num_misses += 1;
    } else {
      totalDeviation += this.ellipseError(
        combined_ellipse2,
        this.perfectShape.ellipse2,
        this.showFeedback
      );
    }

    let left_side = this.combinePath(this.left_strokes);
    if (left_side.length === 0) {
      num_misses += 1;
    } else {
      totalDeviation += this.checkLine(
        left_side,
        this.diag2_circle.position,
        this.diag6_circle.position,
        this.showFeedback
      );
    }

    let right_side = this.combinePath(this.right_strokes);
    if (right_side.length === 0) {
      num_misses += 1;
    } else {
      totalDeviation += this.checkLine(
        right_side,
        this.diag3_circle.position,
        this.diag7_circle.position,
        this.showFeedback
      );
    }

    let avgDeviation = totalDeviation / this.perfectShape.length;
    //console.log("Average Deviation: " + avgDeviation);

    return Math.max(100 - avgDeviation - (100 / 4) * num_misses, 0);
  }

  getSmoothness(strokes) {
    let avgAbsAngle = 0;

    this.sortStrokes(strokes);

    let combined_ellipse1 = this.combinePath(this.ellipse1_strokes);
    this.ellipse1_deviation = this.ellipseDeviation(
      combined_ellipse1,
      this.perfectShape.ellipse1
    );

    let combined_ellipse2 = this.combinePath(this.ellipse2_strokes);
    this.ellipse2_deviation = this.ellipseDeviation(
      combined_ellipse2,
      this.perfectShape.ellipse2
    );
    let left_side = this.combinePath(this.left_strokes);
    let right_side = this.combinePath(this.right_strokes);

    if (
      combined_ellipse1.length +
        combined_ellipse2.length +
        left_side.length +
        right_side.length ===
      0
    ) {
      return 0; // no sketch
    }

    avgAbsAngle += this.smoothnessHelper(this.ellipse1_deviation);
    avgAbsAngle += this.smoothnessHelper(this.ellipse2_deviation);
    avgAbsAngle += this.smoothnessHelper(left_side);
    avgAbsAngle += this.smoothnessHelper(right_side);

    avgAbsAngle /= this.perfectShape.length;
    let normalizedSmoothness = this.normalizeSmoothness(avgAbsAngle);
    return normalizedSmoothness;
  }
}

export default CylinderLesson;
