import { configureStore } from "@reduxjs/toolkit";
import canvasCtxReducer from "./canvas-context";
import loginCtxReducer from "./login-context";
//import sketchReducer from "./sketch-context";

const store = configureStore({
  reducer: { canvas: canvasCtxReducer, login: loginCtxReducer},
});

export default store;
