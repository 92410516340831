import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class CircleLesson extends Lesson {
  //Properties
  exp = 4;
  center = new paper.Point(0, 0);
  perfectShape = new paper.Path.Circle(this.center, this.circleRadius);
  circleRadius = 0;

  isSquareRatio = (path) => {
    //Set thresholds
    let aspectRatioLowerBound = 0.7;
    let aspectRatioUpperBound = 1.3;
    let sizeLowerBound = this.circleRadius * 2 * 0.5;
    let sizeUpperBound = this.circleRadius * 2 * 1.5;

    //Compute ratio and bounding box size
    let x = path.segments.map((ele) => ele.point.x);
    let y = path.segments.map((ele) => ele.point.y);

    let xmax = Math.max(...x);
    let xmin = Math.min(...x);
    let ymax = Math.max(...y);
    let ymin = Math.min(...y);
    let ratio = (ymax - ymin) / (xmax - xmin);
    let boundingBoxLength = Math.sqrt(
      Math.pow(xmax - xmin, 2) + Math.pow(ymax - ymin, 2)
    );

    //Do the checks
    if (ratio >= aspectRatioLowerBound && ratio <= aspectRatioUpperBound) {
      //console.log("Looks to be square ratio");
      if (
        boundingBoxLength > sizeLowerBound &&
        boundingBoxLength < sizeUpperBound
      ) {
        //console.log("It's the right size!");
        return true;
      }
    } else {
      return false;
    }
  };

  exportLessonData = () => {
    return {
      name: "Circles",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      perfectShape: this.perfectShape,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData

    // perfectShape is a paper.js Circle
    this.perfectShape = this.recoverCircle(this.lessonData.perfectShape);
  }


  generateLesson() {
    let x1;
    let y1;

    //Random sized container square and location
    this.circleRadius =
      0.05 * this.canvasWidth + Math.random() * 0.1 * this.canvasWidth;
    x1 = 0.5 * this.canvasWidth;
    y1 = 0.5 * this.canvasHeight;
    this.center.x = x1;
    this.center.y = y1;
    let containerSquare = new paper.Path.Rectangle({
      point: [
        this.center.x - this.circleRadius,
        this.center.y - this.circleRadius,
      ],
      size: [this.circleRadius * 2, this.circleRadius * 2],
      strokeColor: this.masterCircleColor,
    });
    containerSquare.strokeColor = this.masterCircleColor;

    //Ensure there is a perfect circle
    this.perfectShape = new paper.Path.Circle(this.center, this.circleRadius);

    const scaffoldColor =
      this.variation === "scaffold" ? this.masterCircleColor : null;

    // Guidelines

    // Diagonals
    let diag1 = new paper.Path(
      containerSquare.segments[0].point,
      containerSquare.segments[2].point
    );
    diag1.strokeColor = scaffoldColor;
    let diag2 = new paper.Path(
      containerSquare.segments[1].point,
      containerSquare.segments[3].point
    );
    diag2.strokeColor = scaffoldColor;

    //Guide points on circle
    let guidePoint1 = new paper.Path.Circle(
      this.perfectShape.segments[0].point,
      this.masterCircleSize
    );
    guidePoint1.fillColor = scaffoldColor;
    let guidePoint2 = new paper.Path.Circle(
      this.perfectShape.segments[1].point,
      this.masterCircleSize
    );
    guidePoint2.fillColor = scaffoldColor;
    let guidePoint3 = new paper.Path.Circle(
      this.perfectShape.segments[2].point,
      this.masterCircleSize
    );
    guidePoint3.fillColor = scaffoldColor;
    let guidePoint4 = new paper.Path.Circle(
      this.perfectShape.segments[3].point,
      this.masterCircleSize
    );
    guidePoint4.fillColor = scaffoldColor;

    //Guide points on circle
    let guidePoint5 = new paper.Path.Circle(
      diag1.getPointAt(diag1.length * 0.15),
      this.masterCircleSize
    );
    guidePoint5.fillColor = scaffoldColor;
    let guidePoint6 = new paper.Path.Circle(
      diag1.getPointAt(diag1.length * 0.85),
      this.masterCircleSize
    );
    guidePoint6.fillColor = scaffoldColor;
    let guidePoint7 = new paper.Path.Circle(
      diag2.getPointAt(diag2.length * 0.15),
      this.masterCircleSize
    );
    guidePoint7.fillColor = scaffoldColor;
    let guidePoint8 = new paper.Path.Circle(
      diag2.getPointAt(diag2.length * 0.85),
      this.masterCircleSize
    );
    guidePoint8.fillColor = scaffoldColor;

    return true;
  }

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }
    // let combinedPath = this.combinePath(strokes);

    //Do the checks
    //if (this.isSquareRatio(combinedPath) && this.isClosedShape(strokes)) {
    if (this.isClosedShape(strokes)) {
      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }
    return false;
  }

  getPrecision(strokes) {
    let path = this.combinePath(strokes);
    if (path.length === 0) {
      return 0;
    }
    let totalDeviation = this.ellipseError(
      path,
      this.perfectShape,
      this.showFeedback
    );

    let avgDeviation = totalDeviation / (this.perfectShape.length / 2);
    return 100 - avgDeviation;
  }

  getSmoothness(strokes) {
    return this.getCurvedSmoothness(strokes);
  }
}

export default CircleLesson;
