import React, { Fragment } from "react";
import { useSelector } from "react-redux";

const categoryOptions = {
  Basics: ["Lines", "Arcs", "Squares", "Circles"],
  Perspective: ["Planes", "Ellipses"],
  Primitives: ["Cubes", "Cylinders", "Cones", "Spheres"],
  Combinations: [],
  Perception: [],
};

const CategoryStatus = (props) => {
  const lessonNames = useSelector((state) => state.login.lessonNames);
  // const lessonsCompleted = useSelector((state) => state.login.lessonsCompleted);
  const assignmentList = useSelector((state) => state.login.assignmentList);

  const lessonTimestamps = useSelector((state) => state.login.lessonTimestamps);

  let today = new Date(3600000 * Math.floor(Date.now() / 3600000));
  let offset = new Date().getTimezoneOffset();

  // let numberAssigned = assignmentList
  //   .filter((assignment) => props.lessonName in assignment)
  //   .filter((assignment) => new Date(assignment["StartDate"]) < today)
  //   .map((assignment) => assignment[props.lessonName])
  //   .reduce((prev, curr) => prev + curr, 0);

  // Check if assignments have been completed

  const lessonObj = lessonTimestamps.map((lesson) => {
    const obj = {};
    obj["lessonName"] = lesson.LessonName;
    obj["timestamp"] = new Date(lesson.Timestamp);
    obj["timestamp"].setMinutes(obj["timestamp"].getMinutes() - offset);
    return obj;
  });
  const remainingCompleted = {};
  for (const lessonName of lessonNames) {
    remainingCompleted[lessonName] = lessonObj.filter(
      (obj) => obj.lessonName === lessonName
    );
  }

  const numberAssigned = {};
  for (const lessonName of lessonNames) {
    numberAssigned[lessonName] = 0;
  }

  for (let i = 0; i < assignmentList.length; i++) {
    let startDate = new Date(assignmentList[i]["StartDate"]);
    let endDate = new Date(assignmentList[i]["DueDate"]);
    if (startDate.getYear() == 0 || startDate > today) {
      continue;
    }

    // Determine assigned amount and completed (for that assignment)
    for (const lessonName of lessonNames) {
      if (
        lessonName in assignmentList[i] &&
        assignmentList[i][lessonName] !== 0 &&
        categoryOptions[props.category].includes(lessonName)
      ) {
        // Add up number assigned
        numberAssigned[lessonName] += assignmentList[i][lessonName];

        for (let j = 0; j < remainingCompleted[lessonName].length; j++) {
          if (
            numberAssigned[lessonName] > 0 &&
            remainingCompleted[lessonName][j].timestamp > startDate
          ) {
            numberAssigned[lessonName] -= 1;
            remainingCompleted[lessonName].splice(j, 1);
            j -= 1;
          }
        }
      }
    }
  }

  let incompleteColor = "#D97900";
  let statusColor = incompleteColor;

  let totalAssigned = 0;
  for (const lessonName of lessonNames) {
    totalAssigned += numberAssigned[lessonName];
  }

  return (
    <li id={props.id} className={props.status} onClick={props.onClick}>
      <div style={{ verticalAlign: "center" }}>
        {props.name}
        {totalAssigned > 0 && (
          <span
            className="new badge"
            data-badge-caption=""
            style={{ backgroundColor: statusColor }}
          >
            {totalAssigned}
          </span>
        )}
      </div>
    </li>
  );
};

export default CategoryStatus;
