import ExercisePage from "./ExercisePage";
import paper from "paper";
import { i } from "mathjs";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SFTCircles extends ExercisePage {
  //Properties

  letter_array = ["A", "B", "C", "D", "E", "F"];
  circle_array = this.letter_array.map((ele) => [
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [0, 0],
      radius: this.masterCircleSize,
    }),
  ]);
  perfectShape = [];

  squareHeight = Math.min(this.canvasWidth, this.canvasHeight) / 6;

  horiz_offset = (this.canvasWidth - 3 * this.squareHeight) / 4;
  vert_offset = this.canvasHeight / 10;

  initial_x1 = this.horiz_offset;
  initial_y1 = this.canvasHeight / 3 + this.canvasHeight / 20;

  example_array = [
    new paper.Path.Circle({
      center: [this.initial_x1, this.initial_y1],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [this.initial_x1, this.initial_y1 - this.squareHeight],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [this.initial_x1 + this.squareHeight, this.initial_y1],
      radius: this.masterCircleSize,
    }),
    new paper.Path.Circle({
      center: [
        this.initial_x1 + this.squareHeight,
        this.initial_y1 - this.squareHeight,
      ],
      radius: this.masterCircleSize,
    }),
  ];

  midpointHelper = (ptA, ptB) => {
    let x = Math.abs(ptA.x - ptB.x) / 2 + Math.min(ptA.x, ptB.x);
    let y = Math.abs(ptA.y - ptB.y) / 2 + Math.min(ptA.y, ptB.y);
    return new paper.Point(x, y);
  };

  getMidPoints = (pts) => {
    let top_mid = this.midpointHelper(pts[1], pts[3]);
    let bot_mid = this.midpointHelper(pts[0], pts[2]);
    let left_mid = this.midpointHelper(pts[0], pts[1]);
    let right_mid = this.midpointHelper(pts[2], pts[3]);
    return { top_mid, bot_mid, left_mid, right_mid };
  };

  drawBox = (pts) => {
    let bottom = new paper.Path.Line({
      from: pts[0],
      to: pts[2],
      strokeColor: this.masterCircleColor,
    });
    let top = new paper.Path.Line({
      from: pts[1],
      to: pts[3],
      strokeColor: this.masterCircleColor,
    });
    let left = new paper.Path.Line({
      from: pts[0],
      to: pts[1],
      strokeColor: this.masterCircleColor,
    });
    let right = new paper.Path.Line({
      from: pts[3],
      to: pts[2],
      strokeColor: this.masterCircleColor,
    });
  };

  drawDiagonal = (pts) => {
    let neg_diag = new paper.Path.Line({
      from: pts[1],
      to: pts[2],
      strokeColor: this.masterCircleColor,
    });
    let pos_diag = new paper.Path.Line({
      from: pts[0],
      to: pts[3],
      strokeColor: this.masterCircleColor,
    });
  };

  circleHelper = (pts, scaffold, lbl) => {
    // Draw box outline
    this.drawBox(pts);

    if (scaffold >= 1) {
      this.drawDiagonal(pts);
    }

    if (scaffold >= 2) {
      let { top_mid, bot_mid, left_mid, right_mid } = this.getMidPoints(pts);
      // Not diagonal lines; just re-using logic
      this.drawDiagonal([right_mid, bot_mid, top_mid, left_mid]);
    }

    let label2 = new paper.PointText(
      new paper.Point(
        pts[1].x - this.masterCircleSize * 4,
        pts[1].y + this.masterCircleSize * 0.75
      )
    );
    label2.content = lbl;
    label2.style = {
      fontSize: this.masterCircleSize * 2,
      justification: "center",
    };
  };

  plotExample = () => {
    // Plot title, instructions, and circle label

    const title = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20)
    );
    title.content = "CIRCLES";
    title.style = {
      fontWeight: "bold",
      fontSize: 20,
      fillColor: "black",
      justification: "center",
    };

    const instructions = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20 + 20)
    );
    instructions.content =
      "Sketch circles that fit perfectly inside these squares. \n It may help to “ghost” the circles before applying pressure.";
    instructions.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };

    // Example circle

    const pts = this.example_array.map((ele) => ele.position);
    this.drawBox(pts);
    this.drawDiagonal(pts);
    const { top_mid, bot_mid, left_mid, right_mid } = this.getMidPoints(pts);
    this.drawDiagonal([right_mid, bot_mid, top_mid, left_mid]); // not diagonal; reusing logic

    let top_mid_circle = new paper.Path.Circle({
      center: top_mid,
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    });

    let bot_mid_circle = new paper.Path.Circle({
      center: bot_mid,
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    });

    let left_mid_circle = new paper.Path.Circle({
      center: left_mid,
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    });

    let right_mid_circle = new paper.Path.Circle({
      center: right_mid,
      radius: this.masterCircleSize,
      fillColor: this.masterCircleColor,
    });

    let center = this.midpointHelper(top_mid, bot_mid);
    let exampleCircle = new paper.Path.Circle({
      center: center,
      radius: this.squareHeight / 2,
      strokeColor: "black",
    });

    let midpoint_label = new paper.PointText(
      new paper.Point(
        right_mid.x + this.masterCircleSize * 2,
        right_mid.y - this.masterCircleSize * 2
      )
    );
    midpoint_label.content = "MID-POINT \nOF EACH \nSIDE";
    midpoint_label.style = {
      fontSize: this.masterCircleSize * 2,
      fillColor: this.masterCircleColor,
    };

    // Example circle label
    const exampleLabel = new paper.PointText(
      new paper.Point(
        this.example_array[1].position.x + 20,
        this.example_array[1].position.y - 20
      )
    );
    exampleLabel.content = "EXAMPLE";
    exampleLabel.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };
  };

  generatePromptPoints = () => {
    const prompt_points = [];

    let x1 = this.initial_x1;
    let y1 = this.initial_y1;
    y1 += this.squareHeight + this.vert_offset; // skip example location

    let pts = [];
    for (let i = 0; i < this.circle_array.length; i++) {
      pts = [];
      pts.push(new paper.Point(x1, y1));
      pts.push(new paper.Point(x1, y1 - this.squareHeight));
      pts.push(new paper.Point(x1 + this.squareHeight, y1));
      pts.push(new paper.Point(x1 + this.squareHeight, y1 - this.squareHeight));

      y1 += this.squareHeight + this.vert_offset;

      if (i === 1 || i === 3) {
        // Move x, reset y
        x1 += this.squareHeight + this.horiz_offset;
        y1 = this.canvasHeight / 2;
      }

      prompt_points.push(pts);
    }
    return prompt_points;
  };

  generateLesson() {
    // Generate lines based on screen size
    const prompt_points = this.generatePromptPoints();
    let scaffold = 2;
    for (let i = 0; i < prompt_points.length; i++) {
      for (let j = 0; j < prompt_points[i].length; j++) {
        this.circle_array[i][j].position = prompt_points[i][j];
      }
      this.circleHelper(prompt_points[i], scaffold, this.letter_array[i]);

      if (i === 1 || i === 3) {
        // Reduce scaffolding after B and D
        scaffold -= 1;
      }
    }

    this.plotExample(); // plot instructions, title, example label

    this.perfectShape = this.circle_array.map(
      (ele) =>
        new paper.Path.Circle({
          center: this.midpointHelper(ele[0].position, ele[3].position),
          radius: this.squareHeight / 2,
          //strokeColor: "red",
        })
    );
  }

  precisionHelper = (strokes, prompt) => {
    let path = this.combinePath(strokes);
    if (path.length === 0) {
      return 0;
    }
    let totalDeviation = this.ellipseError(path, prompt, this.showFeedback);
    let avgDeviation = totalDeviation / (prompt.length / 2);
    return 100 - avgDeviation;
  };

  getSmoothness = (strokes) => {
    return this.getCurvedSmoothness(strokes);
  }

  getSmoothnessArray = (strokes) => {
    return this.getCurvedSmoothnessArray(strokes);
  }

  loadPrompt = () => {
    const prompt_points = this.generatePromptPoints();

    // Make the corners of the bounding box visible
    for (let i = 0; i < prompt_points.length; i++) {
      for (let j = 0; j < prompt_points[i].length; j++) {
        this.circle_array[i][j].position = prompt_points[i][j];
        this.circle_array[i][j].fillColor = this.cols[i];//this.masterCircleColor;
      }
    }

    this.perfectShape = this.circle_array.map(
      (ele) =>
        new paper.Path.Circle(
          this.midpointHelper(ele[0].position, ele[3].position),
          this.squareHeight / 2
        )
    );
  };

  exportLessonData = () => {
    return {
      name: "SFTCircles",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };
}

export default SFTCircles;
