const lineLesson = {
  name: "Lines",
  label: "1. LINES",
  category: "Basics",
  lessonList: [
    { type: "line", variation: "horizontal" },
    { type: "line", variation: "horizontal" },
    { type: "line", variation: "horizontal" },
    // { type: "line", variation: "horizontal" },

    { type: "line", variation: "vertical" },
    { type: "line", variation: "vertical" },
    { type: "line", variation: "vertical" },
    // { type: "line", variation: "vertical" },

    { type: "line", variation: "diagonal" },
    { type: "line", variation: "diagonal" },
    // { type: "line", variation: "diagonal" },
    // { type: "line", variation: "diagonal" },
  ],
  proTips: [
    "Here's a video to get you started.",
    "Who needs rulers! Let's learn how to sketch straight lines quickly and accurately without them.\n\n It helps to always concentrate on the dot you're moving towards, not the line.",
    "It is best practice to rotate the sketching surface so that you are always sketching comfortably away from your body.",
    "Keep your wrist locked and sketch from the shoulder to avoid arcs in your lines",
  ],
  proTipsImages: ["video", "protip1.png", "protip2.png", "protip3.png"],
  videoId: "_-Ur21Xnj58",
};


const arcLesson = {
  name: "Arcs",
  label: "2. ARCS",
  category: "Basics",
  lessonList: [
    { type: "arc", variation: "horizontal" },
    { type: "arc", variation: "horizontal" },
    { type: "arc", variation: "horizontal" },
    { type: "arc", variation: "horizontal" },
    // { type: "arc", variation: "horizontal" },
    // { type: "arc", variation: "horizontal" },

    { type: "arc", variation: "vertical" },
    { type: "arc", variation: "vertical" },
    { type: "arc", variation: "vertical" },
    { type: "arc", variation: "vertical" },
    // { type: "arc", variation: "vertical" },
    // { type: "arc", variation: "vertical" },
  ],
  proTips: [
    "Here's a video to get you started.",
    "It helps to always concentrate on the dot you're moving towards, not the arc.",
    "It can help to 'ghost' your arcs before you commit to them.",
  ],
  proTipsImages: ["video", "arc1.png", "arc2.png"],
  videoId: "vF7KUjMrMnw",
};

const circleLesson = {
  name: "Circles",
  label: "4. CIRCLES",
  category: "Basics",
  lessonList: [
    { type: "circle", variation: "scaffold" },
    { type: "circle", variation: "scaffold" },
    { type: "circle", variation: "scaffold" },
    { type: "circle", variation: "scaffold" },
    // { type: "circle", variation: "scaffold" },
    // { type: "circle", variation: "scaffold" },

    { type: "circle", variation: "no-scaffold" },
    { type: "circle", variation: "no-scaffold" },
    { type: "circle", variation: "no-scaffold" },
    { type: "circle", variation: "no-scaffold" },
    // { type: "circle", variation: "no-scaffold" },
    // { type: "circle", variation: "no-scaffold" },
  ],
  proTips: [
    "Here's a video to get you started.",
    "It may help to 'ghost' your circles before committing to them by hovering over the sketching surface",
    "Try sketching clockwise and counter-clockwise to see which works best for you",
    "If you're having trouble with precision slow down and sketch the circles piece by piece",
  ],
  proTipsImages: ["video", "circle1.png", "circle2.png", "circle3.png"],
  videoId: "jprI5fSlqG8",
};

const squareLesson = {
  name: "Squares",
  label: "3. SQUARES",
  category: "Basics",
  lessonList: [
    { type: "square", variation: "square" },
    { type: "square", variation: "square" },
    { type: "square", variation: "square" },
    { type: "square", variation: "square" },
    // { type: "square", variation: "square" },
    // { type: "square", variation: "square" },

    { type: "square", variation: "rectangle" },
    { type: "square", variation: "rectangle" },
    { type: "square", variation: "rectangle" },
    { type: "square", variation: "rectangle" },
    // { type: "square", variation: "rectangle" },
    // { type: "square", variation: "rectangle" },
  ],
  proTips: [
    "Here's a video to get you started.",
    "It's best to sketch squares with 4 seperate lines and to sketch parallel sides first",
    "Don't forget to rotate the sketching surface so every line is comfortable!",
  ],
  proTipsImages: ["video", "square1.png", "protip2.png"],
  videoId: "yvz1RnyVS0g",
};

const symmetryLesson = {
  name: "Symmetry",
  label: "15. SYMMETRY",
  category: "Perception",
  lessonList: [
    { type: "symmetry", variation: "" },
    { type: "symmetry", variation: "" },
    { type: "symmetry", variation: "" },
    // { type: "symmetry", variation: "" },
    // { type: "symmetry", variation: "" },
    // { type: "symmetry", variation: "" },
    // { type: "symmetry", variation: "" },
    // { type: "symmetry", variation: "" },
  ],
  proTips: [
    "Many objects, both natural and man-made, are symmetrical. This exercises will help you train your perception in sketching symmetrical objects.",
    "Take your time and be sure to sure to really LOOK at what you're drawing.",
  ],
  proTipsImages: ["symmetry1.png", "symmetry2.png"],
  videoId: "",
};

const contourLesson = {
  name: "Contours",
  label: "14. CONTOURS",
  category: "Perception",
  lessonList: [
    { type: "contour", variation: "visible" },
    { type: "contour", variation: "visible" },
    // { type: "contour", variation: "visible" },
    // { type: "contour", variation: "visible" },

    { type: "contour", variation: "blind" },
    { type: "contour", variation: "blind" },
    // { type: "contour", variation: "blind" },
    // { type: "contour", variation: "blind" },
  ],
  proTips: [
    "Improving drawing ability is in many ways all about 'seeing' the edges of objects. This exercise will help you train your perception in sketching contours.",
    "Take your time and be sure to sure to really LOOK at the edges.",
  ],
  proTipsImages: ["contour1.png", "contour2.png"],
  videoId: "",
};

const planeLesson = {
  name: "Planes",
  label: "7. PLANES",
  category: "Perspective",
  lessonList: [
    { type: "plane", variation: "scaffold" },
    { type: "plane", variation: "scaffold" },
    { type: "plane", variation: "scaffold" },
    { type: "plane", variation: "scaffold" },
    // { type: "plane", variation: "scaffold" },
    // { type: "plane", variation: "scaffold" },

    { type: "plane", variation: "scaffold" },
    { type: "plane", variation: "scaffold" },
    { type: "plane", variation: "scaffold" },
    { type: "plane", variation: "scaffold" },
    // { type: "plane", variation: "scaffold" },
    // { type: "plane", variation: "scaffold" },
  ],
  proTips: [
    "Here's a video to get you started.",
    "Planes are simply squares or rectangles in perspective. When sketching the edges of planes make sure there is proper convergence to the vanishing points.",
  ],
  proTipsImages: ["video", "protip1.png"],
  videoId: "ZU3iT9E6Yak",
};

const ellipseLesson = {
  name: "Ellipses",
  label: "8. ELLIPSES",
  category: "Perspective",
  lessonList: [
    { type: "ellipse", variation: "scaffold" },
    { type: "ellipse", variation: "scaffold" },
    { type: "ellipse", variation: "scaffold" },
    { type: "ellipse", variation: "scaffold" },
    // { type: "ellipse", variation: "scaffold" },
    // { type: "ellipse", variation: "scaffold" },

    { type: "ellipse", variation: "scaffold" },
    { type: "ellipse", variation: "scaffold" },
    { type: "ellipse", variation: "scaffold" },
    { type: "ellipse", variation: "scaffold" },
    // { type: "ellipse", variation: "scaffold" },
    // { type: "ellipse", variation: "scaffold" },
  ],
  proTips: [
    "Here's a video to get you started.",
    "Ellipses are simply circles in perspective. Remember that ellipses will touch the sides of the plane to help you draw the curve.",
  ],
  proTipsImages: ["video", "circle3.png"],
  videoId: "HMt7Zc3GpH4",
};

const cubeLesson = {
  name: "Cubes",
  label: "9. CUBES",
  category: "Primitives",
  lessonList: [
    { type: "cube", variation: "scaffold" },
    { type: "cube", variation: "scaffold" },
    { type: "cube", variation: "scaffold" },
    { type: "cube", variation: "scaffold" },
    // { type: "cube", variation: "scaffold" },
    // { type: "cube", variation: "scaffold" },

    { type: "cube", variation: "scaffold" },
    { type: "cube", variation: "scaffold" },
    { type: "cube", variation: "scaffold" },
    { type: "cube", variation: "scaffold" },
    // { type: "cube", variation: "scaffold" },
    // { type: "cube", variation: "scaffold" },
  ],
  proTips: ["Here's a video to get you started.", "Cubes are defined by two planes."],
  proTipsImages: ["video", "square1.png"],
  videoId: "kel0qcRc1Aw",
};

const cylinderLesson = {
  name: "Cylinders",
  label: "10. CYLINDERS",
  category: "Primitives",
  lessonList: [
    { type: "cylinder", variation: "scaffold" },
    { type: "cylinder", variation: "scaffold" },
    { type: "cylinder", variation: "scaffold" },
    { type: "cylinder", variation: "scaffold" },
    // { type: "cylinder", variation: "scaffold" },
    // { type: "cylinder", variation: "scaffold" },

    { type: "cylinder", variation: "scaffold" },
    { type: "cylinder", variation: "scaffold" },
    { type: "cylinder", variation: "scaffold" },
    { type: "cylinder", variation: "scaffold" },
    // { type: "cylinder", variation: "scaffold" },
    // { type: "cylinder", variation: "scaffold" },
  ],
  proTips: ["Here's a video to get you started.","Cylinders are defined by two ellipses."],
  proTipsImages: ["video", "circle3.png"],
  videoId: "iwlliUlZpkM",
};

const coneLesson = {
  name: "Cones",
  label: "11. CONES",
  category: "Primitives",
  lessonList: [
    { type: "cone", variation: "scaffold" },
    { type: "cone", variation: "scaffold" },
    { type: "cone", variation: "scaffold" },
    { type: "cone", variation: "scaffold" },
    // { type: "cone", variation: "scaffold" },
    // { type: "cone", variation: "scaffold" },

    { type: "cone", variation: "scaffold" },
    { type: "cone", variation: "scaffold" },
    { type: "cone", variation: "scaffold" },
    { type: "cone", variation: "scaffold" },
    // { type: "cone", variation: "scaffold" },
    // { type: "cone", variation: "scaffold" },
  ],
  proTips: ["Here's a video to get you started.","Cones are defined by one ellipse."],
  proTipsImages: ["video", "circle3.png"],
  videoId: "_uY_jWeb-88",
};

const sphereLesson = {
  name: "Spheres",
  label: "12. SPHERES",
  category: "Primitives",
  lessonList: [
    { type: "sphere", variation: "scaffold" },
    { type: "sphere", variation: "scaffold" },
    { type: "sphere", variation: "scaffold" },
    { type: "sphere", variation: "scaffold" },
    // { type: "sphere", variation: "scaffold" },
    // { type: "sphere", variation: "scaffold" },

    { type: "sphere", variation: "scaffold" },
    { type: "sphere", variation: "scaffold" },
    { type: "sphere", variation: "scaffold" },
    { type: "sphere", variation: "scaffold" },
    // { type: "sphere", variation: "scaffold" },
    // { type: "sphere", variation: "scaffold" },
  ],
  proTips: ["Here's a video to get you started.","Spheres are defined by a cube."],
  proTipsImages: ["video", "square1.png"],
  videoId: "re7e6oRmhY0",
};

const onePointPerspective = {
  name: "1pt Perspective",
  label: "5. 1 PT",
  category: "Perspective",
  lessonList: [],
  proTips: ["Here's a video to get you started."],
  proTipsImages: ["video"],
  videoId: "NWikBoNBo54",
};

const twoPointPerspective = {
  name: "2pt Perspective",
  label: "6. 2 PT",
  category: "Perspective",
  lessonList: [],
  proTips: ["Here's a video to get you started."],
  proTipsImages: ["video"],
  videoId: "w7A_vDi8_g4",
};

const combinationsAdd = {
  name: "Additive Combos",
  label: "13. ADDITIVE",
  category: "Combinations",
  lessonList: [
    { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },

    // { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },
    // { type: "additive", variation: "scaffold" },
  ],
  proTips: ["Here's a video to get you started."],
  proTipsImages: ["video"],
  videoId: "tYiMixbMVmM",
};

// const warmup2D_5min = {
//   name: "5 Minute Warm-Up",
//   lessonList: [
//     { type: "line", variation: "horizontal" },
//     { type: "line", variation: "horizontal" },
//     { type: "line", variation: "horizontal" },
//     { type: "line", variation: "horizontal" },
//     { type: "line", variation: "vertical" },
//     { type: "line", variation: "vertical" },
//     { type: "line", variation: "vertical" },
//     { type: "line", variation: "vertical" },
//     { type: "line", variation: "diagonal" },
//     { type: "line", variation: "diagonal" },
//     { type: "line", variation: "diagonal" },
//     { type: "line", variation: "diagonal" },

//     { type: "square", variation: "square" },
//     { type: "square", variation: "square" },
//     { type: "square", variation: "square" },
//     { type: "square", variation: "square" },
//     { type: "square", variation: "rectangle" },
//     { type: "square", variation: "rectangle" },
//     { type: "square", variation: "rectangle" },
//     { type: "square", variation: "rectangle" },

//     { type: "arc", variation: "horizontal" },
//     { type: "arc", variation: "horizontal" },
//     { type: "arc", variation: "horizontal" },
//     { type: "arc", variation: "horizontal" },
//     { type: "arc", variation: "vertical" },
//     { type: "arc", variation: "vertical" },
//     { type: "arc", variation: "vertical" },
//     { type: "arc", variation: "vertical" },

//     { type: "circle", variation: "scaffold" },
//     { type: "circle", variation: "scaffold" },
//     { type: "circle", variation: "scaffold" },
//     { type: "circle", variation: "scaffold" },
//     { type: "circle", variation: "no-scaffold" },
//     { type: "circle", variation: "no-scaffold" },
//     { type: "circle", variation: "no-scaffold" },
//     { type: "circle", variation: "no-scaffold" },
//   ],
//   proTips: [
//     "Warm up on these basic 2D shapes to loosen up your arm and get in to a nice flow state!",
//   ],
//   proTipsImages: ["protip1.png"],
// };

// const warmup_test = {
//   name: "5 Minute Warm-Up",
//   lessonList: [
//     { type: "line", variation: "horizontal" },
//     { type: "square", variation: "square" },
//     { type: "arc", variation: "horizontal" },
//     { type: "circle", variation: "scaffold" },
//   ],
//   proTips: [
//     "Warm up on these basic 2D shapes to loosen up your arm and get in to a nice flow state!",
//   ],
//   proTipsImages: ["protip1.png"],
// };

const lessonData = [
  lineLesson,
  arcLesson,
  circleLesson,
  squareLesson,
  symmetryLesson,
  contourLesson,
  planeLesson,
  ellipseLesson,
  cubeLesson,
  cylinderLesson,
  coneLesson,
  sphereLesson,
  onePointPerspective,
  twoPointPerspective,
  combinationsAdd,
  // warmup2D_5min,
  // warmup_test
];

export default lessonData;
