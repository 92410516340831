import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SphereLesson extends Lesson {
  //Properties
  exp = 8;
  center = new paper.Point(this.canvasWidth / 2, this.canvasHeight / 2);
  squareHeight = 0;
  perfectShape;

  // p1 is closest to viewer (front)
  // p2 is closest to vp1 (left)
  // p3 is closest to vp2 (right)
  // p4 is closest to horizon (back)

  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });

  circle5 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle6 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle7 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });
  circle8 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    fillColor: this.masterCircleColor,
  });

  circle_array = [
    this.circle1,
    this.circle2,
    this.circle3,
    this.circle4,
    this.circle5,
    this.circle6,
    this.circle7,
    this.circle8,
  ];

  exportLessonData = () => {
    return {
      name: "Sphere",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      squareHeight: this.squareHeight,
      perfectShape: this.perfectShape,
      corners: this.corners,
      vp1: this.vp1,
      vp2: this.vp2,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData
    
  }


  generateLesson() {
    let mp1, mp2;
    let checkVisible = false;
    let count = 0;
    //Random size
    let min_dim = Math.min(this.canvasWidth, this.canvasHeight);
    this.squareHeight = min_dim * 0.3 + Math.random() * min_dim * 0.05;
    this.squareWidth = this.squareHeight;
    while (!checkVisible) {
      // Frontmost point
      const x1 = this.canvasWidth / 2;
      let y1 = this.canvasHeight * 0.7;
      if (count > 20) {
        // y1 = this.canvasHeight * 0.5;
        // if (this.horizonHeight > 0) {
        //   y1 = this.canvasHeight * 0.3;
        // }
        this.squareHeight *= 0.9;
        this.squareWidth = this.squareHeight;
      }
      this.circle1.position = new paper.Point(x1, y1);
      this.circle1.fillColor = this.masterCircleColor;

      // Calculate measure points
      [mp1, mp2] = this.findMeasurePoints(x1);

      if (!mp1) {
        // Generated an impossible point in the given perspective
        continue;
      }

      // Define first plane: get center points for circles 2-4
      const [p2, p3, p4] = this.definePlane(x1, y1, mp1, mp2);
      this.circle2.position = p2;
      this.circle2.fillColor = this.masterCircleColor;
      this.circle3.position = p3;
      this.circle3.fillColor = this.masterCircleColor;
      this.circle4.position = p4;
      this.circle4.fillColor = this.masterCircleColor;

      const x5 = x1; // second plane aligns with anchor from first
      const y5 = y1 - this.squareHeight;
      this.circle5.position = new paper.Point(x5, y5);
      this.circle5.fillColor = this.masterCircleColor;

      // Define second plane: get center points for circles 2-4
      const [p6, p7, p8] = this.definePlane(x5, y5, mp1, mp2);
      this.circle6.position = p6;
      this.circle6.fillColor = this.masterCircleColor;
      this.circle7.position = p7;
      this.circle7.fillColor = this.masterCircleColor;
      this.circle8.position = p8;
      this.circle8.fillColor = this.masterCircleColor;

      this.circle_array = [
        this.circle1,
        this.circle2,
        this.circle3,
        this.circle4,
        this.circle5,
        this.circle6,
        this.circle7,
        this.circle8,
      ];

      checkVisible = this.checkPerspectiveVisible(this.circle_array);
      count += 1;

      if (count > 23) {
        console.log("Perspective on edge of screen");
        break;
      }
    }

    if (this.variation === "scaffold") {
      // Make the cube visible
      let _line;
      _line = new paper.Path.Line({
        from: this.circle1.position,
        to: this.circle2.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle1.position,
        to: this.circle3.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle4.position,
        to: this.circle2.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle4.position,
        to: this.circle3.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle5.position,
        to: this.circle6.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle5.position,
        to: this.circle7.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle8.position,
        to: this.circle6.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle8.position,
        to: this.circle7.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle1.position,
        to: this.circle5.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle2.position,
        to: this.circle6.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle3.position,
        to: this.circle7.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      _line = new paper.Path.Line({
        from: this.circle4.position,
        to: this.circle8.position,
        dashArray: [2, 4],
        strokeColor: this.masterCircleColor,
      });

      // Solve for midpoints on front lower plane
      const _mp1measure = new paper.Path.Line({
        from: new paper.Point(
          this.circle1.position.x - this.squareHeight / 2,
          this.circle1.position.y
        ),
        to: mp1,
      });

      // Guideline to determine foreshortening toward vp2
      const _mp2measure = new paper.Path.Line({
        from: new paper.Point(
          this.circle1.position.x + this.squareWidth / 2,
          this.circle1.position.y
        ),
        to: mp2,
      });

      // Determine front-facing sides based on measure points
      let _p2 = _mp1measure.getIntersections(
        paper.Path.Line({
          from: this.circle1.position,
          to: this.vp1,
        })
      )[0].point;

      let _p3 = _mp2measure.getIntersections(
        paper.Path.Line({
          from: this.circle1.position,
          to: this.vp2,
        })
      )[0].point;

      let dotted_line = new paper.Path.Line({
        from: _p2,
        to: this.vp2,
        dashArray: [1, 5],
        strokeColor: "rgb(20, 20, 20, 20)",
      });

      dotted_line = new paper.Path.Line({
        from: _p3,
        to: this.vp1,
        dashArray: [1, 5],
        strokeColor: "rgb(20, 20, 20, 20)",
      });
    }

    // Create sphere based on cube

    // Get vertical midpoints
    const vert1 = new paper.Point(
      this.circle1.position.x,
      (this.circle1.position.y + this.circle5.position.y) / 2
    );
    const vert2 = new paper.Point(
      this.circle2.position.x,
      (this.circle2.position.y + this.circle6.position.y) / 2
    );
    const vert3 = new paper.Point(
      this.circle3.position.x,
      (this.circle3.position.y + this.circle7.position.y) / 2
    );
    const vert4 = new paper.Point(
      this.circle4.position.x,
      (this.circle4.position.y + this.circle8.position.y) / 2
    );

    const [
      center,
      front_left_mid,
      front_right_mid,
      back_left_mid,
      back_right_mid,
      ellipse_center,
      angle,
      major,
      minor,
    ] = this.defineEllipse(
      new paper.Path.Circle({
        center: vert1,
        radius: this.masterCircleSize,
      }),
      new paper.Path.Circle({
        center: vert2,
        radius: this.masterCircleSize,
      }),
      new paper.Path.Circle({
        center: vert3,
        radius: this.masterCircleSize,
      }),
      new paper.Path.Circle({
        center: vert4,
        radius: this.masterCircleSize,
      }),
      -1
    );
    const ellipse = new paper.Path.Ellipse({
      center: [ellipse_center.x, ellipse_center.y],
      radius: [major, minor],
      strokeColor: "#1889d3",
      dashArray: [2, 4],
    });
    ellipse.rotate(angle);

    let plane_center1 = this.solve(
      this.circle1.position,
      this.circle4.position,
      this.circle3.position,
      this.circle2.position
    );
    let plane_center2 = this.solve(
      this.circle5.position,
      this.circle8.position,
      this.circle7.position,
      this.circle6.position
    );

    let vertical_guideline = new paper.Path.Line({
      to: plane_center1,
      from: plane_center2,
      strokeColor: "#1889d3",
      dashArray: [2, 4],
    });

    let cube_center = this.solve(
      this.circle2.position,
      this.circle7.position,
      this.circle3.position,
      this.circle6.position
    );

    const [
      _plane_center,
      plane_front_left_mid,
      plane_front_right_mid,
      plane_back_left_mid,
      plane_back_right_mid,
      plane_ellipse_center,
      plane_angle,
      plane_major,
      plane_minor,
    ] = this.defineEllipse(
      this.circle1,
      this.circle2,
      this.circle3,
      this.circle4,
      -1
    );

    let sphere = new paper.Path.Circle({
      center: [
        // ellipse_center.x, ellipse_center.y
        plane_ellipse_center.x,
        plane_ellipse_center.y - plane_center1.getDistance(cube_center),
        // plane_center1.x,
        // (plane_center1.y + plane_center2.y) / 2,
      ],
      radius: major,
      //opacity: 0.0,
      // strokeColor: "red",
    });
    //sphere.scale(0.9);

    // Keep track of the points that define the plane and the length of the combined path
    this.perfectShape = {
      c1: this.circle1.position,
      c2: this.circle2.position,
      c3: this.circle3.position,
      c4: this.circle4.position,
      c5: this.circle5.position,
      c6: this.circle6.position,
      c7: this.circle7.position,
      c8: this.circle8.position,
      sphere: sphere,
      ellipse: ellipse,
      length: sphere.length,
    };

    this.center = new paper.Point(
      plane_ellipse_center.x,
      plane_ellipse_center.y - plane_center1.getDistance(cube_center)
    );
    this.circleRadius = major;

    this.corners = [
      this.perfectShape.c1,
      this.perfectShape.c2,
      this.perfectShape.c3,
      this.perfectShape.c4,
      this.perfectShape.c5,
      this.perfectShape.c6,
      this.perfectShape.c7,
      this.perfectShape.c8,
    ];

    this.adjacencyList = {
      0: [1, 2, 4],
      1: [0, 3, 5],
      2: [0, 3, 6],
      3: [1, 2, 7],
      4: [5, 6, 0],
      5: [4, 7, 1],
      6: [4, 7, 2],
      7: [5, 6, 3],
    };

    return true;
  }

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }

    //Do the checks
    if (this.isClosedShape(strokes)) {
      // NOTE: could check that shape is within cube
      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }
    return false;
  }

  getPrecision(strokes) {
    let path = this.combinePath(strokes);
    let totalDeviation = this.ellipseError(
      path,
      this.perfectShape.sphere,
      this.showFeedback
    );

    this.deviation_line = this.ellipseDeviation(
      path,
      this.perfectShape.sphere
    );

    let avgDeviation = totalDeviation / this.perfectShape.length;

    return 100 - avgDeviation;
  }
  
  getSmoothness(strokes) {
    let avgAbsAngle =
      this.smoothnessHelper(this.deviation_line) / this.perfectShape.length;
    let normalizedSmoothness = this.normalizeSmoothness(avgAbsAngle);
    return normalizedSmoothness;
  }
}

export default SphereLesson;
