import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LessonProgressBar from "../ProgressBars/LessonProgressBar";
import SketchPadTools from "./SketchPadTools";
import paper from "paper";
import getLessonFromType from "../utils/getLessonFromType";
import SFTCamera from "../../images/challenges/cameraIsometric.png";
import ExerciseReview from "../Lesson/ExerciseReview";

// Note: objects of type Path and Layer need Paper to be setup to be initialized

const SketchCanvas = (props) => {
  // --- Sketch ---
  const lesson = useRef();
  const path = useRef();
  const sketch = useRef([]);

  const [showClear, setShowClear] = useState(false);
  const { index } = props;

  // --- Logs ---
  let timeValues = useRef([]);
  let timeArray = useRef([]);
  let pressureValues = useRef([]);
  let pressureArray = useRef([]);
  let tiltValues = useRef([]);
  let tiltArray = useRef([]);
  let contourPoints = [];
  let pressure = 0.5;
  let tilt = 90;

  // --- Tools ---
  const pencil = new paper.Tool();
  const eraser = new paper.Tool();
  const shapeListener = new paper.Tool();
  const contour = new paper.Tool();
  const symmetry = new paper.Tool();
  const dummyTool = new paper.Tool();
  const [activeTool, setActiveTool] = useState("pencil");

  // --- Layers ---
  const canvasRef = useRef();
  const perspectiveGrid = useRef();
  const constructionLayer = useRef();
  const lineworkLayer = useRef();
  const feedbackLayer = useRef();
  const detailLayer = useRef();

  // --- Reviews ---
  const review1 = useRef();
  const review2 = useRef();
  const review3 = useRef();
  const review4 = useRef();
  const review5 = useRef();
  const review6 = useRef();
  const review7 = useRef();
  const review8 = useRef();
  const reviewCtx = [
    review1,
    review2,
    review3,
    review4,
    review5,
    review6,
    review7,
    review8,
  ];
  const [currentReview, setCurrentReview] = useState(0);

  // --- CanvasCtx ---
  const canvasWidth = useRef(0);
  const canvasHeight = useRef(0);

  const masterCircleColor = useSelector(
    (state) => state.canvas.masterCircleColor
  );
  const masterCircleSize = useSelector(
    (state) => state.canvas.masterCircleSize
  );
  const SFTCircleColor = useSelector((state) => state.canvas.SFTCircleColor);
  const SFTCircleSize = useSelector((state) => state.canvas.SFTCircleSize);
  const initialSketchColor = useSelector((state) => state.canvas.sketchColor);
  const initialSketchSize = useSelector((state) => state.canvas.sketchSize);
  const sketchShadow = useSelector((state) => state.canvas.sketchShadow);
  const sketchShadowBlur = useSelector(
    (state) => state.canvas.sketchShadowBlur
  );
  const sketchShadowOffset = useSelector(
    (state) => state.canvas.sketchShadowOffset
  );
  const deviationThreshold = useSelector(
    (state) => state.canvas.deviationThreshold
  );

  const sketchColor = useRef(initialSketchColor);
  const sketchSize = useRef(initialSketchSize);

  const showFeedback = useSelector((state) => state.login.showFeedback);

  // Helper Boolean for rendering
  // Combinations are on Lesson page, but they use perspective evaluation
  let isLesson =
    props.category !== "challenge" &&
    props.category !== "SFT" &&
    props.category !== "Load" &&
    props.category !== "Combinations";

  // --- Symmetry Brush ---
  let prevX, prevY;
  let dragLength = 0;

  // --- Sand Game ---
  const sandColor = useRef("red");

  const canvasParams = {
    name: props.name,
    variation: props.variation,
    canvasHeight: 0,
    canvasWidth: 0,
    masterCircleColor:
      props.category === "SFT" ? SFTCircleColor : masterCircleColor,
    masterCircleSize:
      props.category === "SFT" ? SFTCircleSize : masterCircleSize,
    sketchColor: sketchColor.current,
    sketchSize: sketchSize.current,
    deviationThreshold,
    showFeedback,
  };

  let canvasId = "sketchCanvas";
  let canvasBackground = "active lesson"; // className for Lesson
  if (props.category === "challenge") {
    canvasBackground = "active " + props.name; // className for Challenge
  } else if (props.category === "SFT") {
    canvasId = "sketchCanvasSFT";
    if (props.name === "SFTCamera") {
      canvasBackground = "active camera"; // unique className for SFTCamera
    } else {
      canvasBackground = "active";
    }
  } else if (props.category === "Load") {
    canvasBackground = "active";
  } else if (props.category === "Combinations") {
    // Unique type of Lesson
    canvasBackground = "active";
  }

  // --- Perspective ---
  const perspectiveHelp = useRef([]);
  const perspectiveGridMode = useRef("none");
  const evalMode = useRef(0);

  const vp1 = useRef();
  const vp2 = useRef();
  const horizonHeight = useRef(0);

  // --- Next Button ---
  const [nextDisabled, setNextDisabled] = useState(true);

  // #####################################################################

  //console.log("Lesson Status (", props.name, "):", lesson.current);

  const lessonStatus = lesson.current == null;
  //console.log("Lesson Status Check:", lessonStatus);
  //const lessonStatus = true;

  useEffect(() => {
    //console.log("New Canvas");
    // Associate Paper JS with Canvas
    paper.setup(canvasRef.current);

    // Make note of canvas size
    canvasHeight.current = paper.view.size.height;
    canvasWidth.current =
      paper.view.size.width * (canvasBackground === "active lesson" ? 0.7 : 1);

    // Initialize Layers
    constructionLayer.current = new paper.Layer({ name: "construction" });
    lineworkLayer.current = new paper.Layer({ name: "linework" });
    perspectiveGrid.current = new paper.Layer({ name: "perspective" });
    feedbackLayer.current = new paper.Layer({ name: "feedback" });
    detailLayer.current = new paper.Layer({ name: "detail" });

    if (props.category === "challenge") {
      updateTool("pencil");
    }

    // Initialize perspective if needed
    if (props.name === "Planes" || props.category === "Combinations") {
      clearPerspectiveGrid();
      toggleTwoPointPerspectiveHandler(); // Fix the vanishing points
    }
    if (props.category === "Primitives" || props.name === "Ellipses") {
      clearPerspectiveGrid();
      toggleTwoPointPerspective(); // Randomize vanishing points
    }
    if (props.category === "SFT") {
      toggleTwoPointPerspectiveHandler(); // make sure vanishing points are initialized
      toggleTwoPointPerspectiveHandler(); // turn grid back off
    }

    // Add perspective params
    canvasParams.canvasHeight = canvasHeight.current;
    canvasParams.canvasWidth = canvasWidth.current;
    canvasParams.vp1 = vp1.current;
    canvasParams.vp2 = vp2.current;
    canvasParams.horizonHeight = horizonHeight.current;

    // Initialize lesson object
    lesson.current = getLessonFromType(props.name, canvasParams);

    if (props.category === "Load") {
      lesson.current.importLessonData(props.lessonData);
      lesson.current.importSketchData(props.sketchData);
    }

    const refresh_canvas = setTimeout(() => {
      if (props.category !== "challenge" && props.category !== "Combinations") {
        shapeListener.activate();
        updateTool("shapeListener");
        if (props.name === "Contours") {
          if (props.variation === "visible") {
            grid();
          }
        }
        constructionLayer.current.activate();
        lesson.current.generateLesson();
        if (props.name === "SFTCube") {
          // || props.name === "SFTCamera") {
          perspectiveGrid.current.activate();
          lesson.current.plotTwoPointVP(); // plot just the VP
          constructionLayer.current.activate();
        }
      }

      // if (props.category === "Load") {
      //   dummyTool.activate();
      // }
      paper.view.update();
    }, 20);

    // Ensure Next Button is enabled after delay
    const reveal_next = setTimeout(() => {
      setNextDisabled(false);
    }, 1000);

    return () => {
      clearTimeout(refresh_canvas);
      clearTimeout(reveal_next);
    };
  }, [index, lessonStatus]);

  const clearSketchHelper = () => {
    sketch.current = [];
    timeValues.current = [];
    timeArray.current = [];
    pressureValues.current = [];
    pressureArray.current = [];
  };

  // ################################################################

  const saveSketchData = (metrics) => {
    props.sketchDataHandler(sketch.current);
    props.lessonDataHandler(lesson.current.exportLessonData());
    props.timeValuesHandler(timeValues.current);
    props.pressureValuesHandler(pressureValues.current);
    props.tiltValuesHandler(tiltValues.current);
    props.metricsHandler(metrics);
  };

  const copyToReview = () => {
    // Copy sketch to review
    if (isLesson) {
      reviewCtx[currentReview].current
        .getContext("2d")
        .drawImage(
          canvasRef.current,
          0,
          0,
          canvasWidth.current,
          canvasHeight.current,
          0,
          0,
          reviewCtx[currentReview].current.width,
          reviewCtx[currentReview].current.height
        );
      setCurrentReview((prev) => prev + 1);
    }
  };

  const recognizeHandler = (metrics) => {
    //console.log("RECOGNIZEHANDLER:", metrics);
    if (metrics) {
      // Metrics were returned from evaluateSketch

      // Save the results
      saveSketchData(metrics);

      // Copy sketch to review
      setTimeout(() => {
        copyToReview();
      }, 200);

      setTimeout(() => {
        clearSketchHelper();
        setShowClear(false);
      }, 200);

      // Timeout to give the animation time to display
      setTimeout(() => {
        constructionLayer.current.removeChildren();
        lineworkLayer.current.removeChildren();
        props.nextLesson();
      }, 1500);
    } else {
      // Nothing was returned
      setShowClear(true);
      setNextDisabled(false);
    }
  };

  const nextHandler = () => {
    // Evaluate sketch and send metrics back to LessonManager
    setNextDisabled(true);

    // Evaluate perspective if applicable
    if (props.category === "Combinations") {
      evaluatePerspectiveHandler();
    }

    // Copy sketch to review
    copyToReview();

    // Calculate metrics and send to LessonManager
    if (props.category === "SFT") {
      lesson.current.processSketch(sketch.current);
    }
    const metrics = lesson.current.evaluateSketch(sketch.current);
    saveSketchData(metrics);
    // Clear the canvas and go to next exercise

    let timeoutTime = props.category === "SFT" ? 200 : 1500;

    clearSketchHelper();
    setShowClear(false);
    setTimeout(() => {
      constructionLayer.current.removeChildren();
      lineworkLayer.current.removeChildren();
      props.nextLesson();
    }, timeoutTime);
  };

  // ################################################################

  //Annotation brush
  pencil.onMouseDown = (event) => {
    path.current = new paper.Path();
    path.current.strokeColor = sketchColor.current;
    path.current.strokeWidth = sketchSize.current;
    path.current.strokeCap = "round";
    path.current.opacity = Math.max(pressure, 0.5);
  };

  pencil.onMouseDrag = (event) => {
    path.current.add(event.point);

    let hits = paper.project.hitTestAll(event.point, {
      segments: true,
      fill: true,
      class: paper.Path,
      tolerance: 5,
      stroke: true,
    });

    if (hits.length) {
      for (var i = 0; i < hits.length; i++) {
        let hit = hits[i];
        //console.log(hit);
        if (hit.item.layer === constructionLayer.current) {
          sketch.current.splice(sketch.current.indexOf(hit.item), 1);
          hit.item.remove();
        }
      }
    }
  };

  pencil.onMouseUp = (event) => {
    //path.current.simplify();
    path.current.smooth();
    if (path.current.segments.length !== 0) {
      sketch.current.push(path.current);
    }
  };

  const tiltHandler = (event) => {
    //Collect pressure and tilt data during sketching events
    let _pressure = event.pressure;
    let tiltX = event.tiltX;
    let tiltY = event.tiltY;

    //Compute actual tilt
    let radianTiltX = tiltX * (Math.PI / 180);
    let radianTiltY = tiltY * (Math.PI / 180);
    let tanTiltX = Math.tan(radianTiltX);
    let tanTiltY = Math.tan(radianTiltY);

    let _tilt = 90; // default
    if (tanTiltX !== 0 && tanTiltY !== 0) {
      let aziX = Math.atan(tanTiltY / tanTiltX);
      _tilt = Math.abs(Math.atan(Math.sin(aziX) / tanTiltY)) * (180 / Math.PI);
    }

    // Add logs
    //console.log("tiltHandler:", _pressure, _tilt, "(", pressure, tilt, ")");
    pressure = _pressure;
    tilt = _tilt;
  };

  // #######################################################

  function clearSketch() {
    lineworkLayer.current.removeChildren();
    if (path.current) {
      path.current.remove();
    }
    clearSketchHelper();
    setShowClear(false);
  }

  contour.onMouseUp = function (event) {
    contourPoints.push([event.point.x, event.point.y]);
    console.log(contourPoints);
  };

  // #######################################################

  const erase = (event) => {
    let hits = paper.project.hitTestAll(event.point, {
      segments: true,
      fill: true,
      class: paper.Path,
      tolerance: 5,
      stroke: true,
    });

    if (hits.length) {
      for (var i = 0; i < hits.length; i++) {
        let hit = hits[i];
        if (
          hit.item.layer !== perspectiveGrid.current &&
          hit.item.layer !== constructionLayer.current
        ) {
          sketch.current.splice(sketch.current.indexOf(hit.item), 1);
          hit.item.remove();
        }
      }
    }
  };

  //Eraser
  eraser.onMouseDown = erase;
  eraser.onMouseDrag = erase;

  // #######################################################

  //Lesson brush
  shapeListener.onMouseDown = (event) => {
    lineworkLayer.current.activate();
    path.current = new paper.Path();
    path.current.strokeColor = sketchColor.current;
    path.current.strokeWidth = sketchSize.current;
    path.current.strokeCap = "round";
    path.current.opacity = Math.max(pressure, 0.5);
    //console.log("path =", path);

    // Originally cleared timeValues, pressureValues
    timeArray.current = [];
    pressureArray.current = [];
    tiltArray.current = [];
  };

  shapeListener.onMouseDrag = function (event) {
    path.current.add(event.point);

    //timeValues.current.push(+new Date());
    timeArray.current.push(+new Date());
    if (pressure !== undefined) {
      //pressureValues.current.push(pressure);
      pressureArray.current.push(pressure);
    }

    tiltArray.current.push(tilt);
  };

  shapeListener.onMouseUp = function (event) {
    //path.current.simplify();
    path.current.smooth();

    //Add path to sketch
    if (path.current.segments.length !== 0) {
      sketch.current.push(path.current);
      timeValues.current.push(timeArray.current);
      pressureValues.current.push(pressureArray.current);
      tiltValues.current.push(tiltArray.current);
    }

    //Make sure shape is complete
    lesson.current.timeValues = timeValues.current;
    lesson.current.pressureValues = pressureValues.current;
    if (isLesson) {
      recognizeHandler(lesson.current.recognize(sketch.current));
    }
  };

  // #######################################################

  //Symmetry brush
  symmetry.onMouseDown = function (event) {
    prevX = event.point.x;
    prevY = event.point.y;
    dragLength = 0;
  };

  symmetry.onMouseDrag = function (event) {
    dragLength++;
    let x = event.point.x;
    let y = event.point.y;
    if (dragLength > 1) {
      drawLine(prevX, prevY, x, y);
      drawLine(
        canvasHeight - prevX,
        canvasHeight - prevY,
        canvasHeight - x,
        canvasHeight - y
      );
      drawLine(canvasHeight - prevX, prevY, canvasHeight - x, y);
      drawLine(prevX, canvasHeight - prevY, x, canvasHeight - y);
      drawLine(prevY, prevX, y, x);
      drawLine(
        canvasHeight - prevY,
        canvasHeight - prevX,
        canvasHeight - y,
        canvasHeight - x
      );
      drawLine(canvasHeight - prevY, prevX, canvasHeight - y, x);
      drawLine(prevY, canvasHeight - prevX, y, canvasHeight - x);
    }
    prevX = x;
    prevY = y;
  };

  symmetry.onMouseUp = function (event) {
    dragLength = 0;
  };

  // --- Sand Game ---
  const drawLine = (x1, y1, x2, y2) => {
    path.current = new paper.Path();
    path.current.strokeWidth = 0;
    let point1 = new paper.Point(x1, y1);
    let point2 = new paper.Point(x2, y2);
    path.current.add(point1);
    path.current.add(point2);

    //Compute stroke length
    var xDist =
      path.current.segments[path.current.segments.length - 1].point.x -
      path.current.segments[0].point.x;
    var yDist =
      path.current.segments[path.current.segments.length - 1].point.y -
      path.current.segments[0].point.y;
    var length = Math.sqrt(Math.pow(xDist, 2) + Math.pow(yDist, 2));

    var middlePoint = new paper.Point(
      path.current.segments[Math.floor(path.segments.length / 2)].point.x,
      path.current.segments[Math.floor(path.segments.length / 2)].point.y
    );

    let raster = new paper.Raster(sandColor.current + "Sand");
    raster.position = middlePoint;
    raster.size = new paper.Size(25, 25);
    raster.rotate(360 * Math.random());
    raster.opacity = pressure;
  };

  const updateColor = (color) => {
    sandColor.current = color;
  };

  // #####################################################################

  // Update Tool
  function updateTool(tool) {
    if (tool === "eraser") {
      setActiveTool("eraser");
      sketchColor.current = masterCircleColor;
      sketchSize.current = 2;
      eraser.activate();
    } else if (tool === "bluepencil") {
      pencil.activate();
      setActiveTool("bluepencil");
      sketchColor.current = masterCircleColor;
      sketchSize.current = 2;
      constructionLayer.current.activate();
    } else if (tool === "pencil") {
      setActiveTool("pencil");
      pencil.activate();
      sketchColor.current = "#444444";
      sketchSize.current = 2;
      lineworkLayer.current.activate();
    } else if (tool === "shapeListener") {
      setActiveTool("shapeListener");
      shapeListener.activate();
      sketchColor.current = initialSketchColor;
      sketchSize.current = initialSketchSize;
      constructionLayer.current.activate();
    } else if (tool === "markerfine") {
      setActiveTool("markerfine");
      pencil.activate();
      sketchColor.current = "#000";
      sketchSize.current = 4;
      lineworkLayer.current.activate();
    } else if (tool === "marker") {
      setActiveTool("marker");
      pencil.activate();
      sketchColor.current = "#000";
      sketchSize.current = 8;
      lineworkLayer.current.activate();
    } else if (tool === "marker20") {
      setActiveTool("marker20");
      pencil.activate();
      sketchColor.current = "rgba(0,0,0,0.2)";
      sketchSize.current = 16;
      detailLayer.current.activate();
    } else if (tool === "marker40") {
      setActiveTool("marker40");
      pencil.activate();
      sketchColor.current = "rgba(0,0,0,0.4)";
      sketchSize.current = 16;
      detailLayer.current.activate();
    } else if (tool === "marker60") {
      setActiveTool("marker60");
      pencil.activate();
      sketchColor.current = "rgba(0,0,0,0.6)";
      sketchSize.current = 16;
      detailLayer.current.activate();
    } else if (tool === "marker80") {
      setActiveTool("marker80");
      pencil.activate();
      sketchColor.current = "rgba(0,0,0,0.8)";
      sketchSize.current = 16;
      detailLayer.current.activate();
    } else if (tool === "marker100") {
      setActiveTool("marker100");
      pencil.activate();
      sketchColor.current = "rgba(0,0,0,1)";
      sketchSize.current = 16;
      detailLayer.current.activate();
    }
  }

  const clearPerspectiveGrid = () => {
    perspectiveGridMode.current = "none";
    perspectiveGrid.current.removeChildren();
    constructionLayer.current.activate();
  };

  const toggleGrid = () => {
    perspectiveGrid.current.removeChildren();
    if (perspectiveGridMode.current !== "grid") {
      perspectiveGridMode.current = "grid";
      perspectiveGrid.current.activate();
      grid();
      constructionLayer.current.activate();
    } else {
      clearPerspectiveGrid();
    }
    updateTool(activeTool);
  };

  const toggleOnePointPerspective = () => {
    perspectiveGrid.current.removeChildren();
    if (perspectiveGridMode.current !== "onePoint") {
      perspectiveGridMode.current = "onePoint";
      perspectiveGrid.current.activate();
      onePointPerspective();
      constructionLayer.current.activate();
    } else {
      clearPerspectiveGrid();
    }
    updateTool(activeTool);
  };

  const toggleTwoPointPerspective = () => {
    perspectiveGrid.current.removeChildren();
    if (perspectiveGridMode.current !== "twoPoint") {
      perspectiveGridMode.current = "twoPoint";
      perspectiveGrid.current.activate();
      twoPointPerspective();
      constructionLayer.current.activate();
    } else {
      clearPerspectiveGrid();
    }
    updateTool(activeTool);
  };

  const toggleTwoPointPerspectiveHandler = () => {
    perspectiveGrid.current.removeChildren();
    if (perspectiveGridMode.current !== "twoPoint") {
      perspectiveGridMode.current = "twoPoint";
      perspectiveGrid.current.activate();
      twoPointPerspective(true);
      constructionLayer.current.activate();
    } else {
      clearPerspectiveGrid();
    }
    updateTool(activeTool);
  };

  const grid = () => {
    //Grid size
    let gridSize = canvasWidth.current / 10;

    // Grid
    var vertLine = new paper.Path.Line({
      from: [0, 0],
      to: [0, canvasHeight.current],
    });
    vertLine.strokeColor = masterCircleColor;
    vertLine.opacity = 0.3;

    var horizLine = new paper.Path.Line({
      from: [0, 0],
      to: [canvasWidth.current, 0],
    });
    horizLine.strokeColor = masterCircleColor;
    horizLine.opacity = 0.3;

    //Generate grid
    for (let i = gridSize; i < canvasWidth.current; i = i + gridSize) {
      let nextGridLine = vertLine.clone();
      nextGridLine.firstSegment.point.x = i;
      nextGridLine.firstSegment.point.y = 0;
      nextGridLine.lastSegment.point.x = i;
      nextGridLine.lastSegment.point.y = canvasHeight.current;

      nextGridLine = horizLine.clone();
      nextGridLine.firstSegment.point.x = 0;
      nextGridLine.firstSegment.point.y = i;
      nextGridLine.lastSegment.point.x = canvasWidth.current;
      nextGridLine.lastSegment.point.y = i;
    }
  };

  const onePointPerspective = () => {
    // Grid Density
    let d = 2;

    // Vertical Density
    let vd = 60;

    // Vanishing point
    vp1.current = new paper.Point(
      canvasWidth.current / 2,
      canvasHeight.current / 3
    );
    let circle1 = new paper.Path.Circle(vp1.current, 7);
    circle1.strokeColor = masterCircleColor;
    circle1.fillColor = masterCircleColor;

    // Horizon
    let leftedge = new paper.Point(0, canvasHeight.current / 3);
    let rightedge = new paper.Point(
      canvasWidth.current,
      canvasHeight.current / 3
    );

    // Sky
    // let topLeft = new paper.Point(0, 0);
    // let sky = new paper.Path.Rectangle(topLeft, rightedge);
    // sky.fillColor = masterCircleColor;
    // sky.opacity = 0.3;

    // Perspective Grid
    let gridLine = new paper.Path.Line(vp1.current, rightedge);
    let gridLine2 = new paper.Path.Line(vp1.current, leftedge);
    gridLine.strokeColor = masterCircleColor;
    gridLine.opacity = 0.3;
    gridLine2.strokeColor = masterCircleColor;
    gridLine2.opacity = 0.3;
    for (let i = d; i < 90; i = Math.pow(i, 1.2)) {
      let nextGridLine = gridLine.clone();
      nextGridLine.rotate(i, vp1.current);
      i *= -1;
      let nextGridLine2 = gridLine2.clone();
      nextGridLine2.rotate(i, vp1.current);
      i *= -1;
    }

    // Horizontals
    let horizontal = new paper.Path.Line(leftedge, rightedge);
    for (let i = 1; i < 1000; i = i) {
      var nextHorizontal = horizontal.clone();
      nextHorizontal.strokeColor = masterCircleColor;
      nextHorizontal.opacity = 0.3;
      nextHorizontal.position.y += i;
      if (i < 4) {
        i += 1;
      } else {
        i = Math.pow(i, 1.05);
      }
    }
  };

  const twoPointPerspective = (useDefault = false) => {
    //Grid Density
    let d = 1000;

    // Vertical Density
    let vd = 50;

    if (useDefault) {
      // Put vanishing points near the left and rights edges of the screen at the vertical midpoint
      horizonHeight.current = canvasHeight.current / 2;
      if (props.category === "Combinations") {
        horizonHeight.current = canvasHeight.current / 4;
      }
      vp1.current = new paper.Point(
        canvasWidth.current / 10,
        horizonHeight.current
      );
      vp2.current = new paper.Point(
        canvasWidth.current / 1.1,
        horizonHeight.current
      );
    } else {
      // Put the vanishing points
      let rand = Math.random();

      // OLD VERSION
      // if (rand < 0.4) {
      //   horizonHeight.current = -canvasHeight.current / 10;
      // } else if (rand < 0.6) {
      //   horizonHeight.current = canvasHeight.current / 2;
      // } else {
      //   horizonHeight.current = 0.9 * canvasHeight.current;
      // }

      if (rand < 0.6) {
        horizonHeight.current =
          -canvasHeight.current + Math.random() * 1.2 * canvasHeight.current;
      } else {
        horizonHeight.current =
          2 * canvasHeight.current - Math.random() * 1.2 * canvasHeight.current;
      }

      let left_bound =
        -2 * canvasWidth.current + Math.random() * 2 * canvasWidth.current;
      vp1.current = new paper.Point(left_bound, horizonHeight.current);
      vp2.current = new paper.Point(
        left_bound + 3 * canvasWidth.current,
        horizonHeight.current
      );

      // OLD VERSION
      // vp1.current = new paper.Point(
      //   (1 * canvasWidth.current) / 5 -
      //     (Math.random() * canvasWidth.current) / 2,
      //   horizonHeight.current
      // );
      // vp2.current = new paper.Point(
      //   (4 * canvasWidth.current) / 5 +
      //     (Math.random() * canvasWidth.current) / 2,
      //   horizonHeight.current
      // );
      //console.log("vp1, vp2", vp1.current, vp2.current);
    }

    // Vanishing points
    let circle1 = new paper.Path.Circle(vp1.current, 7);
    circle1.fillColor = masterCircleColor;
    circle1.opacity = 0.8;

    let circle2 = new paper.Path.Circle(vp2.current, 7);
    circle2.fillColor = masterCircleColor;
    circle2.opacity = 0.8;

    // Horizon
    let leftedge = new paper.Point(0, horizonHeight.current);
    let rightedge = new paper.Point(canvasWidth.current, horizonHeight.current);
    let horizon = new paper.Path.Line(leftedge, rightedge);
    horizon.strokeColor = masterCircleColor;
    horizon.strokeWidth = "2";
    horizon.opacity = 0.3;

    // Sky
    // let topLeft = new paper.Point(0, 0);
    // let sky = new paper.Path.Rectangle(topLeft, rightedge);
    // sky.fillColor = masterCircleColor;
    // sky.opacity = 0.3;

    // Perspective Grid
    let initial1 = new paper.Point(0, canvasHeight.current);
    let initial2 = new paper.Point(canvasWidth.current, canvasHeight.current);
    let initial3 = new paper.Point(0, 0);
    let initial4 = new paper.Point(canvasWidth.current, 0);

    // let gridLine1 = new paper.Path.Line(vp1.current, initial1);
    // let gridLine2 = new paper.Path.Line(vp2.current, initial2);
    // // let gridLine3 = new paper.Path.Line(vp1.current, initial3);
    // // let gridLine4 = new paper.Path.Line(vp2.current, initial4);
    // gridLine1.strokeColor = masterCircleColor;
    // gridLine1.opacity = 0.3;
    // gridLine2.strokeColor = masterCircleColor;
    // gridLine2.opacity = 0.3;
    // // gridLine3.strokeColor = masterCircleColor;
    // // gridLine3.opacity = 0.3;
    // // gridLine4.strokeColor = masterCircleColor;
    // // gridLine4.opacity = 0.3;

    // Below Horizon
    for (let i = 0; i < canvasWidth.current * 20; i = i + d) {
      let nextGridLine1 = new paper.Path.Line(vp1.current, initial1);
      nextGridLine1.strokeColor = masterCircleColor;
      nextGridLine1.opacity = 0.3;
      initial1.x += d;

      let nextGridLine2 = new paper.Path.Line(vp2.current, initial2);
      nextGridLine2.strokeColor = masterCircleColor;
      nextGridLine2.opacity = 0.2;
      initial2.x -= d;
    }

    // Above Horizon
    for (let i = 0; i < canvasHeight.current * 20; i = i + d) {
      let nextGridLine3 = new paper.Path.Line(vp2.current, initial3);
      nextGridLine3.strokeColor = masterCircleColor;
      nextGridLine3.opacity = 0.3;
      initial3.x -= d;

      let nextGridLine4 = new paper.Path.Line(vp1.current, initial4);
      nextGridLine4.strokeColor = masterCircleColor;
      nextGridLine4.opacity = 0.3;
      initial4.x += d;
    }
  };

  const getAngleDegrees = (stroke) => {
    let adj = stroke.getPointAt(stroke.length).x - stroke.getPointAt(0).x;
    let opp = stroke.getPointAt(0).y - stroke.getPointAt(stroke.length).y;
    let result = Math.atan2(adj, opp) * (180 / Math.PI);
    return result;
  };

  const evaluatePerspectiveHandler = () => {
    evaluatePerspective(sketch.current);
  };

  const evaluatePerspective = (drawing) => {
    let angle = 0;
    let perspectiveAngle = 0;
    let angleDiff = 0;

    if (evalMode.current === 0) {
      for (let i = 0; i < drawing.length; i++) {
        // Consider straightened path
        let startpoint = drawing[i].firstSegment.point;
        let endpoint = drawing[i].lastSegment.point;

        // Consider which direction the stroke started from
        let currentStroke = drawing[i];
        let straightPath;
        let straightZero;
        let straightEnd;
        if (startpoint.y > endpoint.y) {
          straightPath = new paper.Path.Line(startpoint, endpoint);
          straightZero = startpoint;
          straightEnd = endpoint;
        } else {
          straightPath = new paper.Path.Line(endpoint, startpoint);
          straightZero = endpoint;
          straightEnd = startpoint;
        }

        angle = getAngleDegrees(straightPath);

        // Is it vertical? Not important
        if (angle < 10 && angle > -10) {
          currentStroke.strokeColor = "#42dcb6";
          currentStroke.strokeWidth = "3";
        }

        //Is it going to left VP?
        else if (angle <= 0) {
          let perspectivePath = new paper.Path.Line(straightZero, vp1.current);
          perspectiveHelp.current.push(perspectivePath);
          perspectiveAngle = getAngleDegrees(perspectivePath);
          angleDiff = Math.abs(perspectiveAngle - angle);

          if (angleDiff > 3) {
            currentStroke.strokeColor = "red";
            currentStroke.strokeWidth = "3";
            perspectivePath.strokeColor = "#42dcb6";
            let xDiff = Math.abs(straightZero.x - straightEnd.x);
            let yDiff = Math.abs(straightZero.y - straightEnd.y);
            let newX = straightEnd.x - xDiff * 3;
            let newY = straightEnd.y - yDiff * 3;
            let newPoint = new paper.Point(newX, newY);
            let helperPath = new paper.Path.Line(straightEnd, newPoint);
            helperPath.strokeColor = "red";
            perspectiveHelp.current.push(helperPath);
          } else {
            currentStroke.strokeColor = "#42dcb6";
            currentStroke.strokeWidth = "3";
            perspectivePath.strokeColor = "#42dcb6";
          }
        }
        //Must be going to right VP
        else {
          let perspectivePath = new paper.Path.Line(straightZero, vp2.current);
          perspectiveHelp.current.push(perspectivePath);
          perspectiveAngle = getAngleDegrees(perspectivePath);
          angleDiff = Math.abs(perspectiveAngle - angle);

          if (angleDiff > 3) {
            currentStroke.strokeColor = "red";
            currentStroke.strokeWidth = "3";
            perspectivePath.strokeColor = "#42dcb6";
            let xDiff = Math.abs(straightZero.x - straightEnd.x);
            let yDiff = Math.abs(straightZero.y - straightEnd.y);
            let newX = straightEnd.x + xDiff * 3;
            let newY = straightEnd.y - yDiff * 3;
            let newPoint = new paper.Point(newX, newY);
            let helperPath = new paper.Path.Line(straightEnd, newPoint);
            helperPath.strokeColor = "red";
            perspectiveHelp.current.push(helperPath);
          } else {
            currentStroke.strokeColor = "#42dcb6";
            currentStroke.strokeWidth = "3";
            perspectivePath.strokeColor = "#42dcb6";
          }
        }
      }
      evalMode.current = 1;
    } else {
      for (let i = 0; i < drawing.length; i++) {
        drawing[i].strokeColor = "black";
        drawing[i].strokeWidth = "2";
      }
      for (let i = 0; i < perspectiveHelp.current.length; i++) {
        perspectiveHelp.current[i].remove();
      }
      perspectiveHelp.current = [];
      evalMode.current = 0;
    }
  };

  return (
    <React.Fragment>
      {isLesson && (
        <LessonProgressBar index={props.index} numLessons={props.numLessons} />
      )}

      {(props.category === "challenge" ||
        props.category === "SFT" ||
        props.category === "Combinations") && (
        <SketchPadTools
          gridToggle={toggleGrid}
          onePointToggle={toggleOnePointPerspective}
          twoPointToggle={toggleTwoPointPerspectiveHandler}
          activeTool={activeTool}
          updateTool={updateTool}
          eraserOnly={
            props.category === "SFT" || props.category === "Combinations"
          }
        />
      )}

      {isLesson && (
        <div className="leftpane">
          <div className="lessonsidecontainer">
            <div className="row review">
              <canvas className="exerciseReview" ref={review1} />
              <canvas className="exerciseReview" ref={review2} />
            </div>
            <div className="row review">
              <canvas className="exerciseReview" ref={review3} />
              <canvas className="exerciseReview" ref={review4} />
            </div>
            <div className="row review">
              <canvas className="exerciseReview" ref={review5} />
              <canvas className="exerciseReview" ref={review6} />
            </div>
            <div className="row review">
              <canvas className="exerciseReview" ref={review7} />
              <canvas className="exerciseReview" ref={review8} />
            </div>
          </div>
        </div>
      )}

      <canvas
        id={canvasId}
        ref={canvasRef}
        hidpi="off"
        onPointerDown={tiltHandler}
        className={canvasBackground}
      />

      {(showClear ||
        props.category === "challenge" ||
        props.category === "SFT" ||
        props.category === "Combinations") && (
        <div
          id="clearSketch"
          onClick={clearSketch}
          style={{
            display: "block",
            position: "absolute",
            top: "90%",
            left: "0%",
            marginLeft: "5%",
          }}
        >
          Clear sketch
        </div>
      )}

      {((showClear && isLesson) || props.category === "Combinations") && (
        <a
          className="waves-effect waves-light btn"
          onClick={nextHandler}
          disabled={nextDisabled}
          style={{
            position: "absolute",
            top: "90%",
            left: "80%",
            marginLeft: "5%",
          }}
        >
          Next
        </a>
      )}

      {props.category === "challenge" && (
        <a
          className="waves-effect waves-light btn"
          onClick={evaluatePerspectiveHandler}
          style={{
            position: "absolute",
            top: "90%",
            left: "70%",
            marginLeft: "5%",
          }}
        >
          Evaluate
        </a>
      )}

      {props.category === "SFT" && (
        <a
          className="waves-effect waves-light btn"
          onClick={nextHandler}
          disabled={nextDisabled}
          style={{
            position: "absolute",
            top: "90%",
            left: "80%",
            marginLeft: "5%",
          }}
        >
          Next
        </a>
      )}

      <div
        style={{
          position: "absolute",
          top: "90%",
          left: "90%",
          marginLeft: "5%",
        }}
      ></div>
    </React.Fragment>
  );
};

export default SketchCanvas;
