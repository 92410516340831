import Lesson from "../Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class EllipseLesson extends Lesson {
  //Properties
  exp = 8;
  center = new paper.Point(this.canvasWidth / 2, this.canvasHeight / 2);
  squareHeight = 0;
  squareWidth = 0;
  perfectShape;

  // p1 is closest to viewer (front)
  // p2 is closest to vp1 (left)
  // p3 is closest to vp2 (right)
  // p4 is closest to horizon (back)

  circle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  circle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  circle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  circle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });

  // Points that define the plane containing the ellipse
  planeCircle1 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  planeCircle2 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  planeCircle3 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });
  planeCircle4 = new paper.Path.Circle({
    center: [0, 0],
    radius: this.masterCircleSize,
    //fillColor: this.masterCircleColor,
  });

  exportLessonData = () => {
    return {
      name: "Ellipses",
      variation: this.variation,
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
      center: this.center,
      perfectShape: this.perfectShape,
      vp1: this.vp1,
      vp2: this.vp2,
    };
  };

  loadPrompt = () => {
    // Get perfectShape from the loaded LessonData

    // Set vp1, vp2
    this.vp1 = this.recoverPoint(this.lessonData.vp1);
    this.vp2 = this.recoverPoint(this.lessonData.vp2);

    // perfectShape is a paper.js Ellipse
    const [ellipse, center] = this.recoverEllipse(
      this.lessonData.perfectShape,
      this.lessonData.center
    );

    this.perfectShape = ellipse;
    this.center = center;
  };

  generateLesson() {
    //Random size
    let min_dim = Math.min(this.canvasWidth, this.canvasHeight);
    this.squareHeight = min_dim * 0.5 + Math.random() * min_dim * 0.2;
    this.squareWidth = this.squareHeight;

    // Frontmost point
    let width = Math.min(this.vp2.x - this.vp1.x, this.canvasWidth);
    let left_x = Math.max(this.vp1.x, this.canvasWidth / 8);
    // const x1 = left_x + Math.random() * width * 0.8;
    // const y1 = this.canvasHeight / 5 + Math.random() * this.canvasHeight * 0.6;
    const x1 = this.canvasWidth / 2;
    let y1 = this.canvasHeight * 0.7;
    if (this.horizonHeight > 0) {
      y1 = this.canvasHeight * 0.3;
    }

    this.circle1.position = new paper.Point(x1, y1);
    //this.circle1.fillColor = this.masterCircleColor;

    // Calculate measure points
    const [mp1, mp2] = this.findMeasurePoints(x1);

    // Define first plane: get center points for circles 2-4
    const [p2, p3, p4] = this.definePlane(x1, y1, mp1, mp2);
    this.circle2.position = p2;
    this.circle3.position = p3;
    this.circle4.position = p4;

    // Keep track of the plane
    this.planeCircle1.center = new paper.Point(x1, y1);
    this.planeCircle2.center = p2;
    this.planeCircle3.center = p3;
    this.planeCircle4.center = p4;

    const [
      center,
      front_left_mid,
      front_right_mid,
      back_left_mid,
      back_right_mid,
      ellipse_center,
      angle,
      major,
      minor,
    ] = this.defineEllipse(
      this.circle1,
      this.circle2,
      this.circle3,
      this.circle4,
      1
    );

    // Move the points from the plane corners to the ellipse points
    this.circle1 = front_left_mid;
    this.circle2 = back_left_mid;
    this.circle3 = front_right_mid;
    this.circle4 = back_right_mid;

    this.perfectShape = new paper.Path.Ellipse({
      center: [ellipse_center.x, ellipse_center.y],
      radius: [major, minor],
      //strokeColor: "red",
    });
    this.perfectShape.rotate(angle);
    this.center = center;

    // Draw guidelines to the center
    let vp1c1 = new paper.Path.Line({
      from: [this.vp2.x, this.vp2.y],
      to: [front_left_mid.position.x, front_left_mid.position.y],
      dashArray: [2, 4],
      strokeColor: this.sketchColor,
    });

    let vp2c1 = new paper.Path.Line({
      from: [this.vp1.x, this.vp1.y],
      to: [front_right_mid.position.x, front_right_mid.position.y],
      dashArray: [2, 4],
      strokeColor: this.sketchColor,
    });

    if (this.variation === "scaffold") {
      // Add points along plane definition lines
      this.circle1.strokeColor = "#1889d3";
      this.circle2.strokeColor = "#1889d3";
      this.circle3.strokeColor = "#1889d3";
      this.circle4.strokeColor = "#1889d3";

      // Add points along diagonals
      let temp = this.perfectShape.getIntersections(
        new paper.Path.Line(this.center, this.planeCircle1.center)
      )[0].point;
      let diag1_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });
      temp = this.perfectShape.getIntersections(
        new paper.Path.Line(this.center, this.planeCircle2.center)
      )[0].point;
      let diag2_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });

      temp = this.perfectShape.getIntersections(
        new paper.Path.Line(this.center, this.planeCircle3.center)
      )[0].point;
      let diag3_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });

      temp = this.perfectShape.getIntersections(
        new paper.Path.Line(this.center, this.planeCircle4.center)
      )[0].point;
      let diag4_circle = new paper.Path.Circle({
        center: [temp.x, temp.y],
        radius: this.masterCircleSize,
        strokeColor: "#1889d3",
      });
    }

    return true;
  }

  recognize(strokes) {
    if (strokes.length === 0) {
      return false;
    }

    let combinedPath = this.combinePath(strokes);

    let intersect_count = 0;
    if (combinedPath.intersects(this.circle1)) {
      intersect_count += 1;
    }
    if (combinedPath.intersects(this.circle2)) {
      intersect_count += 1;
    }
    if (combinedPath.intersects(this.circle3)) {
      intersect_count += 1;
    }
    if (combinedPath.intersects(this.circle4)) {
      intersect_count += 1;
    }

    // Note: could add closeness conditions instead of intersections

    //Do the checks
    if (this.isClosedShape(strokes) && intersect_count >= 3) {
      playAudio("sketch");
      return this.evaluateSketch(strokes);
    }
    return false;
  }

  getPrecision(strokes) {
    let path = this.combinePath(strokes);
    if (path.length === 0) {
      return 0;
    }
    let totalDeviation = this.ellipseError(
      path,
      this.perfectShape,
      this.showFeedback
    );

    let avgDeviation = totalDeviation / this.perfectShape.length;
    // console.log("Average Deviation: " + avgDeviation);

    return 100 - avgDeviation;
  }

  getSmoothness(strokes) {
    return this.getCurvedSmoothness(strokes);
  }
}

export default EllipseLesson;
