import { Fragment } from "react";
import { useSelector } from "react-redux";
import LessonManager from "./LessonManager";

const LessonMenu = (props) => {
  const activeLesson = useSelector((state) => state.login.activeLesson);
  const displayAll = useSelector((state) => state.login.displayAll);

  const showMenu =
    displayAll ||
    activeLesson === props.col1.name ||
    activeLesson === props.col2.name ||
    activeLesson === props.col3.name ||
    activeLesson === props.col4.name;

  const showCol = (col) => {
    return (displayAll && col.name !== "Blank") || activeLesson === col.name;
  };

  const formatCol = (col) => {
    let content;
    if (col.name !== "Blank") {
      content = (
        <LessonManager
          key={col.name}
          name={col.name}
          label={col.label}
          category={col.category}
          lessonList={col.lessonList}
          proTips={col.proTips}
          proTipsImages={col.proTipsImages}
          videoId={col.videoId}
          onSelect={props.onSelect}
        />
      );
    } else {
      // content = <div className="col s5" />;
      content = <Fragment />
    }
    return content;
  };

  return (
    <Fragment>
      {showMenu && (
        <Fragment>
          <div className="row" style={{ marginBottom: "0px" }}>
            {showCol(props.col1) && formatCol(props.col1)}
            {displayAll && <div className="col s2" />}
            {showCol(props.col2) && formatCol(props.col2)}
          </div>
          {props.col3.name !== "Blank" && (
            <Fragment>
              <div className="row" style={{ paddingTop: "5%" }}>
                {displayAll && props.col3.name !== "Blank" && (
                  <div className="col s12" />
                )}
              </div>

              <div className="row" style={{ marginBottom: "0px" }}>
                {showCol(props.col3) && formatCol(props.col3)}
                {displayAll && <div className="col s2" />}
                {showCol(props.col4) && formatCol(props.col4)}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default LessonMenu;
