import { useState } from "react";
import ProTip from "./ProTip";
import LessonNav from "../UI/LessonNav";

const ProTipOverlay = (props) => {
  const [index, setIndex] = useState(0);

  const nextTip = () => {
    if (index === props.proTips.length - 1) {
      setIndex(0);
    } else {
      setIndex((prev) => prev + 1);
    }
  };

  const prevTip = () => {
    if (index === 0) {
      setIndex(props.proTips.length - 1);
    } else {
      setIndex((prev) => prev - 1);
    }
  };

  return (
    <div id="lessonOverlay" className="active">
      <div className="container overlayContent">
        <div id="overlayTitle" className="overlayTitle">
          {props.name}
        </div>
        {/* <div id="best-stars">Best</div> */}
        <div style={{ clear: "both" }}></div>
        <div id="overlayMain">
          <div className="row">
            <div className="col s3 m3 l3">
              <div id="leoLesson">
                <div className="leoName">Da Vinci</div>
              </div>
            </div>
            <ProTip
              text={props.proTips[index]}
              img={props.proTipsImages[index]}
              index={index}
              length={props.proTips.length}
              nextTip={nextTip}
              prevTip={prevTip}
              videoId={props.videoId}
            />
          </div>
        </div>
        <LessonNav
          name={props.name}
          onBack={props.onBack}
          onStart={props.onStart}
          beginText="Start"
        />
      </div>
    </div>
  );
};

export default ProTipOverlay;
