import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sketchColor: "#444444",
  sketchSize: 3,
  sketchShadow: "#444444",
  sketchShadowBlur: 5,
  sketchShadowOffset: 15,
  sandColor: "red",

  onePointMode: 0,
  twoPointMode: 0,
  gridMode: 0,

  masterCircleSize: 8,
  masterCircleColor: "#71bdef",
  deviationThreshold: 5,

  SFTCircleSize: 5,
  SFTCircleColor: "#a2a3a0",
};

const canvasCtxSlice = createSlice({
  name: "canvasCtx",
  initialState: initialState,
  reducers: {},
});

export const canvasActions = canvasCtxSlice.actions;
export default canvasCtxSlice.reducer;
