import Lesson from "../Lesson/Lesson";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SFTCamera extends Lesson {
  //Properties

  squareHeight = Math.min(this.canvasWidth, this.canvasHeight) * 0.6;

  generateLesson() {
    // Generate lines based on screen size

    this.vp1.y = this.canvasHeight/4;
    this.vp2.y = this.canvasHeight/4;

    const title = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20)
    );
    title.content = "CAMERA";
    title.style = {
      fontWeight: "bold",
      fontSize: 20,
      fillColor: "black",
      justification: "center",
    };

    const instructions = new paper.PointText(
      new paper.Point(this.canvasWidth * 0.1, this.canvasHeight / 20 + 20)
    );
    instructions.content =
      "Sketch this camera in two-point perspective.\nFocus on perspective accuracy and realism by making light construction lines first.";
    instructions.style = {
      fontSize: 15,
      fillColor: "black",
    };
  }

  getAngleDegrees = (stroke) => {
    let adj = stroke.getPointAt(stroke.length).x - stroke.getPointAt(0).x;
    let opp = stroke.getPointAt(0).y - stroke.getPointAt(stroke.length).y;
    let result = Math.atan2(adj, opp) * (180 / Math.PI);
    return result;
  };

  getPrecision = (strokes) => {
    let num_correct = 0;
    let num_incorrect = 0;

    let angle;
    let perspectiveAngle;
    let angleDiff;
    for (let i = 0; i < strokes.length; i++) {
      // Consider straightened path
      let startpoint = strokes[i].firstSegment.point;
      let endpoint = strokes[i].lastSegment.point;

      // Consider which direction the stroke started from
      let straightPath;
      let straightZero;
      let straightEnd;
      if (startpoint.y > endpoint.y) {
        straightPath = new paper.Path.Line(startpoint, endpoint);
        straightZero = startpoint;
        straightEnd = endpoint;
      } else {
        straightPath = new paper.Path.Line(endpoint, startpoint);
        straightZero = endpoint;
        straightEnd = startpoint;
      }

      angle = this.getAngleDegrees(straightPath);

      // Is it vertical? Not important
      if (angle < 10 && angle > -10) {
        num_correct += 1;
      }

      //Is it going to left VP?
      else if (angle <= 0) {
        let perspectivePath = new paper.Path.Line(straightZero, this.vp1);
        perspectiveAngle = this.getAngleDegrees(perspectivePath);
        angleDiff = Math.abs(perspectiveAngle - angle);

        if (angleDiff > 3) {
          num_incorrect += 1;
        } else {
          num_correct += 1;
        }
      }
      //Must be going to right VP
      else {
        let perspectivePath = new paper.Path.Line(straightZero, this.vp2);
        perspectiveAngle = this.getAngleDegrees(perspectivePath);
        angleDiff = Math.abs(perspectiveAngle - angle);

        if (angleDiff > 3) {
          num_incorrect += 1;
        } else {
          num_correct += 1;
        }
      }
    }

    return num_correct/(num_correct+num_incorrect);
  };

  exportLessonData = () => {
    return {
      name: "SFTCamera",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };
}

export default SFTCamera;
