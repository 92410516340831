import { Fragment, useState } from "react";
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import MinimalRegisterPage from "./MinimalRegisterPage";

const LandingPage = (props) => {
  const [showRegister, setShowRegister] = useState(false);

  const toggleRegister = () => {
    setShowRegister((prev) => !prev);
  };

  return (
    <Fragment>
      {/* <MinimalRegisterPage
        showRegister={showRegister}
        toggleRegister={toggleRegister}
        showFrontPage={props.showFrontPage}
      /> */}
      <RegisterPage
        showRegister={showRegister}
        toggleRegister={toggleRegister}
        showFrontPage={props.showFrontPage}
      />
      <div className="loginBody">
        <LoginPage
          toggleRegister={toggleRegister}
          showFrontPage={props.showFrontPage}
          showKanjiCanvas={props.showKanjiCanvas}
        />
      </div>
    </Fragment>
  );
};

export default LandingPage;
