import ExercisePage from "./ExercisePage";
import paper from "paper";

const playAudio = (type) => {
  console.log("Lesson playAudio", type);
};

class SFTWarmup extends ExercisePage {
  // For unification with other SFT prompts
  perfectShape = [{ length: 1 }];

  generateLesson() {
    // Generate lines based on screen size
    const title = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20)
    );
    title.content = "WARM UP";
    title.style = {
      fontWeight: "bold",
      fontSize: 20,
      fillColor: "black",
      justification: "center",
    };

    const instructions = new paper.PointText(
      new paper.Point(this.canvasWidth / 2, this.canvasHeight / 20 + 20)
    );
    instructions.content =
      "Feel free to use this sheet to practice sketches. \n It may also help to loosen up your arm before getting started.";
    instructions.style = {
      fontSize: 15,
      fillColor: "black",
      justification: "center",
    };
  }

  precisionHelper = (strokes) => {
    return 100;
  };

  getPrecision = (strokes) => {
    return 100;
  };

  processSketch = (strokes) => {
    if (strokes.length !== 0) {
      this.choppedStrokes = [[]];
      this.choppedTime = [[]];
      this.choppedPressure = [[]];
      this.choppedIndices = [[]];

      for (let i = 0; i < strokes.length; i++) {
        this.choppedStrokes[0].push(new paper.Path(strokes[i].segments));
        if (this.timeValues.length > 0) {
          this.choppedTime[0].push([...this.timeValues[i]]);
        }
        if (this.pressureValues.length > 0) {
          this.choppedTime[0].push([...this.pressureValues[i]]);
        }
        this.choppedIndices[0].push(i);
      }

      this.length = strokes
        .map((ele) => ele.length)
        .reduce((a, b) => {
          return a + b;
        });

      this.perfectShape[0].length = strokes.length;
    } else {
      this.choppedStrokes = [[]];
      this.choppedTime = [[]];
      this.choppedPressure = [[]];
      this.choppedIndices = [[]];
      this.length = 1;
    }

    return;
  };

  getBoundingBox = () => {
    // Copied from Lesson.js to enforce original logic

    // Determine whether to scale based on original canvas size or sketch's bb
    // bb = bounding box
    this.hasCanvasDims =
      "canvasWidth" in this.lessonData && "canvasHeight" in this.lessonData;
    let bb_width = this.hasCanvasDims
      ? this.lessonData.canvasWidth
      : this.raw_bb.width;
    let bb_height = this.hasCanvasDims
      ? this.lessonData.canvasHeight
      : this.raw_bb.height;
    let bb_topLeft = this.hasCanvasDims
      ? new paper.Point(0, 0)
      : this.raw_bb.topLeft;

    return { bb_width, bb_height, bb_topLeft };
  };

  loadPrompt = () => {
    return;
  };

  transformPerfectShape = () => {
    return;
  };

  exportLessonData = () => {
    return {
      name: "SFTWarmup",
      canvasHeight: this.canvasHeight,
      canvasWidth: this.canvasWidth,
    };
  };
}

export default SFTWarmup;
