const challenges = [
  {
    name: "cameraIsometric",
    title: "Camera",
    description: "",
    previewImage: "",
    scaffolding: "",
    locked: 0,
    proTips: [],
    proTipsImages: [],
  },
  {
    name: "app",
    title: "App",
    description: "",
    previewImage: "",
    scaffolding: "",
    locked: 0,
    proTips: [],
    proTipsImages: [],
  },
  {
    name: "chair",
    title: "Chair (2D)",
    description: "",
    previewImage: "",
    locked: 0,
    proTips: [],
    proTipsImages: [],
  },
  {
    name: "car",
    title: "Car",
    description: "",
    previewImage: "",
    locked: 0,
    proTips: [],
    proTipsImages: [],
  },
  {
    name: "motorcycle",
    title: "Motorcycle",
    description: "",
    previewImage: "",
    locked: 0,
    proTips: [],
    proTipsImages: [],
  },
  {
    name: "citystreet1",
    title: "City Street (1 PT)",
    description: "",
    previewImage: "",
    locked: 0,
    proTips: [],
    proTipsImages: [],
  },
  {
    name: "citystreet2",
    title: "City Street (2 PT)",
    description: "",
    previewImage: "",
    locked: 0,
    proTips: [],
    proTipsImages: [],
  },
  {
    name: "citystreet3",
    title: "City Street ( PT)",
    description: "",
    previewImage: "",
    locked: 1,
    proTips: [],
    proTipsImages: [],
  },
];

export default challenges;
